import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../ModuleTwo/WhatIsCyberSecurity.css';

const PublicWifiPartTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const bestPracticesRef = useRef(null);

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module6/public-wifi-quiz');
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module6/public-wifi#PublicWiFiHeading');
  };

  // Scroll to "Best Practices for Safe Use of Public Wi-Fi" section if hash is present
  useEffect(() => {
    if (location.hash === '#BestPracticesPublicWiFiHeading' && bestPracticesRef.current) {
      bestPracticesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container">
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to public Wi-Fi section"
      >
        ←
      </button>

      <div className="cybersecurity-basics-header">
        
        <h2 ref={bestPracticesRef} className="centered-header">Best Practices for Safe Use of Public Wi-Fi</h2>
        </div>
       
        

        <section className="module-section">
          <div className="module-content">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'rgb(255, 177, 169)' }}>1. Verify the Network:</strong> Always confirm the network name with staff before connecting. Avoid networks with generic names like “Free Wi-Fi” or “Public Wi-Fi.”</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section">
          <div className="module-content">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'rgb(255, 177, 169)' }}>2. Use a VPN (Virtual Private Network):</strong> A VPN encrypts your internet connection, providing a secure tunnel for data transmission. Choose reputable VPN services such as NordVPN, ExpressVPN, or CyberGhost.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section">
          <div className="module-content">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'rgb(255, 177, 169)' }}>3. Use HTTPS Websites:</strong> Ensure websites you visit use HTTPS, indicated by a padlock icon in the browser's address bar, to encrypt data transmitted between your browser and the website.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section">
          <div className="module-content">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'rgb(255, 177, 169)' }}>4. Keep Software Updated:</strong> Regularly update your operating system, browser, and security software to protect against vulnerabilities.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section">
          <div className="module-content">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'rgb(255, 177, 169)' }}>5. Avoid Sensitive Transactions:</strong> Refrain from accessing sensitive accounts (e.g., banking, email) and conducting financial transactions on public Wi-Fi.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section">
          <div className="module-content">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'rgb(255, 177, 169)' }}>6. Log Out After Use:</strong> Always log out of websites and services when you are done using them on public Wi-Fi.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="start-quiz-button-container">
        <button 
          className="start-quiz-button1" 
          onClick={goToNext} 
          aria-label="Proceed to the public Wi-Fi quiz"
        >
          Next
        </button>
        </div>
      
    </div>
  );
};

export default PublicWifiPartTwo;
