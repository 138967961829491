import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../ModuleTwo/WhatIsCyberSecurity.css';

const HomeNetwork = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const homeNetworkRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module5/home-network-quiz');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module4/poster#MobileDeviceSecurity');
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  // Scroll to the "Protect Your Home Network" section if the hash is present
  useEffect(() => {
    if (location.hash === '#HomeNetworkHeading' && homeNetworkRef.current) {
      homeNetworkRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container">
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to mobile device security section"
      >
        ←
      </button>

      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header" ref={homeNetworkRef}>
              <h2>Protect Your Home Network</h2>
            </div>
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay />
              <video
                ref={videoRef}
                src="/assets/videos/HomeNetwork.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                aria-label="Home Network Security video"
              >
                Your browser does not support the video tag.
              </video>
              
            </div>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <p>
              When Jason set up his wireless router, he overlooked changing the default settings, including the SSID (wireless network name) and the network password. This oversight presents a considerable security risk, particularly because the router's admin page is still accessible using the default credentials, "admin/password." 
              While many routers conveniently have these default details printed on the back of the device, it also means that anyone with physical access to the router can easily gain access to the network. 
              This is precisely how his neighbour's son, who often visited during social events, managed to connect to Jason's network.
            </p>
          </div>

          <h2 className="cybersecurity-basics-header">Securing the Network</h2>

          <div className="bordered-container alt-color">
            <p>
              On the router's admin page, you can configure the router's settings. Change your Wi-Fi credentials and admin page password to something strong and unique. 
              Ensure you reconnect all legitimate devices with the new password. Changing the SSID from the default name can also prevent attackers from identifying the router's brand.
            </p>
          </div>

          <div className="bordered-container">
            <p>
              On the admin page, you can review the devices currently connected to the network. Jason identifies his PC and mobile phone, but there are several unrecognised devices, likely old gadgets or unauthorised users. 
              Disconnect all suspicious devices to secure your Wi-Fi.
            </p>
          </div>

          <div className="bordered-container alt-color">
            <p>
              Enable WPA3 or WPA2 encryption for added security. These encryptions prevent unauthorised access and protect data transmission. 
              If you've replaced your Wi-Fi box within the last few years, it should have this encryption by default. You can check this on your router's admin page.
            </p>
          </div>

          <div className="bordered-container">
            <p>
              Wi-Fi Protected Setup (WPS) allows easy device connections using a PIN or push-button method, but it can be a security risk. 
              You can disable this feature on the router's admin page.
            </p>
          </div>

          <div className="bordered-container alt-color">
            <p>
              Update Router Firmware: Keeping your router's firmware up to date helps protect against security vulnerabilities. 
              Many routers update automatically, but you can verify this on the admin page.
            </p>
          </div>

          <div className="bordered-container">
            <p>
              Set Up a Guest Network: A guest network provides a separate, secure connection for visitors, keeping your main network protected.
            </p>
          </div>

          <div className="bordered-container alt-color">
            <p>
              Upgrade Your Router: Ensure your router is from a reputable brand and no more than four years old to continue receiving security updates.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button 
            className="start-quiz-button1" 
            onClick={handleNextClick} 
            aria-label="Proceed to the home network security quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default HomeNetwork;
