import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar2.css'; // Import Sidebar2 styles

// SidebarTwo component
const SidebarTwo = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  // Function to toggle sidebar visibility on small screens
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // Function to handle link click and hide the sidebar on small screens
  const handleLinkClick = () => {
    if (window.innerWidth < 769) {
      setSidebarVisible(false); // Hide sidebar when a link is clicked on small screens
    }
  };

  // Ensure sidebar is always visible on larger screens
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 769) {
        setSidebarVisible(true); // Always show the sidebar on larger screens
      } else {
        setSidebarVisible(false); // Hide sidebar initially on smaller screens
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on screen size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {/* Sidebar Toggle Button (only visible on small screens) */}
      <div className="sidebar2-toggle-btn" onClick={toggleSidebar}>
        Contents
      </div>

      {/* Sidebar (conditionally apply show/hide class based on screen size) */}
      <div className={`sidebar2 ${sidebarVisible ? 'show' : 'sidebar2-hidden'}`}>
        <ul className="sidebar2-ul">
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/introduction/introduction-part-two#CybersecurityDetective"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Introduction
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module2/what-is-cyber-security#BackArrow"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              What Is Cyber Security
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module2/importance-cyber#ImportanceHeading"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Importance of Cybersecurity
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module3/cyber-threats#CyberThreatsHeading"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Cyber Threats
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module3/malware#MalwareHeading"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Malware
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module3/phishing#PhishingHeading"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Phishing
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module3/ransomware#RansomwareHeading"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Ransomware
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module4/mobile-security#MobileSecurityHeading"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Mobile Security
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module4/poster#MobileDeviceSecurity"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Poster
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module5/home-network#HomeNetworkHeading"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Home Network
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module6/public-wifi#PublicWiFiHeading"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Public Wi-Fi
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module6/public-wifi-part-two#BestPracticesPublicWiFiHeading"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Public Wi-Fi (2)
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module7/data-backup#DataBackupHeading"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Data Backup
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module7/data-backup-part-two#DataBackupPlanHeading"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Data Backup (2)
            </NavLink>
          </li>
          <li className="sidebar2-li">
            <NavLink 
              to="/beginnerscourseparttwo/module8/credit-profile#CreditProfileHeading"
              className={({ isActive }) => isActive ? 'active2' : ''}
              onClick={handleLinkClick}
            >
              Credit Profile
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SidebarTwo;
