import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Introduction.css';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';

const Introduction = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current URL location
  const videoRef = useRef(null);
  const titleRef = useRef(null); // Ref for the title element
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lastValidTime, setLastValidTime] = useState(0);

  const goBack = () => {
    navigate('/');
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
    setProgress(100);
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      // Navigate to CybersecurityBasics and include the hash to scroll to the title
      navigate('/beginnerscourse/page2/cybersecurity-basics#BasicsTitle'); 
    }
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }

    const progressPercent = (currentTime / duration) * 100;
    setProgress(progressPercent);
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  // Scroll to the title if hash matches
  useEffect(() => {
    if (location.hash === '#BasicsTitle') {
      titleRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div
      className="introduction-container2"
      role="main"
      aria-label="Introduction to the Cybersecurity Detective course"
    >
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the main page"
      >
        ←
      </button>
      <section className="story-introduction">
        <h2
          id="BasicsTitle"
          ref={titleRef}
          className="BasicsTitle"
          tabIndex={0}
          aria-label="The Cybersecurity Detective"
        >
          The Cybersecurity Detective
        </h2>

        <div
          id="video-container"
          className="video-container"
          style={{ position: 'relative' }}
          role="region"
          aria-label="Course introduction video"
        >
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/Introduction.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            aria-label="Introduction video for The Cybersecurity Detective"
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="start-button-container-intro">
          <button
            className="start-button-intro1"
            onClick={handleNextClick}
            aria-label="Start the course"
          >
            Start
          </button>
          {showWarning && (
            <p
              className="warning-text22"
              role="alert"
              aria-live="assertive"
            >
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Introduction;
