import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import '../ModuleOne/WhatIsCyberSecurity.css';

const ComplianceQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([null]); // Adjusted for single question
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState([null]);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([null]);
  const [showStar, setShowStar] = useState(false);
  const auth = getAuth();

  const questions = [
    {
      question: "What is the purpose of the organisation's cybersecurity policies?",
      options: [
        "To enhance system speed",
        "To protect the organisation's assets, data, and reputation",
        "To increase data storage capacity",
        "To allow free access to all data, saving time and increasing efficiency"
      ],
      correctAnswer: "To protect the organisation's assets, data, and reputation"
    },
    {
      question: "Which of the following is a key responsibility of employees regarding cybersecurity policies?",
      options: [
        "Ignoring suspicious activities",
        "Adhering to guidelines for secure data handling",
        "Sharing passwords with colleagues for faster access",
        "Allowing unauthorised access to systems"
      ],
      correctAnswer: "Adhering to guidelines for secure data handling"
    },
    {
      question: "What is a critical aspect of adhering to legal requirements like GDPR?",
      options: [
        "Storing data indefinitely as it may be required in te future, this saves time and resources",
        "All data should be accessible to all employees to ensure fairness and transparency within the organisation",
        "Protecting the organisation from legal risks and maintaining customer trust",
        "Ignoring data breach notifications"
      ],
      correctAnswer: "Protecting the organisation from legal risks and maintaining customer trust"
    },
    {
      question: "How should organisational policies be managed?",
      options: [
        "Policies should be ignored if they are inconvenient",
        "Policies should be aligned with legal requirements and communicated clearly to employees",
        "Policies should be updated every decade",
        "If senior management requests that you overlook policies due to inconvenience, it is acceptable to do so"
      ],
      correctAnswer: "Policies should be aligned with legal requirements and communicated clearly to employees"
    },
    {
      question: "What is the importance of third-party compliance?",
      options: [
        "It allows third parties to operate freely without oversight as they follow their own policies",
        "It ensures that third-party vendors comply with the same standards as your organisation",
        "It encourages third parties to store data insecurely",
        "It eliminates the need for regular audits"
      ],
      correctAnswer: "It ensures that third-party vendors comply with the same standards as your organisation"
    },
    {
        question: "What is the purpose of ongoing cybersecurity training?",
        options: [
          "To keep employees aware of cybersecurity threats",
          "To replace and update organisational policies",
          "To make cybersecurity optional for all employees"
        ],
        correctAnswer: "To keep employees aware of cybersecurity threats"
      }
      ,
      {
        question: "How often should organisational policies be reviewed and updated?",
        options: [
          "Never, policies should stay the same always",
          "Regularly, to handle threats and legal changes",
          "Only when there is a serious breach",
          "Every 10 years, to keep them relevant"
        ],
        correctAnswer: "Regularly, to handle threats and legal changes"
      },
      {
        question: "What role do employees have in the process of policy updates?",
        options: [
          "Ignoring policy updates and changes completely",
          "Providing feedback and suggesting improvements",
          "Only following the policies they prefer to follow",
          "Completely rewriting policies all on their own"
        ],
        correctAnswer: "Providing feedback and suggesting improvements"
      },
      {
        question: "Why is it important for policies to be communicated clearly to all employees?",
        options: [
          "To ensure that only management fully understands the policies",
          "To make sure policies become optional for employees to follow",
          "To ensure everyone understands and follows them for protection",
          "So that employees feel free to ignore them as they wish"
        ],
        correctAnswer: "To ensure everyone understands and follows them for protection"
      },
      {
        question: "What is a potential consequence of not adhering to organisational policies?",
        options: [
          "Increased productivity across the organisation due to reduced red tape",
          "Improved security measures within the company",
          "Significant fines and damage to the organisation's reputation",
          "Better overall employee morale and satisfaction"
        ],
        correctAnswer: "Significant fines and damage to the organisation's reputation"
      },
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect =
        answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] =
          shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 1) {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, "users", user.uid);
          await updateDoc(userDocRef, {
            "progress.course3.module6_quiz3": true,
          });
          setShowStar(true);
        }
      } catch (error) {
        console.error("Error updating user progress:", error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers([null]);
    setFeedback([null]);
    setCorrectAnswers([null]);
    setShowResults(false);
    setScore(0);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate("/businessacademy/modulesix/finishing-page");
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star" role="alert" aria-live="polite"></div>}
      <section
        className="module-section2"
        role="region"
        aria-labelledby="quiz-title"
      >
        <h2 id="quiz-title" tabIndex="0">
          Compliance Quiz
        </h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={
                  feedback[currentQuestionIndex] ? "feedback" : undefined
                }
              >
                <legend
                  id={`question-${currentQuestionIndex}`}
                  tabIndex="0"
                  aria-live="polite"
                  aria-label={`Question ${
                    currentQuestionIndex + 1
                  }: ${shuffledQuestions[currentQuestionIndex]?.question}`}
                >
                  {shuffledQuestions[currentQuestionIndex]?.question}
                </legend>
                {shuffledQuestions[currentQuestionIndex]?.options.map(
                  (option, index) => (
                    <div key={index} className="quiz-option">
                      <label
                        className="quiz-option-label"
                        htmlFor={`option-${currentQuestionIndex}-${index}`}
                      >
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex}-${index}`}
                          name={`question-${currentQuestionIndex}`}
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={() => handleAnswerChange(option)}
                          disabled={feedback[currentQuestionIndex] !== null}
                          aria-checked={
                            answers[currentQuestionIndex] === option
                          }
                        />
                        <span className="option-text">{option}</span>
                      </label>
                    </div>
                  )
                )}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  role="alert"
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === "correct"
                    ? "Correct!"
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                <button className="submit-button" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 1</p>
              {score >= 1 ? (
                <p>
                  Congratulations! You passed the quiz and have a solid
                  understanding of compliance.
                </p>
              ) : (
                <p>You need to score at least 1 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 1 ? (
                  <button className="next-button" onClick={goToNext}>
                    Next
                  </button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default ComplianceQuiz;
