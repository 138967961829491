import React, { useEffect, useState } from 'react';
import './AccountType.css';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase'; // Assuming Firestore is exported from firebase.js
import { useAuth } from '../contexts/AuthProvider'; // Ensure the correct path


const AccountType = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const { profile } = useAuth(); // ✅ Get profile from AuthContext




  // Function to handle Standard User upgrade
const handleUpgradeStandard = async () => {
  try {
    console.log('Attempting to handle Standard User upgrade...');

    if (!user) {
      console.error('User is not authenticated');
      alert('Only Free users can upgrade to Personal Plan. Please create a Free User account to upgrade.');
      return;
    }

    // Force token refresh to ensure it is not expired
    let idToken;
    try {
      idToken = await user.getIdToken(true);
      console.log('User ID Token:', idToken);
    } catch (tokenError) {
      console.error('Error fetching ID token:', tokenError);
      alert('Could not verify your session. Please try again.');
      return;
    }

    // Fetch user profile data from Firestore
    console.log('Fetching user profile data from Firestore...');
    const userDocRef = doc(firestore, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const accountType = userData.profile?.accountType;

      // Check if the user's account type is allowed to upgrade
      if (accountType !== 'FreeUser') {
        console.error('Only Free users can upgrade to Standard');
        alert('Only Free users can upgrade to Personal Plan. Please create a Free User account to upgrade.');
        return;
      }

      // Call HTTPS function to create a Stripe Checkout session
      console.log('Calling HTTPS function to create a Stripe Checkout session...');
      const response = await fetch(
        'https://createcheckoutsession-rhi2cwsv5a-uc.a.run.app',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        console.error('HTTP error! Status:', response.status);
        alert('Failed to create checkout session. Please try again later.');
        return;
      }

      const responseData = await response.json();
      if (responseData && responseData.url) {
        console.log('Redirecting to Stripe Checkout:', responseData.url);
        // Redirect user to the Stripe Checkout page
        window.location.href = responseData.url;
      } else {
        console.error('Failed to create a Stripe checkout session.');
        alert('Could not initiate checkout. Please contact support.');
      }
    } else {
      console.error('User profile not found');
      alert('User profile not found. Please try again.');
    }
  } catch (error) {
    console.error('Error upgrading to standard account:', error);
    alert('An unexpected error occurred. Please try again later.');
  }
};


    // Function to handle Manager upgrade
  const handleUpgradeManager = async () => {
    try {
      console.log('Attempting to handle Manager upgrade...');

      if (!user) {
        console.error('User is not authenticated');
        alert('Only Free users can upgrade to Team Plan. Please create a Free User account to upgrade.');
        return;
      }

      // Force token refresh to ensure it is not expired
      let idToken;
      try {
        idToken = await user.getIdToken(true);
        console.log('User ID Token:', idToken);
      } catch (tokenError) {
        console.error('Error fetching ID token:', tokenError);
        alert('Could not verify your session. Please try again.');
        return;
      }

      // Fetch user profile data from Firestore
      console.log('Fetching user profile data from Firestore...');
      const userDocRef = doc(firestore, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const accountType = userData.profile?.accountType;

        // Check if the user's account type is allowed to upgrade
        if (accountType !== 'FreeUser') {
          console.error('Only Free users can upgrade to Manager');
          alert('Only Free users can upgrade to a Team Plan. Please create a Free User account to upgrade.');
          return; 
        }

        // Call HTTPS function to create a Stripe Checkout session for manager account
        console.log('Calling HTTPS function to create a Manager Stripe Checkout session...');
        const response = await fetch(
          'https://createmanagercheckoutsession-rhi2cwsv5a-uc.a.run.app',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({}),
          }
        );

        if (!response.ok) {
          console.error('HTTP error! Status:', response.status);
          alert('Failed to create checkout session. Please try again later.');
          return;
        }

        const responseData = await response.json();
        if (responseData && responseData.url) {
          console.log('Redirecting to Stripe Checkout:', responseData.url);

          // Redirect user to the Stripe Checkout page
          window.location.href = responseData.url;
        } else {
          console.error('Failed to create a Stripe checkout session.');
          alert('Could not initiate checkout. Please contact support.');
        }
      } else {
        console.error('User profile not found');
        alert('User profile not found. Please try again.');
      }
    } catch (error) {
      console.error('Error upgrading to manager account:', error);
      alert('An unexpected error occurred. Please try again later.');
    }
  };

  return (
    <div className="account-type-container">
      <h1 className="account-type-title">Choose the Right Plan for You</h1>

      <div className="account-options">
        {/* Personal Plan */}
        <div className="account-box">
          <h2 className="account-box-title">Personal Plan</h2>
          <p className="account-box-subtitle">For individual users</p>
          <h3 className="account-price">One Year Access £9.99</h3>
          <p className="account-subtext">One-off payment, no subscription.</p>
          <button className="account-button" onClick={handleUpgradeStandard}>
            Upgrade →
          </button>
          <ul className="account-benefits-list">
            <li>Access to All Courses</li>
            <li>Track Progress</li>
            <li>Link Account to Manager (Optional)</li>
            <li>Certification on Completion (Valid for 1 Year)</li>
            <li>Access for One Year</li>
          </ul>
        </div>

        {/* Team Plan */}
        <div className="account-box" style={{ position: 'relative' }}>
          <h2 className="account-box-title">Team Plan</h2>
          <p className="account-box-subtitle">For Managers</p>
          <h3 className="account-price">£4.99 per month</h3>
          <p className="account-subtext">Billed Monthly. Cancel anytime.</p>
          <button 
  onClick={() => {
    const normalizedAccountType = profile?.accountType?.trim(); // Remove any accidental spaces

console.log("📌 Profile Data:", profile);
console.log("📌 Account Type:", normalizedAccountType);

if (normalizedAccountType === "FreeUser") {
  console.log("✅ User is a Free User");
} else {
  console.log("❌ User is not a Free User");
}
    
  }}
>
  Upgrade →
</button>
          <ul className="account-benefits-list">
            <li>Access to All Courses</li>
            <li>Track Progress</li>
            <li>Link to Employee Accounts</li>
            <li>Certification on Completion (Valid for 1 Year)</li>
            <li>Track Employee Progress</li>
            <li>Receive Threat Reports from Employees</li>
            <li>Threat Reports Compiled into Useful Statistics</li>
            <li>Add Company Logo for Increased Personalisation</li>
          </ul>
        </div>

        {/* Team Plan Plus 
        <div className="account-box">
          <h2 className="account-box-title">Team Plan Plus</h2>
          <p className="account-box-subtitle">For managers</p>
          <p className="account-price">£9.99/month + £2.99 for each additional employee</p>
          <p className="account-subtext">Billed Monthly. Cancel anytime.</p>
          <button className="account-button" onClick={handleUpgradeManager}>
            Upgrade →
          </button>
          <ul className="account-benefits-list">
            <li>Access to All Courses</li>
            <li>Track Employee Progress</li>
            <li>Link to Employee Accounts</li>
            <li>Certification on Completion</li>
            <li>Track Employee Progress</li>
            <li>Receive Threat Reports from Employees</li>
            <li>Threat Reports Compiled into Useful Statistics</li>
            <li>Add Company Logo for Increased Personalisation</li>
            <li>Advanced Analytics and Insights</li>
            <li>Continual Assessments</li>
            <li>Regular Phishing Simulations</li>
            <li>Advanced Employee Tracking</li>
            <li>One-Click Download Full Employee Report</li>
            <li>One-Click Download Full Threat Report</li>
            <li>Employee Account Creation</li>
            <li>One Subscription</li>
            <li>Dedicated Customer Service Team</li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default AccountType;
