import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import "../ModuleTwo/WhatIsCyberSecurity.css";

const CreditReportQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(12).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(12).fill(null));
  const [correctAnswers, setCorrectAnswers] = useState(Array(12).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [showStar, setShowStar] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "Why is it important to set up credit monitoring?",
      options: [
        "To increase your credit score",
        "To get alerts about new credit applications",
        "To apply for more credit cards",
        "To reduce your overall debt"
      ],
      correctAnswer: "To get alerts about new credit applications"
    },
    {
      question: "Which of the following services offer credit monitoring in the UK?",
      options: [
        "Credit Karma, Experian, Equifax",
        "Facebook, Twitter, Instagram",
        "BBC, ITV, Channel 4",
        "NHS, GOV.UK, HMRC"
      ],
      correctAnswer: "Credit Karma, Experian, Equifax"
    },
    {
      question: "What should you do if you notice an unauthorised credit search?",
      options: [
        "Ignore the issue",
        "Contact the company that made the search",
        "Delete your entire credit report",
        "Apply for more credit"
      ],
      correctAnswer: "Contact the company that made the search"
    },
    {
      question: "How can bank alerts help in fraud prevention?",
      options: [
        "By informing you about suspicious transactions",
        "By providing daily motivational quotes",
        "By sending regular weather updates",
        "By sharing the latest sports news"
      ],
      correctAnswer: "By informing you about suspicious transactions"
    },
    {
      question: "What is the first step to take if you suspect identity theft?",
      options: [
        "Contact the police immediately",
        "Contact your bank to report the fraud",
        "Post about it on social media",
        "Ignore the issue entirely"
      ],
      correctAnswer: "Contact your bank to report the fraud"
    },
    {
      question: "Which UK agency should you contact to report fraud?",
      options: [
        "Action Fraud UK",
        "The National Fraud Centre",
        "Victim Fraud UK",
        "NHS Services"
      ],
      correctAnswer: "Action Fraud UK"
    },
    {
      question: "What is a Benefit of Credit Monitoring Alerts",
      options: [
        "Easily check bank balances",
        "Early detection of issues",
        "Stops all identity theft",
        "Helps you get loan approvals"
      ],
      correctAnswer: "Early detection of issues"
    },
    {
      question: "How often should you review your credit report for unauthorised activity?",
      options: [
        "Once a year",
        "Only when you remember",
        "At regular intervals",
        "Never at all"
      ],
      correctAnswer: "At regular intervals"
    },
    {
      question: "What can you do to protect your credit report from unauthorised access?",
      options: [
        "Share login details with friends",
        "Use strong passwords with two-factor authentication",
        "Leave it completely unprotected",
        "Ignore any suspicious activity"
      ],
      correctAnswer: "Use strong passwords with two-factor authentication"
    },
    {
      question: "What is a credit freeze?",
      options: [
        "A measure to cool your credit report",
        "A service that restricts credit access",
        "An option to delete credit records",
        "A type of new investment"
      ],
      correctAnswer: "A service that restricts credit access"
    },
    {
      question: "How can credit monitoring services help prevent fraud?",
      options: [
        "By providing investment advice",
        "By alerting you to new credit activity",
        "By offering great travel discounts",
        "By sending frequent newsletters"
      ],
      correctAnswer: "By alerting you to new credit activity"
    },
    {
      question: "What should you do if you find an error in your credit report?",
      options: [
        "Ignore it completely",
        "Dispute the error with credit agencies",
        "Delete your credit report entirely",
        "Apply for more credit"
      ],
      correctAnswer: "Dispute the error with credit agencies"
    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect =
        answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] =
          shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 9 && user) {
      try {
        const userDocRef = doc(firestore, "users", user.uid);
        await updateDoc(userDocRef, {
          "progress.course2.module8_quiz": true,
        });
        setShowStar(true);
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(12).fill(null));
    setFeedback(Array(12).fill(null));
    setCorrectAnswers(Array(12).fill(null));
    setShowResults(false);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate("/beginnerscourseparttwo/finishing-page-two");
  };

  const goBack = () => {
    navigate("/beginnerscourseparttwo/module8/credit-profile#CreditProfileHeading");
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && (
        <div className="falling-star" role="alert" aria-live="polite"></div>
      )}
      <section
        className="module-section2"
        role="region"
        aria-labelledby="quiz-title"
      >
        <h2 id="quiz-title" tabIndex="0">
          Credit Report Quiz
        </h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={
                  feedback[currentQuestionIndex] ? "feedback" : undefined
                }
              >
                <legend
                  id={`question-${currentQuestionIndex}`}
                  tabIndex="0"
                  aria-live="polite"
                  aria-label={`Question ${
                    currentQuestionIndex + 1
                  }: ${shuffledQuestions[currentQuestionIndex]?.question}`}
                >
                  {shuffledQuestions[currentQuestionIndex]?.question}
                </legend>
                {shuffledQuestions[currentQuestionIndex]?.options.map(
                  (option, index) => (
                    <div key={index} className="quiz-option">
                      <label
                        className="quiz-option-label"
                        htmlFor={`option-${currentQuestionIndex}-${index}`}
                      >
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex}-${index}`}
                          name={`question-${currentQuestionIndex}`}
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={() => handleAnswerChange(option)}
                          disabled={feedback[currentQuestionIndex] !== null}
                          aria-checked={
                            answers[currentQuestionIndex] === option
                          }
                        />
                        <span className="option-text">{option}</span>
                      </label>
                    </div>
                  )
                )}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  role="alert"
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === "correct"
                    ? "Correct!"
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Next Question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit Quiz"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="alert" aria-live="polite">
              <p tabIndex="0">Your score: {score} / 12</p>
              {score >= 9 ? (
                <p tabIndex="0">
                  Congratulations! You passed the quiz and successfully learned
                  about credit monitoring and fraud prevention.
                </p>
              ) : (
                <p tabIndex="0">
                  You need to score at least 9 to pass. Please retake the quiz.
                </p>
              )}
              <div className="quiz-buttons">
                {score >= 9 ? (
                  <button
                    className="next-button"
                    onClick={goToNext}
                    aria-label="Next Lesson"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="retake-button"
                    onClick={handleRetake}
                    aria-label="Retake Quiz"
                  >
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="back-button-container">
          <button
            className="back-button"
            onClick={goBack}
            aria-label="Exit Quiz"
          >
            Exit Quiz
          </button>
        </div>
      </section>
    </div>
  );
};

export default CreditReportQuiz;
