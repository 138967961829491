import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import '../page5/SafeBrowsingGame.css';

const scenarios = [
  {
    text: "What domain does this link end at?: https://bit.ly/3MrqW9y (Use a URL Checker)",
    options: [
      { text: "www.youtube.com", isCorrect: false },
      { text: "www.facebook.com", isCorrect: false },
      { text: "www.bbc.com", isCorrect: true },
    ],
    feedback: [
      "Incorrect.",
      "Incorrect.",
      "Correct!",
    ],
  },
  {
    text: "What domain does this link end at?: https://bit.ly/3Xq6OLn (Use a URL Checker)",
    options: [
      { text: "www.youtube.com", isCorrect: false },
      { text: "www.ncsc.gov.uk", isCorrect: true },
      { text: "www.bbc.com", isCorrect: false },
    ],
    feedback: [
      "Incorrect.",
      "Correct!",
      "Incorrect.",
    ],
  },
  {
    text: "What domain does this link end at?: https://bit.ly/3TeDQM2 (Use a URL Checker)",
    options: [
      { text: "www.youtube.com", isCorrect: true },
      { text: "www.facebook.com", isCorrect: false },
      { text: "www.bbc.com", isCorrect: false },
    ],
    feedback: [
      "Correct!",
      "Incorrect.",
      "Incorrect.",
    ],
  },
];

const CheckingURLsQuiz = () => {
  const [currentScenario, setCurrentScenario] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showStar, setShowStar] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleOptionClick = (isCorrect, feedbackMessage, index) => {
    if (selectedOption === null) {
      setSelectedOption(index);
      setFeedback({ message: feedbackMessage, isCorrect });
      if (isCorrect) setScore(score + 1);

      setTimeout(() => {
        setFeedback(null);
        setSelectedOption(null);
        setCurrentScenario(currentScenario + 1);
      }, 3000);
    }
  };

  useEffect(() => {
    if (currentScenario >= scenarios.length) {
      if (score === scenarios.length) {
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          updateDoc(userDocRef, {
            'progress.course1.module55': true,
          })
            .then(() => setShowStar(true))
            .catch((error) => console.error('Error updating user profile:', error));
        }
      }
    }
  }, [currentScenario, score, user]);

  const resetGame = () => {
    setCurrentScenario(0);
    setScore(0);
    setFeedback(null);
    setShowStar(false);
    setSelectedOption(null);
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page6/social-media-safety#SocialMediaSafety');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page5.5/checking-urls#VerifyLinks');
  };

  if (currentScenario >= scenarios.length) {
    const hasPassed = score === scenarios.length;

    return (
      <div className="safe-browsing-game-container-unique11">
        <button
          className="back-arrow-button-unique11"
          onClick={goBack}
          aria-label="Go back to the previous page"
        >
          ←
        </button>
        {showStar && hasPassed && <div className="falling-star" aria-hidden="true"></div>}
        <h2>
          {hasPassed
            ? 'Congratulations! You passed the quiz and are now more cyber secure!'
            : 'You did not pass the quiz. Try again to improve your cyber security knowledge.'}
        </h2>
        <p>
          You scored {score} out of {scenarios.length}
        </p>
        <button onClick={resetGame} aria-label="Restart the quiz">
          Try Again
        </button>
        {hasPassed && (
          <button onClick={goToNext} aria-label="Go to the next section">
            Next
          </button>
        )}
      </div>
    );
  }

  const { text, options, feedback: scenarioFeedback } = scenarios[currentScenario];

  return (
    <div className="safe-browsing-game-container-unique11">
      <button
        className="back-arrow-button-unique11"
        onClick={goBack}
        aria-label="Go back to the previous page"
      >
        ←
      </button>
      <fieldset
        className="quiz-fieldset"
        aria-describedby={feedback ? 'feedback' : undefined}
      >
        <legend className="quiz-legend">{text}</legend>
        {options.map((option, index) => (
          <button
            key={index}
            className={
              selectedOption === index
                ? feedback?.isCorrect
                  ? 'correct-unique11'
                  : 'incorrect-unique11'
                : ''
            }
            onClick={() => handleOptionClick(option.isCorrect, scenarioFeedback[index], index)}
            disabled={selectedOption !== null}
            aria-describedby={selectedOption === index && feedback ? 'feedback' : undefined}
            aria-pressed={selectedOption === index}
          >
            {option.text}
          </button>
        ))}
      </fieldset>
      <div
        id="feedback"
        className={`feedback-unique11 ${feedback?.isCorrect ? 'correct-feedback-unique11' : 'incorrect-feedback-unique11'}`}
        aria-live="polite"
      >
        {feedback?.message}
      </div>
    </div>
  );
};

export default CheckingURLsQuiz;
