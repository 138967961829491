import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Page3/RecognizingThreatsPartTwo.css'; // Adjusted path
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const StrongPasswords = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page4/strong-passwords-part-two#bordered-container'); // Navigate to the new page and target the bordered-container element
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Next" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page3/recognizing-threats-part-two#BasicsTitle'); // Navigate to the previous page
  };

  return (
    <div className="cybersecurity-basics-container-r">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Recognizing Threats Part Two"
      >
        ←
      </button>
      <br />
      <h2 id="BasicsTitle" className="BasicsTitle">Strong Passwords</h2>
      <section className="module-section">
        <div>
          <div className="video-container" style={{ position: 'relative' }}>
            <LogoOverlay /> {/* Add the logo overlay */}
            <video
              ref={videoRef}
              src="/assets/videos/PageFour.mp4"
              width="100%"
              onEnded={handleVideoEnd}
              onError={(e) => console.error("Video Error:", e)}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
              disablePictureInPicture // Disable Picture-in-Picture
              aria-label="Educational video on strong passwords"
            >
              Your browser does not support the video tag.
            </video>
          </div>

          <section className="module-section" style={{ textAlign: 'center' }}>
            <h2>Understanding Strong Passwords</h2>
          </section>

          <div className="module-content">
            <div className="bordered-container alt-color">
              <p>
                You arrive at Ravi's house and he wastes no time, putting the kettle on and serving tea as you sit down on his sofa. Ravi explains he tried to log into his Facebook account and found himself locked out. He then tried his Twitter and Instagram accounts and was also locked out of them. Ravi explains he uses a single password for all these accounts, which made you shudder.
              </p>
            </div>
          </div>

          <div className="button-container">
            <button
              className="next-button"
              onClick={goToNext}
              aria-disabled={!isVideoWatched}
              aria-label="Proceed to Strong Passwords Part Two"
            >
              Next
            </button>
            {showWarning && (
              <p
                className="warning-text22"
                role="alert"
              >
                You must watch the video before proceeding.
              </p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default StrongPasswords;
