import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure your Firebase setup file path is correct
import '../ModuleTwo/WhatIsCyberSecurity.css'; // Ensure this file exists

const RansomwareQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(10).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(10).fill(null));
  const [correctAnswers, setCorrectAnswers] = useState(Array(10).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]); // Correctly defined state for shuffled questions
  const [showStar, setShowStar] = useState(false); // State to control star animation

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "What is ransomware?",
      options: [
        "A specific type of computer hardware used for security purposes",
        "Malicious software that encrypts data and demands a ransom for its release",
        "A comprehensive antivirus program that prevents infections, keeping your device secure",
        "A regular software update that enhances system security and performance",
      ],
      correctAnswer: "Malicious software that encrypts data and demands a ransom for its release",
    },
    {
      question: "What is one way that ransomware spreads?",
      options: [
        "Through physical contact between infected devices or systems",
        "Via phishing emails with malicious attachments or links included",
        "By installing legitimate software downloaded from trusted sources",
        "By updating your operating system with the latest security patches",
      ],
      correctAnswer: "Via phishing emails with malicious attachments or links included",
    },
    {
      question: "What should you do if you receive a ransomware demand?",
      options: [
        "Pay the ransom immediately without contacting anyone",
        "Ignore the demand and hope the problem eventually goes away",
        "Disconnect from the internet and seek professional help right away",
        "Try to decrypt the files yourself using available tools",
      ],
      correctAnswer: "Disconnect from the internet and seek professional help right away",
    },
    {
      question: "Why is it important to have regular backups?",
      options: [
        "To free up some extra space on your hard drive for new files",
        "To ensure you can restore your data if encrypted by ransomware",
        "To make your computer run faster than it usually does",
        "To avoid buying any new storage devices for backups",
      ],
      correctAnswer: "To ensure you can restore your data if encrypted by ransomware",
    },
    {
      question: "Which of the following actions can help prevent ransomware infections?",
      options: [
        "Opening email attachments from unknown senders",
        "Clicking on pop-up ads",
        "Keeping your software and operating system up to date",
        "Downloading software from unofficial sources",
      ],
      correctAnswer: "Keeping your software and operating system up to date",
    },
    {
      question: "What is the purpose of ransomware?",
      options: [
        "To improve computer performance and speed up operations",
        "To protect data from being stolen by unauthorised users",
        "To extort money from victims by holding their data hostage",
        "To clean up temporary files and free up space on your device",
      ],
      correctAnswer: "To extort money from victims by holding their data hostage",
    },
    {
      question: "What is a key sign that a computer might be infected with ransomware?",
      options: [
        "The computer has faster performance",
        "The computer is operating normally",
        "A ransom note displayed on the screen",
        "The computer has increased battery life",
      ],
      correctAnswer: "A ransom note displayed on the screen",
    },
    {
      question: "Which of the following tools can help protect against ransomware?",
      options: [
        "A word processing program for documents",
        "A firewall and antivirus software for protection",
        "A music player for listening to songs",
        "A web browser for accessing websites",
      ],
      correctAnswer: "A firewall and antivirus software for protection",
    },
    {
      question: "After removing ransomware, what is a recommended practice?",
      options: [
        "Ignore future updates and notifications",
        "Change passwords for important accounts",
        "Uninstall all software from the device",
        "Delete all emails in your inbox",
      ],
      correctAnswer: "Change passwords for important accounts",
    },
    {
      question: "Why is it important to report ransomware attacks?",
      options: [
        "To get a reward",
        "To help authorities track and stop the attackers",
        "To let your friends know about the attack",
        "To increase internet traffic",
      ],
      correctAnswer: "To help authorities track and stop the attackers",
    },
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect = answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 7 && user) {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          'progress.course2.module3_quiz3': true,
        });
        setShowStar(true);
      } catch (error) {
        console.error('Error updating user profile: ', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(10).fill(null));
    setFeedback(Array(10).fill(null));
    setCorrectAnswers(Array(10).fill(null));
    setShowResults(false);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module4/mobile-security#MobileSecurityHeading');
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/ransomware#RansomwareHeading');
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star" aria-hidden="true"></div>}
      <section className="module-section2" role="region" aria-labelledby="quiz-title">
        <h2 id="quiz-title">Ransomware Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={feedback[currentQuestionIndex] ? 'feedback' : undefined}
              >
                <legend className="quiz-legend">{shuffledQuestions[currentQuestionIndex].question}</legend>
                {shuffledQuestions[currentQuestionIndex].options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                        aria-pressed={answers[currentQuestionIndex] === option}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === 'correct'
                    ? 'Correct!'
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Go to the next question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit your answers"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="region" aria-labelledby="results-title">
              <h2 id="results-title">Results</h2>
              <p>Your score: {score} / 10</p>
              {score >= 7 ? (
                <p>Congratulations! You passed the quiz and successfully learned about ransomware.</p>
              ) : (
                <p>You need to score at least 7 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 7 ? (
                  <button
                    className="next-button"
                    onClick={goToNext}
                    aria-label="Go to the next section"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="retake-button"
                    onClick={handleRetake}
                    aria-label="Retake the quiz"
                  >
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="back-button-container">
          <button
            className="back-button"
            onClick={goBack}
            aria-label="Exit the quiz and go to the previous page"
          >
            Exit Quiz
          </button>
        </div>
      </section>
    </div>
  );
};

export default RansomwareQuiz;
