import React, { useState, useRef, useEffect } from 'react'; 
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const Incidents = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const incidentRef = useRef(null);
  const videoRef = useRef(null);
  const warningRef = useRef(null);

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
      setTimeout(() => {
        warningRef.current?.focus();
      }, 100);
    } else {
      goToQuiz();
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/modulefour/incidents-quiz');
  };

  const goBack = () => {
    navigate('/businessacademy/modulethree/securing-work-devices#SecuringWorkDevicesHeading');
  };

  useEffect(() => {
    if (location.hash === '#IncidentResponseHeading' && incidentRef.current) {
      incidentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the Securing Work Devices module"
      >
        ←
      </button>

      <div className="cybersecurity-basics-header">
        <h2 ref={incidentRef}>Incident Response and Reporting</h2>
      </div>

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="/assets/videos/IncidentsModuleFour.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Incident Response Awareness Video"
          >
            <track kind="captions" src="/assets/videos/IncidentsModuleFour.vtt" label="English captions" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="module-content">
        <div className="highlighted-section">
          <p>
           <h3><strong> What Constitutes a Security Incident:</strong></h3>
            
            <strong style={{ color: 'rgb(255, 184, 176)' }}>Definition:</strong> A security incident is any event that compromises or has the potential to compromise the confidentiality, integrity, or availability of an organisation's information, systems, or services.
            
            <h3><strong>Common Examples:</strong></h3>
          </p>
          <ul>
            <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Unauthorised Access:</strong> Attempts to access data or systems by unauthorised individuals, whether external (hackers) or internal (employees without the necessary permissions).</li>
            <  br/> 
            <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Phishing Attacks:</strong> Receiving suspicious emails that attempt to trick employees into revealing sensitive information or clicking on malicious links.</li>
            <  br/> 
            <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Malware Infections:</strong> Detection of viruses, ransomware, spyware, or other forms of malicious software on work devices or within the network.</li>
            <  br/> 
            <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Data Breaches:</strong> Unauthorised access to or disclosure of confidential information, such as customer data, financial records, or intellectual property.</li>
            <  br/> 
            <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Network Intrusions:</strong> Detection of unusual or unauthorised activity within the organisation's network, such as unexpected data transfers or connections from unknown devices.</li>
            <  br/> 
            <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Denial of Service (DoS) Attacks:</strong> Overwhelming the organisation's systems or networks, causing them to become unavailable or slow.</li>
            <  br/> 
            <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Physical Security Breaches:</strong> Unauthorised access to restricted areas within the organisation's premises, which could lead to theft or tampering with equipment.</li>
          </ul>
        </div>
      </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button"
            onClick={handleNextClick}
            aria-label="Start Incident Response and Reporting Quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" ref={warningRef} tabIndex="-1" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Incidents;
