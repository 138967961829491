import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';

const PublicWifi = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const publicWiFiRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module6/public-wifi-part-two#BestPracticesPublicWiFiHeading');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module5/home-network#HomeNetworkHeading');
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  // Scroll to the "Safe Use of Public Wi-Fi" section if the hash is present
  useEffect(() => {
    if (location.hash === '#PublicWiFiHeading' && publicWiFiRef.current) {
      publicWiFiRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container">
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to home network security section"
      >
        ←
      </button>

      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header" ref={publicWiFiRef}>
              <h2>Safe Use of Public Wi-Fi</h2>
            </div>
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay />
              <video
                ref={videoRef}
                src="/assets/videos/SafeWiFiPractices.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                aria-label="Safe Use of Public Wi-Fi video"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <h3>What is Public Wi-Fi?</h3>
            <p>Public Wi-Fi is a wireless network that anyone can connect to, typically provided in places like cafés, airports, hotels, and libraries.</p>
          </div>

          <div className="highlighted-section2">
            <h3>Common Risks</h3>
            <p>
              <strong>Man-in-the-Middle Attacks:</strong>
              <br />
              Attackers intercept communication between your device and the Wi-Fi network.
            </p>
            <br />
            <p>
              <strong>Unsecured Networks:</strong>
              <br /> 
              Many public Wi-Fi networks lack proper encryption, making data transmission vulnerable.
            </p>
            <br />
            <p>
              <strong>Malware Distribution:</strong>
              <br /> 
              Hackers can use unsecured networks to spread malware to connected devices.
            </p>
            <br />
            <p>
              <strong>Evil Twin Attacks:</strong>
              <br />
              Attackers create a fake Wi-Fi network that mimics a legitimate one, tricking users into connecting to it.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button 
            className="start-quiz-button1" 
            onClick={handleNextClick} 
            aria-label="Proceed to the next section on best practices for public Wi-Fi"
          >
            Next
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default PublicWifi;
