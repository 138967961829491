import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component
import '../Page3/RecognizingThreatsPartTwo.css'; // Adjusted path

const StrongPasswordsPartThree = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page4/password-game'); // Navigate to the next page
    }
  };

  useEffect(() => {
    if (location.hash === '#BasicsTitle') {
      const element = document.querySelector('.BasicsTitle');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Task" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page4/strong-passwords-part-two#bordered-container'); // Navigate to the previous page
  };

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Strong Passwords Part Two"
      >
        ←
      </button>
      <br />
      <h2 id="BasicsTitle" className="BasicsTitle">Strong Password Creation</h2>
      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/pageFourPartThree.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
            disablePictureInPicture // Disable Picture-in-Picture
            aria-label="Educational video on creating strong passwords (Part 3)"
          >
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Content Sections */}
        <section className="module-section2">
          <h2>Strong Passwords</h2>
          <div className="module-content">
            <div className="highlighted-section">
              <p>
                <strong>Length:</strong> Aim for at least 12-16 characters. Longer passwords are generally more secure. Don't worry, we will provide a technique to make this easier!
              </p>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content">
            <div className="highlighted-section">
              <p>
                <strong>Complexity:</strong> Use a mix of uppercase and lowercase letters, numbers, and special characters (e.g., !, @, #, $).
              </p>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content">
            <div className="highlighted-section">
              <p>
                <strong>Avoid Common Words and Patterns:</strong> Avoid using common words, phrases, or easily guessable patterns like "password123" or "qwerty."
              </p>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content">
            <div className="highlighted-section">
              <p>
                <strong>Randomness:</strong> Create passwords that are not based on personal information such as birthdays, names, or easily obtainable information.
              </p>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <h2>Techniques for Creating Strong Passwords</h2>
          <div className="module-content">
            <div className="highlighted-section">
             
              <p>
                <strong>  Passphrase Method:</strong> Use a series of random words strung together. For example, "CorrectHorseBatteryStaple."
              </p>
              
            </div>

            

            <div className="highlighted-section">
              <p>
                <strong>Acronym Method:</strong> Create an acronym from a phrase or sentence. For instance, "I love to travel around the world in 80 days!" becomes "IltTatWi80d!"
              </p>
            </div>

            

            <div className="highlighted-section">
              <p>
                <strong>Sentence Method:</strong> Turn a sentence into a password by using the first letter of each word, including punctuation. For example, "My favorite movie is Jurassic Park!" becomes "MfmijP!"
              </p>
            </div>

            

            <div className="highlighted-section">
              <p>
                Alternatively, you can <strong>Install and Set Up a Password Manager:</strong> Choose a reputable password manager to securely store all your passwords.
              </p>
            </div>
          </div>
        </section>

        {/* Navigation Buttons */}
        <div className="button-container">
          <button
            className="next-button"
            onClick={goToNext}
            aria-disabled={!isVideoWatched}
            aria-label="Proceed to Password Game"
          >
            Start Task
          </button>
          {showWarning && (
            <p className="warning-text22" role="alert">
              You must watch the video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default StrongPasswordsPartThree;
