import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay

const SocialEngineering = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const socialEngineeringRef = useRef(null);
  const videoRef = useRef(null);
  const warningRef = useRef(null);

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
      setTimeout(() => {
        warningRef.current?.focus();
      }, 100);
    } else {
      goToQuiz();
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/moduletwo/social-engineering-quiz#SocialEngineeringHeading');
  };

  const goBack = () => {
    navigate('/businessacademy/moduletwo/vishing-quiz#VishingHeading');
  };

  useEffect(() => {
    if (location.hash === '#SocialEngineeringHeading' && socialEngineeringRef.current) {
      socialEngineeringRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to previous module"
      >
        ←
      </button>
      <div className="cybersecurity-basics-header">
        <h2 ref={socialEngineeringRef}>Social Engineering</h2>
      </div>

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="/assets/videos/SocialEngineeringModuleTwo.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Social Engineering Awareness Video"
          >
            <track kind="captions" src="/assets/videos/SocialEngineeringModuleTwo.vtt" label="English captions" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Common Tactics</h3>
            <ul>
              <li><strong>Pretexting:</strong> Creating a fabricated scenario to obtain information.</li>
              <li><strong>Baiting:</strong> Offering something enticing to gain access to sensitive information.</li>
              <li><strong>Tailgating:</strong> Gaining physical access to restricted areas by following authorized personnel.</li>
              <li><strong>Quid Pro Quo:</strong> Offering a service or benefit in exchange for information.</li>
            </ul>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>How to Identify Social Engineering</h3>
            <ul>
              <li>Be wary of unsolicited requests for sensitive information.</li>
              <li>Recognize unusual or inappropriate questions from strangers or new contacts.</li>
              <li>Notice if someone is trying to gain your trust too quickly.</li>
            </ul>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>How to Avoid Social Engineering</h3>
            <ul>
              <li>Verify the identity of the requester before providing any information.</li>
              <li>Do not share personal or sensitive information with unknown individuals.</li>
              <li>Report any suspicious interactions to the security team.</li>
            </ul>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button"
            onClick={handleNextClick}
            aria-label="Start Social Engineering Quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" ref={warningRef} tabIndex="-1" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default SocialEngineering;
