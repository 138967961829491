import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FinisherPageThree.css'; // Updated path for the new CSS file
import Confetti from 'react-confetti';

const FinishingPageThree = () => {
  const navigate = useNavigate();
  const [recycleConfetti, setRecycleConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRecycleConfetti(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="FinishersPageThree-introduction-container">
      {/* Confetti animation - Hidden from screen readers */}
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={recycleConfetti}
        numberOfPieces={2000}
        aria-hidden="true"
      />

      <section className="FinishersPageThree-story-introduction">
        <br />
        <br />
        <h2>🎉🎉 Congratulations, You Completed CyberShield: Business Academy! 🎉🎉</h2>

        <div className="FinishersPageThree-introduction-content">
          <div className="FinishersPageThree-introduction-text">
            <p>
              Well done on completing the Business Module! Your dedication to enhancing your knowledge and skills in cybersecurity and data protection is commendable. By finishing this course, you've taken an important step in helping to secure your organisation and protect sensitive information.
              <br /><br />
              Remember, the insights and practices you've learned here are crucial in today's digital landscape. Keep applying these principles in your daily work, and continue to stay informed as cybersecurity evolves. Your efforts play a vital role in maintaining a safe and secure business environment. Congratulations!
            </p>

            <button
              className="FinishersPageThree-start-button"
              onClick={() => navigate('/')}
              aria-label="Return to home page"
            >
              🏠 <strong>Home</strong>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FinishingPageThree;
