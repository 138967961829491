import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification, signOut } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA v2
import './signUp.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Timestamp } from "firebase/firestore"; // Import Firestore Timestamp

// Constants for character limits
const MAX_NAME_LENGTH = 100;
const MAX_EMAIL_LENGTH = 100;
const MAX_PASSWORD_LENGTH = 50;

// Function to sanitize user input
const sanitizeInput = (input) => {
  return input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/`/g, '&#96;'); 
};

// Function to validate password strength
const validatePassword = (password, email, name) => {
  const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const containsEmailOrName = new RegExp(`(${email}|${name})`, 'i');
  return strongPasswordRegex.test(password) && !containsEmailOrName.test(password);
};


const FreeUserSignUpForm = ({ setUser, setProfile }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (!validatePassword(password, email, name)) {
      setError('Password must be at least 8 characters long, and include one uppercase letter, one lowercase letter, one number, and one special character. It should not contain your email or name.');
      return;
    }
  
    if (!agreeToTerms) {
      setError('You must agree to the User Agreement and Privacy Policy.');
      return;
    }
  
  
    if (!recaptchaToken) {
      setError('Please complete the reCAPTCHA challenge.');
      return;
    }
  
    const sanitizedEmail = email.trim();
    const sanitizedName = sanitizeInput(name);
  
    try {
      // ✅ Step 1: Create user (DO NOT keep them signed in)
      const userCredential = await createUserWithEmailAndPassword(auth, sanitizedEmail, password);
      const user = userCredential.user;
  
      // ✅ Step 2: Update the display name in Firebase
      await updateProfile(user, { displayName: sanitizedName });
  
      // ✅ Step 3: Send verification email
      await sendEmailVerification(user);
  
      // ✅ Step 4: Store user profile in Firestore (AFTER sending verification)
      const signUpDate = new Date();
      const userDocRef = doc(firestore, 'users', user.uid);
      const userRole = user.email.includes("admin") ? "manager" : "FreeUser";

await setDoc(userDocRef, {
  userId: user.uid,
  profile: {
    name: user.displayName,
    email: user.email,
    accountType: userRole, // Dynamically set account type ✅
    createdAt: Timestamp.now(),
  },
        progress: {
          course1: {
            module1: false,
            module2: false,
          },
        },
        agreedToTerms: true, // Store user acceptance of terms
        termsAcceptedAt: signUpDate, // Timestamp for when terms were accepted
        verificationSentAt: signUpDate, // Track when the verification email was sent
        isEmailVerified: false, // Initially set email verification status to false
        employees: [], // Initialize employees array
        linkRequests: [], // Initialize linkRequests array
      });
  
      // ✅ Step 5: Notify the user and prevent login
      setSuccessMessage('A verification email has been sent. Please check your email and verify your account before logging in.');
      setError('');
  
      // ✅ Step 6: Sign them out immediately and navigate to the verification notice page
      await signOut(auth);
      navigate('/verification-notice');
  
    } catch (error) {
      console.error('Signup error:', error);
      setError('Failed to create an account. Please try again.');
      setSuccessMessage('');
    }
  };
  

  const handleBackClick = () => {
    navigate('/');
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <div className="signup-container">
  <div className="signup-box">
    <h1>Free Account Sign Up</h1>
    <br />

    {successMessage && (
      <p className="success-text" role="alert">
        {successMessage}
      </p>
    )}

    {error && (
      <p className="error-text" role="alert">
        {error}
      </p>
    )}

    <form onSubmit={handleSignUp}>
      <div className="input-group">
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value.slice(0, MAX_NAME_LENGTH))}
          required
          aria-required="true"
          aria-label="Enter your full name"
        />
      </div>

      <div className="input-group">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value.slice(0, MAX_EMAIL_LENGTH))}
          required
          aria-required="true"
          aria-label="Enter your email address"
        />
      </div>

      <div className="input-group">
        <label htmlFor="password">Password</label>
        <div className="password-container">
          <input
            id="password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value.slice(0, MAX_PASSWORD_LENGTH))}
            required
            aria-required="true"
            aria-label="Enter your password"
          />
          <span 
            onClick={togglePasswordVisibility} 
            className="password-icon" 
            role="button" 
            tabIndex="0" 
            aria-label={showPassword ? "Hide password" : "Show password"}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
      </div>

      <div className="input-group-signup">
        <label>
          <input
            type="checkbox"
            checked={agreeToTerms}
            onChange={() => setAgreeToTerms(!agreeToTerms)}
            required
            aria-required="true"
            aria-label="Agree to the User Agreement and Privacy Policy"
          />
          {' '}
          I agree to the <a href="/user-agreement" target="_blank">User Agreement</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>.
        </label>
      </div>

      {/* ReCAPTCHA v2 Component */}
      <div className="recaptcha-container">
  <label htmlFor="recaptcha" className="sr-only">
    Please complete the ReCAPTCHA verification
  </label>
  <ReCAPTCHA
    sitekey="6Lfr8W0qAAAAABQY145UoWluq1Ap6kr0jOXxQfWz"
    onChange={onRecaptchaChange}
    id="recaptcha"
    aria-labelledby="recaptcha"
  />
</div>

      <button 
        type="submit" 
        className="login-button" 
        disabled={!agreeToTerms || !recaptchaToken} 
        aria-label="Submit sign up form"
      >
        Sign Up
      </button>
    </form>
  </div>
</div>

  );
};

export default FreeUserSignUpForm;