import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../ModuleTwo/WhatIsCyberSecurity.css';

const DataBackupPartTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dataBackupPlanRef = useRef(null);

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module7/data-backup-quiz');
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module7/data-backup#DataBackupHeading');
  };

  // Scroll to the "Implementing a Basic Backup Plan" section if hash is present
  useEffect(() => {
    if (location.hash === '#DataBackupPlanHeading' && dataBackupPlanRef.current) {
      dataBackupPlanRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack}>
        ←
      </button>
      <div className="cybersecurity-basics-header centered-header">
        <h2 ref={dataBackupPlanRef}>Implementing a Basic Backup Plan</h2>
      </div>
       

        
          <div className="module-content">
            <div className="highlighted-section">
              
                  <p><strong style={{ color: 'rgb(255, 177, 169)' }}>Step 1: Identify Important Data:</strong> Determine which files and folders are crucial to back up (e.g., documents, photos, videos, emails).</p>
                  <br /> 
                </div>
              
          </div>
       

        
          <div className="module-content">
            <div className="highlighted-section">
            
                  <p><strong style={{ color: 'rgb(255, 177, 169)' }}>Step 2: Select Backup Tools and Services:</strong></p>
                  <br />
                  <p><strong>Local Backup Tools:</strong> External hard drives, USB flash drives, Network Attached Storage (NAS)</p>
                  <br />
                  <p><strong>Cloud Backup Services:</strong> Google Drive, Dropbox, OneDrive, iCloud</p>
                  <br />
                </div>
             
          </div>
       

        
          <div className="highlighted-section">
           
                <div className="module-content">
                  <p><strong style={{ color: 'rgb(255, 177, 169)' }}>Step 3: Schedule Regular Backups:</strong> Automate backups to occur at regular intervals (e.g., daily, weekly).</p>
                  
                </div>
             
          </div>
        

        
          <div className="module-content">
            <div className="highlighted-section">
              
                  <p><strong style={{ color: 'rgb(255, 177, 169)' }}>Step 4: Verify Backups:</strong> Regularly check your backups to ensure data is being saved correctly and can be restored.</p>
                  <br />
                </div>
             
          </div>
       
          <div className="start-quiz-button-container">
        <button className="start-quiz-button1" onClick={goToNext}>Start Quiz</button>
      </div>
    </div>
  );
};

export default DataBackupPartTwo;
