import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './ManagersPortal.css'; // Import the CSS for styling
import Footer from '../Footer/Footer';  // Import the Footer component
import DOMPurify from 'dompurify';

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
const MAX_FILE_NAME_LENGTH = 50; // Maximum file name length
const RATE_LIMIT_TIME = 10 * 1000; // 10 seconds limit between uploads/deletes

const ManagersPortal = () => {
  const [logoFile, setLogoFile] = useState(null); // State to manage file input
  const [logoUrl, setLogoUrl] = useState(''); // State to manage the uploaded logo URL
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const [successMessage, setSuccessMessage] = useState(''); // State for success messages
  const [lastActionTime, setLastActionTime] = useState(0); // Track the last upload/delete time
  const [showLinkEmployeePopup, setShowLinkEmployeePopup] = useState(false); // State for popup visibility
  const auth = getAuth();
  const user = auth.currentUser;
  const storage = getStorage();

  useEffect(() => {
    // Fetch the existing logo URL from Firestore when the component mounts
    const fetchLogoUrl = async () => {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().profile?.logoURL) {
          setLogoUrl(userDoc.data().profile.logoURL);
        }
      } catch (error) {
        console.error('Error fetching logo URL:', error);
      }
    };

    fetchLogoUrl();
  }, [user.uid]);

  // Function to validate the file type before uploading
  const validateFileType = (file) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
    return validTypes.includes(file.type);
  };

  // Function to handle file upload with validations
  const handleLogoUpload = async (e) => {
    e.preventDefault();

    // Ensure a file is selected
    if (!logoFile) {
      setErrorMessage('Please select a valid file to upload.');
      return;
    }

    // Validate file name length
    if (logoFile.name.length > MAX_FILE_NAME_LENGTH) {
      setErrorMessage(`File name is too long. Maximum allowed length is ${MAX_FILE_NAME_LENGTH} characters.`);
      return;
    }

    // Validate file size
    if (logoFile.size > MAX_FILE_SIZE) {
      setErrorMessage('File size exceeds the maximum limit of 2MB.');
      return;
    }

    // Validate file type
    if (!validateFileType(logoFile)) {
      setErrorMessage('Invalid file type. Only JPEG, PNG, and WEBP are allowed.');
      return;
    }

    const user = auth.currentUser;

    // Ensure user is authenticated and has a valid UID
    if (!user || !user.uid) {
      console.error('User is not authenticated or UID is missing');
      return;
    }

    const logoRef = ref(storage, `logos/${user.uid}/logo.png`);
    const userDocRef = doc(firestore, 'users', user.uid);

    try {
      const currentTime = Date.now();
      if (currentTime - lastActionTime < RATE_LIMIT_TIME) {
        setErrorMessage('Please wait a few seconds before uploading again.');
        return;
      }

      // Fetch the current logo URL from Firestore
      const userDoc = await getDoc(userDocRef);
      const currentLogoURL = userDoc.exists() ? userDoc.data().profile?.logoURL : null;

      // If a previous logo exists, delete it from Firebase Storage
      if (currentLogoURL) {
        const oldLogoRef = ref(storage, currentLogoURL);
        try {
          await deleteObject(oldLogoRef); // Delete old logo from storage
          console.log('Old logo deleted successfully.');
        } catch (error) {
          console.error('Error deleting old logo:', error);
          // You can choose to return here if the delete fails, or proceed with the new upload
        }
      }

      console.log('Uploading new logo file:', logoFile);

      // Upload the new file to Firebase Storage
      await uploadBytes(logoRef, logoFile);
      const downloadURL = await getDownloadURL(logoRef);

      console.log('File uploaded successfully. URL:', downloadURL);

      // Update Firestore with the new logo URL
      await updateDoc(userDocRef, {
        'profile.logoURL': downloadURL,
        lastUploadTime: currentTime, // Track last upload time
      });

      // Update the local state with the new logo URL
      setLogoUrl(downloadURL);
      setErrorMessage(''); // Clear any previous error messages
      setSuccessMessage('Logo uploaded successfully! Please Refresh page');
      setLastActionTime(currentTime); // Set the last action time to now
    } catch (error) {
      console.error('Error uploading logo:', error);
      alert('Failed to upload the logo. Please try again.');
    }
  };

  // Handle logo deletion from Firebase Storage and Firestore
  const handleLogoDelete = async () => {
    const logoRef = ref(storage, `logos/${user.uid}/logo.png`);
    const userDocRef = doc(firestore, 'users', user.uid);

    try {
      const currentTime = Date.now();
      if (currentTime - lastActionTime < RATE_LIMIT_TIME) {
        setErrorMessage('Please wait a few seconds before deleting again.');
        return;
      }

      // Delete the logo from Firebase Storage
      await deleteObject(logoRef);

      // Remove the logo URL from the Firestore document
      await updateDoc(userDocRef, {
        'profile.logoURL': '', // Clear the logo URL in the Firestore document
      });

      // Update the local state to clear the logo URL
      setLogoUrl(''); // Clear the logo URL in the state
      setSuccessMessage('Logo deleted successfully!');
      setLastActionTime(currentTime); // Set the last action time to now
    } catch (error) {
      console.error('Error deleting logo:', error);
      alert('Failed to delete the logo. Please try again.');
    }
  };

  // Sanitize messages before displaying them
  const sanitizedErrorMessage = DOMPurify.sanitize(errorMessage);
  const sanitizedSuccessMessage = DOMPurify.sanitize(successMessage);

  return (
    <>
      <div className="managers-portal-container">
        <h1 className="managers-portal-title">Manager's Portal</h1>
        <div className="portal-boxes">
          <div className="portal-box">
            <h2>View Employee Progress</h2>
            <Link to="/progress#EmployeeProgressHeading">
            <button className="portal-button" aria-label="Employee Progress">Employee Progress</button>
            </Link>
          </div>
          <div className="portal-box">
            <h2>Report Threat</h2>
            <Link to="/add-report#ReportAThreatHeading">
            <button className="portal-button" aria-label="Report a Threat">Report a Threat </button>
            </Link>
          </div>
          <div className="portal-box">
            <h2>View Threat Reports</h2>
            <Link to="/threat-reports#ThreatReportsHeading">
            <button className="portal-button" aria-label="View Threat Reports">View Threat Reports</button>
            </Link>
          </div>
          <div className="portal-box">
            <h2>View Threat Statistics</h2>
            <Link to="/threat-stats#ThreatStatsHeading">
            <button className="portal-button" aria-label="View Threat Statistics">Statistics</button>
            </Link>
          </div>
          <div className="portal-box">
            <h2>Settings</h2>
            <Link to="/settings#AccountSettingsHeading">
            <button className="portal-button" aria-label="Go to Settings">Settings</button>
            </Link>
  
          </div>

          {/* Logo Upload Section */}
          <div className="portal-box-large">
            <h2>Upload Business Logo</h2>
            {/* Info Button */}
            <button
  className="info-button"
  onClick={() => alert('Upload a logo with a transparent background for best effect')}
  aria-label="More information about uploading a business logo"
  aria-describedby="upload-logo-info"
>
  ℹ️
</button>

            
            <form onSubmit={handleLogoUpload} className="upload-logo-form">
 
  <input
    type="file"
    id="logo-upload"
    accept="image/*"
    onChange={(e) => setLogoFile(e.target.files[0])}
    className="file-input"
    aria-describedby="file-upload-info"
  />
  <p id="file-upload-info" className="visually-hidden">
    Accepted formats: PNG, JPG, WEBP. Maximum file size: 2MB.
  </p>
  <button type="submit" className="portal-button" aria-label="Upload Business Logo">Upload Logo</button>
</form>

            {sanitizedErrorMessage && <p className="error-message">{sanitizedErrorMessage}</p>}
            {sanitizedSuccessMessage && <p className="success-message">{sanitizedSuccessMessage}</p>}
            {logoUrl && (
              <div className="uploaded-logo">
                <button className="delete-logo-button" onClick={handleLogoDelete} aria-label="Delete Business Logo">
      Delete Logo
    </button>
              </div>
            )}
          </div>
        </div>

        {/* Button for linking employee accounts */}
        <div className="link-employee-button-container">
  <button
    className="portal-button"
    onClick={() => setShowLinkEmployeePopup(true)}
    aria-label="How to Link Employee Accounts"
  >
    How to Link Employee Accounts
  </button>
</div>

        {/* Popup for linking employee accounts */}
        {showLinkEmployeePopup && (
  <div className="link-employee-popup" role="dialog" aria-labelledby="popup-title" aria-describedby="popup-description">
    <div className="popup-content">
  <h3 id="popup-title">How to Link Employee Accounts</h3>
  <p id="popup-description">
    
    <br />
    1. Navigate to the <strong>Settings</strong> page.
    <br /> <br /> 
    2. Enter the email address of the user you wish to link in the <strong>"Link Standard User"</strong> field.
    <br /> <br /> 
    3. Once the user accepts the request, the link will be successfully established.
  </p>
  <button
    className="popup-close-button"
    onClick={() => setShowLinkEmployeePopup(false)}
    aria-label="Close How to Link Employee Accounts popup"
  >
    Close
  </button>
</div>

    </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ManagersPortal;
