import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../ModuleTwo/WhatIsCyberSecurity.css'; // Adjusted path

const RemoveMalwareGuide3 = () => {
  const navigate = useNavigate();

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module3/ransomware#RansomwareHeading'); // Navigate to the new page
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/ransomware#RansomwareHeading'); // Navigate to the CybersecurityBasics.js page
  };

  return (
    <div className="cybersecurity-basics-container" role="main">
      <section className="module-section" role="region" aria-labelledby="remove-malware-guide">
        <h2 id="remove-malware-guide" tabIndex="0">Remove Malware Guide</h2>

        <div className="module-content">
          <div className="bordered-container alt-color">
            <h3>1. Disconnect from the Internet</h3>
            <p>Unplug your PC from the internet to stop the malware from spreading or communicating with its server.</p>
          </div>

          <div className="bordered-container">
            <h3>2. Enter Safe Mode</h3>
            <p>Restart your computer and boot into Safe Mode to prevent most malware from running.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>3. Backup Important Files</h3>
            <p>Backup your important files to an external drive before you start the removal process.</p>
          </div>

          <div className="bordered-container">
            <h3>4. Use an Antivirus Program</h3>
            <p>Install or update your antivirus software and run a full system scan. Tools like Malwarebytes or Norton can help.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>5. Delete Temporary Files</h3>
            <p>Delete temporary files using the Disk Cleanup tool to remove some types of malware.</p>
          </div>

          <div className="bordered-container">
            <h3>6. Uninstall Suspicious Programs</h3>
            <p>Go to Control Panel, Programs and Features, and uninstall any unfamiliar or suspicious programs.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>7. Reset Browser Settings</h3>
            <p>Reset your browser settings to default and remove any suspicious extensions or add-ons.</p>
          </div>

          <div className="bordered-container">
            <h3>8. Check for Suspicious Activities</h3>
            <p>Use Task Manager (Ctrl + Shift + Esc) to look for and end any unusual processes.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>9. Update Your Software</h3>
            <p>Ensure your operating system, browsers, and software are up to date to fix security vulnerabilities.</p>
          </div>

          <div className="bordered-container">
            <h3>10. Change Passwords</h3>
            <p>After removing the malware, change your passwords, especially for important accounts.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>11. Stay Protected</h3>
            <p>Install antivirus software, keep it updated, and practice safe browsing habits.</p>
          </div>
        </div>

        <div className="button-group">
          <button 
            className="back-button" 
            onClick={goBack} 
            aria-label="Go back to the ransomware module"
            tabIndex="0"
          >
            Go Back
          </button>

          <button 
            className="next-button" 
            onClick={goToNext} 
            aria-label="Proceed to the ransomware module"
            tabIndex="0"
          >
            Next
          </button>
        </div>
      </section>
    </div>
  );
};

export default RemoveMalwareGuide3;
