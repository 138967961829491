import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './RecognizingThreats.css';

const RecognizingThreatsQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(12).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(12).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(12).fill(null));
  const [authChecked, setAuthChecked] = useState(false);
  const [user, setUser] = useState(null);
  const [showStar, setShowStar] = useState(false);
  const location = useLocation();

  const questions = [
    {
      question: "Chloe asks you what adware is, pick the correct answer.",
      options: [
        "A type of program that allows you to block pop-up ads easily",
        "A type of malware that displays or downloads unwanted ads",
        "A targeted advert",
        "An operating system"
      ],
      correctAnswer: "A type of malware that displays or downloads unwanted ads"
    },
    {
      question: "Explain what malware stands for?",
      options: [
        "Malicious software",
        "Multiple applications",
        "Mechanical ware",
        "Memory ware"
      ],
      correctAnswer: "Malicious software"
    },
    {
      question: "Explain a sign that you saw on Chloe's computer suggesting it might be infected with adware which is a type of malware.",
      options: [
        "Faster performance",
        "Frequent pop-up ads",
        "New software updates",
        "Increased battery life"
      ],
      correctAnswer: "Frequent pop-up ads"
    },
    {
      question: "So Chloe knows what to look out for in the future suggest what might indicate slow performance due to malware?",
      options: [
        "Quick start-up of your computer",
        "Fast program loading",
        "Long time to start up or load web pages",
        "Improved internet speed"
      ],
      correctAnswer: "Long time to start up or load web pages"
    },
    {
      question: "Chloe wants to know what is a common browser issue caused by malware?",
      options: [
        "New bookmarks added",
        "Homepage changes without permission",
        "Faster page loading times than you are use too",
        "New themes applied"
      ],
      correctAnswer: "Homepage changes without permission"
    },
    {
      question: "What other activity should Chloe look that could suggest a malware infection?",
      options: [
        "New programs you didn't install",
        "Old programs updating automatically",
        "New browser extensions you installed yourself",
        "Improved computer speed"
      ],
      correctAnswer: "New programs you didn't install"
    },
    {
        question: "What does it mean if Chloe's web browser changes to a different web browser without her permission?",
        options: [
          "A browser extension was installed",
          "She cleared her browser cache",
          "Her computer might be infected with malware",
          "The browser was updated to the latest version"
        ],
        correctAnswer: "Her computer might be infected with malware"
      },
      
      {
        question: "Chloe kept being redirected to different websites when she was browsing online. What could cause this?",
        options: [
          "Malware infection",
          "Browser cache needing to be cleared",
          "Internet connection issues",
          "Outdated browser version"
        ],
        correctAnswer: "Malware infection"
      },
      
    {
      question: "To help prevent malware infection you advise Chloe to install and keep updated:",
      options: [
        "The latest computer games",
        "Anti-virus Software",
        "Mouse and keyboard settings",
        
      ],
      correctAnswer: "Anti-virus Software"
    },
    {
      question: "What should Chloe suspect if her friends receive strange emails from her?",
      options: [
        "Corrupt Software",
        "Malware infection",
        "New email software",
        "Email provider issues"
      ],
      correctAnswer: "Malware infection"
    },
    {
      question: "Does a slow computer mean malware is the cause?",
      options: [
        "No, other factors can cause a slow computer",
        "Yes, a slow computer always means you have a malware infection"
        
      ],
      correctAnswer: "No, other factors can cause a slow computer"
    },
    {
      question: "Explain What frequent pop-up ads suggest?",
      options: [
        "High-quality browsing",
        "Infected with adware",
        "Fast internet speed",
        "New updates available"
      ],
      correctAnswer: "Infected with adware"
    }
  ];

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate('/login');
      }
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  useEffect(() => {
    if (location.hash === '#QuizTitle') {
      const element = document.getElementById('QuizTitle');
      if (element) {
        const offset = element.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }
    }
  }, [location]);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 9 && user) {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          'progress.course1.module2': true,
        });
        setShowStar(true);
      } catch (error) {
        console.error('Error updating user profile: ', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(12).fill(null));
    setFeedback(Array(12).fill(null));
    setCorrectAnswers(Array(12).fill(null));
    setShowResults(false);
    setScore(0);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page4/strong-passwords');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page3/recognizing-threats-part-two#BasicsTitle');
  };

  if (!authChecked || shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the previous page"
      >
        ←
      </button>
      <br />
      {showStar && <div className="falling-star" aria-hidden="true"></div>}
      <section className="module-section2" role="region" aria-labelledby="QuizTitle">
        <h2 id="QuizTitle">Recognising Cyber Threats Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={feedback[currentQuestionIndex] ? 'feedback' : undefined}
              >
                <legend className="quiz-legend">
                  Question {currentQuestionIndex + 1}: {shuffledQuestions[currentQuestionIndex].question}
                </legend>
                {shuffledQuestions[currentQuestionIndex].options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                        aria-checked={answers[currentQuestionIndex] === option}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
              </fieldset>
              <div
                id="feedback"
                className={`feedback ${feedback[currentQuestionIndex] || ''}`}
                aria-live="polite"
              >
                {feedback[currentQuestionIndex] === 'correct'
                  ? 'Correct!'
                  : feedback[currentQuestionIndex] === 'incorrect'
                  ? `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`
                  : ''}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Go to the next question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit your answers"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="region" aria-labelledby="results-title">
              <h2 id="results-title">Results</h2>
              <p>Your score: {score} / 12</p>
              {score >= 9 ? (
                <p>
                  Congratulations! You've passed the quiz and successfully explained to Chloe how to
                  recognise the signs of cyber threats. Well done! I will proceed to get rid of this
                  adware infection. Don't worry, I will show you how to do this later in the course.
                </p>
              ) : (
                <p>You need to score at least 9 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 9 ? (
                  <button className="next-button" onClick={goToNext} aria-label="Go to the next section">
                    Next
                  </button>
                ) : (
                  <button className="retake-button" onClick={handleRetake} aria-label="Retake the quiz">
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default RecognizingThreatsQuiz;
