import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import "../ModuleTwo/WhatIsCyberSecurity.css";

const DataBackupQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(12).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(12).fill(null));
  const [correctAnswers, setCorrectAnswers] = useState(Array(12).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [showStar, setShowStar] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "What is data backup?",
      options: [
        "Copying your data",
        "Deleting old files",
        "Moving files to another device",
        "Encrypting your data"
      ],
      correctAnswer: "Copying your data"
    },
    {
      question: "Why is data backup important?",
      options: [
        "To save storage space",
        "To prevent data loss",
        "To organise your files",
        "To speed up the computer"
      ],
      correctAnswer: "To prevent data loss"
    },
    {
      question: "Which of the following is a benefit of data backup?",
      options: [
        "Better internet speed",
        "Preventing data loss",
        "Reducing electricity use",
        "Enhancing video quality"
      ],
      correctAnswer: "Preventing data loss"
    },
    {
      question: "What should be your first step in creating a backup plan?",
      options: [
        "Buying a new device",
        "Identifying key data",
        "Installing antivirus",
        "Changing your passwords"
      ],
      correctAnswer: "Identifying key data"
    },
    {
      question: "Which of these is a local backup tool?",
      options: [
        "Google Drive",
        "Dropbox",
        "External hard drive",
        "OneDrive"
      ],
      correctAnswer: "External hard drive"
    },
    {
      question: "Which of these is a cloud backup service?",
      options: [
        "Using a USB drive",
        "A Network Attached Storage",
        "Google Drive",
        "Using an external drive"
      ],
      correctAnswer: "Google Drive"
    },
    {
      question: "How often should you schedule backups?",
      options: [
        "Once a year",
        "When you remember",
        "At regular intervals",
        "Never at all"
      ],
      correctAnswer: "At regular intervals"
    },
    {
      question: "Why should you verify your backups?",
      options: [
        "To ensure data is correct",
        "To remove unnecessary files",
        "To speed up backups",
        "To save electricity"
      ],
      correctAnswer: "To ensure data is correct"
    },
    {
      question: "Which type of files should be prioritised for backup?",
      options: [
        "Temporary files",
        "System files",
        "Important documents",
        "Old software installers"
      ],
      correctAnswer: "Important documents"
    },
    {
      question: "What is business continuity in the context of data backup?",
      options: [
        "Running business without breaks",
        "Maintaining operations after data loss",
        "Hiring new staff",
        "Upgrading office equipment"
      ],
      correctAnswer: "Maintaining operations after data loss"
    },
    {
      question: "How can backups help in case of a malware attack?",
      options: [
        "By slowing the attack",
        "By removing the malware",
        "By restoring your data",
        "By preventing the attack"
      ],
      correctAnswer: "By restoring your data"
    },
    {
      question: "What should you do after creating a backup?",
      options: [
        "Delete original files",
        "Turn off the computer",
        "Verify the backup's completion",
        "Disconnect from internet"
      ],
      correctAnswer: "Verify the backup's completion"
    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect =
        answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] =
          shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 9 && user) {
      try {
        const userDocRef = doc(firestore, "users", user.uid);
        await updateDoc(userDocRef, {
          "progress.course2.module7_quiz": true,
        });
        setShowStar(true);
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(12).fill(null));
    setFeedback(Array(12).fill(null));
    setCorrectAnswers(Array(12).fill(null));
    setShowResults(false);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate(
      "/beginnerscourseparttwo/module8/credit-profile#CreditProfileHeading"
    );
  };

  const goBack = () => {
    navigate("/beginnerscourseparttwo/module7/data-backup-part-two#DataBackupPlanHeading");
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && (
        <div className="falling-star" role="alert" aria-live="polite"></div>
      )}
      <section
        className="module-section2"
        role="region"
        aria-labelledby="quiz-title"
      >
        <h2 id="quiz-title" tabIndex="0">
          Data Backup Quiz
        </h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={
                  feedback[currentQuestionIndex] ? "feedback" : undefined
                }
              >
                <legend
                  id={`question-${currentQuestionIndex}`}
                  tabIndex="0"
                  aria-live="polite"
                  aria-label={`Question ${
                    currentQuestionIndex + 1
                  }: ${shuffledQuestions[currentQuestionIndex]?.question}`}
                >
                  {shuffledQuestions[currentQuestionIndex]?.question}
                </legend>
                {shuffledQuestions[currentQuestionIndex]?.options.map(
                  (option, index) => (
                    <div key={index} className="quiz-option">
                      <label
                        className="quiz-option-label"
                        htmlFor={`option-${currentQuestionIndex}-${index}`}
                      >
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex}-${index}`}
                          name={`question-${currentQuestionIndex}`}
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={() => handleAnswerChange(option)}
                          disabled={feedback[currentQuestionIndex] !== null}
                          aria-checked={
                            answers[currentQuestionIndex] === option
                          }
                        />
                        <span className="option-text">{option}</span>
                      </label>
                    </div>
                  )
                )}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  role="alert"
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === "correct"
                    ? "Correct!"
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Next Question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit Quiz"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="alert" aria-live="polite">
              <p tabIndex="0">Your score: {score} / 12</p>
              {score >= 9 ? (
                <p tabIndex="0">
                  Congratulations! You passed the quiz and successfully learned
                  about data backup.
                </p>
              ) : (
                <p tabIndex="0">
                  You need to score at least 9 to pass. Please retake the quiz.
                </p>
              )}
              <div className="quiz-buttons">
                {score >= 9 ? (
                  <button
                    className="next-button"
                    onClick={goToNext}
                    aria-label="Next Lesson"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="retake-button"
                    onClick={handleRetake}
                    aria-label="Retake Quiz"
                  >
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="back-button-container">
          <button
            className="back-button"
            onClick={goBack}
            aria-label="Exit Quiz"
          >
            Exit Quiz
          </button>
        </div>
      </section>
    </div>
  );
};

export default DataBackupQuiz;
