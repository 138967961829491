import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import "../ModuleTwo/WhatIsCyberSecurity.css";

const MobileSecurityQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(15).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(15).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(15).fill(null));
  const [showStar, setShowStar] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "What is the first step to secure your mobile device?",
      options: [
        "Turn off the mobile device",
        "Enable a strong passcode or biometric authentication",
        "Install a brand new app on your device",
        "Connect to the nearest public Wi-Fi network"
      ],
      correctAnswer: "Enable a strong passcode or biometric authentication"
    },
    {
      question: "Why is it important to keep your mobile device's software updated?",
      options: [
        "To access new mobile features and updates",
        "To protect against security vulnerabilities in software",
        "To change the appearance of your device",
        "To make your battery last a little longer"
      ],
      correctAnswer: "To protect against security vulnerabilities in software"
    },
    {
      question: "Where should you download apps from to ensure they are safe?",
      options: [
        "From any website that you find",
        "From a friend's recommendation link",
        "From official app stores",
        "From an email link that looks reliable"
      ],
      correctAnswer: "From official app stores"
    },
    {
      question: "What is the purpose of enabling remote wipe on your mobile device?",
      options: [
        "To clean the screen remotely if needed",
        "To erase your data if your device is lost or stolen",
        "To make your device run faster than before",
        "To reset your device's settings to default"
      ],
      correctAnswer: "To erase your data if your device is lost or stolen"
    },
    {
      question: "Why should you be cautious when using public Wi-Fi?",
      options: [
        "It is always very slow in performance",
        "It can drain your battery quickly and easily",
        "It can expose your data to hackers online",
        "It disconnects frequently without warning"
      ],
      correctAnswer: "It can expose your data to hackers online"
    },
    {
      question: "How can you protect sensitive data stored on your mobile device?",
      options: [
        "By sharing it on social media accounts",
        "By using a strong password or passkey",
        "By deleting it from your device immediately",
        "By sending it to your friends and family"
      ],
      correctAnswer: "By using a strong password or passkey"
    },
    {
      question: "What should you do regularly to ensure you don't lose important data on your mobile device?",
      options: [
        "Turn off your mobile device when not in use",
        "Back up your data to the cloud or a secure external drive",
        "Clear your browsing history frequently",
        "Close all apps running on your mobile device"
      ],
      correctAnswer: "Back up your data to the cloud or a secure external drive"
    },
    {
      question: "What is a common characteristic of phishing scams?",
      options: [
        "They contain spelling and grammar mistakes in emails",
        "They offer free gifts in exchange for details",
        "They are sent by your family and friends",
        "They look very professional and reliable"
      ],
      correctAnswer: "They contain spelling and grammar mistakes in emails"
    },
    {
      question: "What action should you take if you receive an unsolicited message asking for personal information?",
      options: [
        "Reply with the information requested immediately",
        "Click on the link provided in the message",
        "Verify the sender through other means before responding",
        "Forward it to friends for their advice"
      ],
      correctAnswer: "Verify the sender through other means before responding"
    },
    {
      question: "How can you verify the authenticity of an email from a financial institution?",
      options: [
        "Click on the link in the email to check the website",
        "Call the institution using a phone number from their official website",
        "Reply to the email asking for verification details",
        "Ignore the email and delete it from your inbox"
      ],
      correctAnswer: "Call the institution using a phone number from their official website"
    },
    {
      question: "What should you do if you have provided personal information in response to a phishing email?",
      options: [
        "Ignore it and hope for the best",
        "Change your passwords immediately and monitor your accounts",
        "Wait for the sender to contact you for more details",
        "Share the incident on social media platforms"
      ],
      correctAnswer: "Change your passwords immediately and monitor your accounts"
    },
    {
      question: "What is a key sign that a mobile device might be infected with malware?",
      options: [
        "The device has faster performance than before",
        "The device is operating normally and efficiently",
        "Strange pop-ups and slow performance on the device",
        "The device has increased battery life overall"
      ],
      correctAnswer: "Strange pop-ups and slow performance on the device"
    },
    {
      question: "What kind of information do phishing scams typically seek?",
      options: [
        "Your favorite color or hobby of interest",
        "Usernames, passwords, and credit card details",
        "The name of your pet or childhood nickname",
        "Your last vacation destination or travel plans"
      ],
      correctAnswer: "Usernames, passwords, and credit card details"
    },
    {
      question: "Which of the following actions can help prevent malware infections on mobile devices?",
      options: [
        "Opening email attachments from unknown senders",
        "Clicking on pop-up ads that look interesting",
        "Keeping your software and operating system up to date",
        "Downloading software from unofficial sources"
      ],
      correctAnswer: "Keeping your software and operating system up to date"
    },
    {
      question: "Why is it important to report mobile security breaches?",
      options: [
        "To get a reward or compensation for the breach",
        "To help authorities track and stop the attackers involved",
        "To let your friends know about the breach incident",
        "To increase internet traffic to the reported issue"
      ],
      correctAnswer: "To help authorities track and stop the attackers involved"
    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect =
        answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] =
          shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 11 && user) {
      try {
        const userDocRef = doc(firestore, "users", user.uid);
        await updateDoc(userDocRef, {
          "progress.course2.module4_quiz": true,
        });
        setShowStar(true);
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(15).fill(null));
    setFeedback(Array(15).fill(null));
    setCorrectAnswers(Array(15).fill(null));
    setShowResults(false);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate("/beginnerscourseparttwo/module5/home-network#HomeNetworkHeading");
  };

  const goBack = () => {
    navigate("/beginnerscourseparttwo/module4/poster#MobileDeviceSecurity");
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && (
        <div className="falling-star" role="alert" aria-live="polite"></div>
      )}
      <section
        className="module-section2"
        role="region"
        aria-labelledby="quiz-title"
      >
        <h2 id="quiz-title" tabIndex="0">
          Mobile Security Quiz
        </h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={
                  feedback[currentQuestionIndex] ? "feedback" : undefined
                }
              >
                <legend
                  id={`question-${currentQuestionIndex}`}
                  tabIndex="0"
                  aria-live="polite"
                  aria-label={`Question ${
                    currentQuestionIndex + 1
                  }: ${shuffledQuestions[currentQuestionIndex]?.question}`}
                >
                  {shuffledQuestions[currentQuestionIndex]?.question}
                </legend>
                {shuffledQuestions[currentQuestionIndex]?.options.map(
                  (option, index) => (
                    <div key={index} className="quiz-option">
                      <label
                        className="quiz-option-label"
                        htmlFor={`option-${currentQuestionIndex}-${index}`}
                      >
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex}-${index}`}
                          name={`question-${currentQuestionIndex}`}
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={() => handleAnswerChange(option)}
                          disabled={feedback[currentQuestionIndex] !== null}
                          aria-checked={
                            answers[currentQuestionIndex] === option
                          }
                        />
                        <span className="option-text">{option}</span>
                      </label>
                    </div>
                  )
                )}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  role="alert"
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === "correct"
                    ? "Correct!"
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Next Question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit Quiz"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="alert" aria-live="polite">
              <p tabIndex="0">Your score: {score} / 15</p>
              {score >= 11 ? (
                <p tabIndex="0">
                  Congratulations! You passed the quiz and successfully learned
                  how to secure your mobile device.
                </p>
              ) : (
                <p tabIndex="0">
                  You need to score at least 11 to pass. Please retake the quiz.
                </p>
              )}
              <div className="quiz-buttons">
                {score >= 11 ? (
                  <button
                    className="next-button"
                    onClick={goToNext}
                    aria-label="Next Lesson"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="retake-button"
                    onClick={handleRetake}
                    aria-label="Retake Quiz"
                  >
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="back-button-container">
          <button
            className="back-button"
            onClick={goBack}
            aria-label="Exit Quiz"
          >
            Exit Quiz
          </button>
        </div>
      </section>
    </div>
  );
};

export default MobileSecurityQuiz;
