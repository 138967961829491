import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../ModuleTwo/WhatIsCyberSecurity.css';

const Phishing = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const phishingHeadingRef = useRef(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'illegitimate');
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module3/phishing-quiz');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/malware#MalwareHeading');
  };

  useEffect(() => {
    if (location.hash === '#PhishingHeading' && phishingHeadingRef.current) {
      phishingHeadingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the malware section"
      >
        ←
      </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header" ref={phishingHeadingRef}>
              <h2>Phishing</h2>
            </div>

            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay />
              <video
                ref={videoRef}
                src="/assets/videos/Phishing.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                aria-label="Phishing awareness video"
              >
                Your browser does not support the video tag.
              </video>
              <p aria-live="polite">
                {isVideoWatched
                  ? 'Video completed. You may proceed to the quiz.'
                  : 'Please watch the video to proceed to the quiz.'}
              </p>
            </div>
          </div>
        </div>

        <div className="module-section2">
          <section className="module-section" style={{ textAlign: 'center' }}>
            <h2>Email Security</h2>
          </section>
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="task-instruction">
                <p>
                  <strong>
                    <span style={{ color: 'white' }}>
                      Your task is to decide if the email is legitimate or illegitimate.
                    </span>
                  </strong>
                </p>
              </div>
            </div>
          </div>

          <div className="email-example-box">
            <h3>Example Email</h3>
            <div className="email-content">
              <p><strong>From:</strong> support@brambletonbank.com</p>
              <p><strong>Subject:</strong> Important: Verify Your Account Information</p>
              <p>Dear Barbera,</p>
              <p>
                We have detected unusual activity on your Brambleton Bank account. For your security, please{' '}
                <span
                  className="tooltip-link"
                  role="link"
                  tabIndex="0"
                  aria-label="Fake phishing link, does nothing"
                  onClick={(e) => e.preventDefault()}
                  style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                >
                  Click Here
                </span>{' '}
                to verify your account information immediately. Failure to verify your information may result in a
                temporary hold on your account.
              </p>
              <p>Thank you for your prompt attention to this matter.</p>
              <p>Sincerely,</p>
              <p>Brambleton Bank Support Team</p>
            </div>
            <div className="answer-buttons">
              <button onClick={() => handleAnswer('legitimate')} aria-label="Mark email as legitimate">
                Legitimate
              </button>
              <button onClick={() => handleAnswer('illegitimate')} aria-label="Mark email as illegitimate">
                Illegitimate
              </button>
            </div>
            {isAnswered && (
              <div className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`} role="alert">
                {isCorrect ? 'Well done! This is a Phishing Email' : 'Wrong answer.'}
              </div>
            )}
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <h3>Different types of phishing</h3>
            <p>
              Phishing can take various forms, such as phishing emails, smishing (SMS phishing), and vishing (voice phishing).
              Signs of phishing include unsolicited messages, urgent requests for personal information, and suspicious links or attachments.
              You advise Barbara not to respond to the email and to mark the sender as junk or spam and delete it.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button1"
            onClick={handleNextClick}
            aria-label="Proceed to the phishing quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Phishing;
