import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../Page3/RecognizingThreatsPartTwo.css';

const EmailSecurity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const emailSecurityRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourse/page7/email-security-two#EmailSecurity');
    }
  };

  useEffect(() => {
    if (location.hash === '#EmailSecurity' && emailSecurityRef.current) {
      emailSecurityRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page6/social-media-safety-part-two#Task2');
  };

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Social Media Safety Part Two"
      >
        ←
      </button>

      <section className="module-section">
        <section className="module-section" style={{ textAlign: 'center' }} ref={emailSecurityRef}>
          <h2 id="EmailSecurity" className="BasicsTitle">
            Email Security
          </h2>
        </section>

        <div className="module-content">
          <div className="video-container" style={{ position: 'relative' }}>
            <LogoOverlay />
            <video
              ref={videoRef}
              src="/assets/videos/PageSeven.mp4"
              width="100%"
              onEnded={handleVideoEnd}
              onError={(e) => console.error('Video Error:', e)}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate"
              disablePictureInPicture
              aria-label="Educational video on email security best practices"
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="bordered-container">
          <p>
            You start by discussing with Dorothy the importance of securing her email account. To help her manage the challenge
            of remembering multiple complex passwords, you introduce her to a password manager, which allows her to create
            and store unique, strong passwords for each of her accounts. Additionally, you guide her in enabling two-factor
            authentication (2FA), adding an extra layer of security to her email by requiring a secondary form of verification.
            By taking these steps, Dorothy significantly enhances the security of her email and other online accounts,
            safeguarding her digital identity.
          </p>
        </div>

        <div className="button-container">
          <button
            className="next-button"
            onClick={goToNext}
            aria-label="Proceed to Email Security Part Two"
          >
            Next
          </button>
          {showWarning && (
            <p className="warning-text22" role="alert">
              You must watch the video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default EmailSecurity;
