import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './PasswordGame.css';

const questions = [
  {
    question: "Ravi asked what the recommended length for a strong password is, pick the corect response.",
    options: ["8-10 characters", "10-12 characters", "12-16 characters", "16-20 characters"],
    answer: "12-16 characters"
  },
  {
    question: "Show Ravi which one of the following is an example of using complexity in a password.",
    options: ["password123", "123456789", "Blu3D00rW@y86", "abcdefg"],
    answer: "Blu3D00rW@y86"
  },
  {
    question: "What should Ravi avoid using in his password?",
    options: ["Special characters", "Common words and patterns", "Uppercase and lowercase letters", "Numbers"],
    answer: "Common words and patterns"
  },
  {
    question: "Show Ravi which one of the following is a method to create randomness in passwords.",
    options: ["Using personal information", "Using common phrases", "Using unrelated words", "Using sequential numbers"],
    answer: "Using unrelated words"
  },
  {
    question: "What is an example of a passphrase?",
    options: ["password123", "BlueHorse$7Coffee*", "12345678", "abcdefg"],
    answer: "BlueHorse$7Coffee*"
  },
  {
    question: "Which of these is a strong password?",
    options: ["password", "qwerty", "Blu3D00rW@y86", "123456"],
    answer: "Blu3D00rW@y86"
  },
  {
    question: "Tell Ravi why is it important to use a unique password for each account.",
    options: ["For convenience", "To prevent multiple account breaches", "To remember easily", "To look cool"],
    answer: "To prevent multiple account breaches"
  },
  {
    question: "Explain a benefit of using a password manager",
    options: ["Stores all your passwords securely", "Shares your passwords with friends", "Makes passwords shorter", "Automatically logs you out"],
    answer: "Stores all your passwords securely"
  },
  {
    question: "Tell Ravi what two-factor authentication is.",
    options: ["Using the same password for two accounts", "A method to store passwords", "An additional layer of security", "A way to share passwords"],
    answer: "An additional layer of security"
  },
  {
    question: "Show Ravi Which of these passwords is more secure.",
    options: ["sunshine", "123456", "letmein", "C0mpl3x!ty2024"],
    answer: "C0mpl3x!ty2024"
  },
  {
    question: "Why should Ravi avoid using personal information in his passwords?",
    options: ["It's easy to remember", "It can be easily guessed", "It looks professional", "It makes the password longer"],
    answer: "It can be easily guessed"
  },
  {
    question: "What should Ravi do if he suspects his password has been compromised?",
    options: ["Ignore it", "Change it immediately", "Share it with friends", "Use it on more accounts"],
    answer: "Change it immediately"
  }
];

const PasswordGame = () => {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [canProceed, setCanProceed] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [user, setUser] = useState(null);
  const [showStar, setShowStar] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleAnswerOptionClick = (option) => {
    if (selectedOption === null) {
      setSelectedOption(option);

      if (option === questions[currentQuestion].answer) {
        setScore(score + 1);
        setFeedback('Correct!');
        setCorrectAnswer('');
      } else {
        setFeedback('Incorrect!');
        setCorrectAnswer(`The correct answer is: ${questions[currentQuestion].answer}`);
      }

      setTimeout(() => {
        setFeedback('');
        setCorrectAnswer('');
        setSelectedOption(null);

        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
          setCurrentQuestion(nextQuestion);
        } else {
          setShowScore(true);
          const finalScore = score + (option === questions[currentQuestion].answer ? 1 : 0);
          if (finalScore >= 3) {
            setCanProceed(true);

            if (user) {
              const userDocRef = doc(firestore, 'users', user.uid);
              updateDoc(userDocRef, {
                'progress.course1.module3': true,
              })
                .then(() => {
                  setShowStar(true);
                })
                .catch((error) => {
                  console.error('Error updating user profile: ', error);
                });
            }
          }
        }
      }, 3000);
    }
  };

  const resetGame = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
    setCanProceed(false);
    setShowStar(false);
    setSelectedOption(null);
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page4.5/two-factor-auth#BasicsTitle');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page4/strong-passwords-part-three#BasicsTitle');
  };

  return (
    <div className="password-game-container-unique">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the previous page"
      >
        ←
      </button>
      <br />
      {showStar && <div className="falling-star" aria-hidden="true"></div>}

      {showScore ? (
        <div className="score-section-unique" role="region" aria-labelledby="quiz-score">
          <h2 id="quiz-score">Quiz Results</h2>
          <div className="score-feedback-unique">
            {canProceed ? (
              <>
                <p>Well done! You successfully taught Ravi the importance of strong passwords.</p>
                <button
                  className="next-button-unique"
                  onClick={goToNext}
                  aria-label="Go to the next section"
                >
                  Next
                </button>
              </>
            ) : (
              <>
                <p>You didn't score high enough to pass. Please try again.</p>
                <button onClick={resetGame} aria-label="Retake the quiz">
                  Retake the Test
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <section
            className="module-section-unique"
            role="region"
            aria-labelledby={`question-${currentQuestion}`}
          >
            <h2 id={`question-${currentQuestion}`} className="question-text-unique">
              Question {currentQuestion + 1}: {questions[currentQuestion].question}
            </h2>
            <fieldset className="answer-section-unique" aria-describedby="feedback">
              <legend>Answer Choices</legend>
              {questions[currentQuestion].options.map((option, index) => (
                <div key={index} className="quiz-option-unique">
                  <label className="quiz-option-label">
                    <input
                      type="radio"
                      name={`question-${currentQuestion}`}
                      value={option}
                      checked={selectedOption === option}
                      onChange={() => handleAnswerOptionClick(option)}
                      disabled={selectedOption !== null}
                      aria-checked={selectedOption === option}
                    />
                    <span className="option-text">{option}</span>
                  </label>
                </div>
              ))}
            </fieldset>
            {feedback && (
              <div
                className={`feedback-unique ${feedback === 'Correct!' ? 'correct' : 'incorrect'}`}
                id="feedback"
                aria-live="polite"
              >
                {feedback}
                {correctAnswer && <p>{correctAnswer}</p>}
              </div>
            )}
          </section>
        </>
      )}
    </div>
  );
};

export default PasswordGame;
