import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component
import '../Page3/RecognizingThreatsPartTwo.css'; // Adjusted path

const TwoFactorAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page4.5/two-factor-auth-part-two#Why2FAIsImportant'); // Navigate to the next page
    }
  };

  useEffect(() => {
    if (location.hash === '#BasicsTitle') {
      const element = document.querySelector('.BasicsTitle');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Next" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page4/strong-passwords-part-three#BasicsTitle'); // Navigate to the previous page
  };

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Strong Passwords Part Three"
      >
        ←
      </button>
      <br />
      <h2 id="BasicsTitle" className="BasicsTitle">Two Factor Authentication</h2>
      <section className="module-section">
        <div className="module-content">
          <div className="video-container" style={{ position: 'relative' }}>
            <LogoOverlay /> {/* Add the logo overlay */}
            <video
              ref={videoRef}
              src="/assets/videos/page4.5.mp4"
              width="100%"
              onEnded={handleVideoEnd}
              onError={(e) => console.error("Video Error:", e)}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
              disablePictureInPicture // Disable Picture-in-Picture
              aria-label="Educational video on Two-Factor Authentication"
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        {/* Info Section */}
        <div className="bordered-container alt-color">
          <h2>What is Two-Factor Authentication?</h2>
          <p>
            <strong><span style={{ color: '#005f73' }}>Definition:</span></strong> Two-Factor Authentication (2FA) is an additional layer of security used to ensure that people trying to gain access to an online account are who they say they are.
          </p>
          <p>
            <strong><span style={{ color: '#005f73' }}>How It Works:</span></strong> Two-factor authentication (2FA) is a security process that requires two separate forms of identification to access an account. Typically, it combines something you know (like a password) with something you have (such as a mobile device to receive a code) or something you are (like a fingerprint). This added layer of security makes it more difficult for unauthorized individuals to gain access to your account.
          </p>
        </div>

        {/* Navigation Buttons */}
        <div className="button-container">
          <button
            className="next-button"
            onClick={goToNext}
            aria-disabled={!isVideoWatched}
            aria-label="Proceed to Two-Factor Authentication Part Two"
          >
            Next
          </button>
          {showWarning && (
            <p
              className="warning-text22"
              role="alert"
            >
              You must watch the video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default TwoFactorAuth;
