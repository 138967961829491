import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../ModuleTwo/WhatIsCyberSecurity.css';

const MobileSecurity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const mobileSecurityRef = useRef(null);

  const goToQuiz = () => {
    navigate('/beginnerscourseparttwo/module4/poster#MobileDeviceSecurity'); 
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/ransomware#RansomwareHeading'); 
  };

  // Scroll to Mobile Device Security section if hash is present
  useEffect(() => {
    if (location.hash === '#MobileSecurityHeading' && mobileSecurityRef.current) {
      mobileSecurityRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container">
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to the ransomware section"
      >
        ←
      </button>

      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header" ref={mobileSecurityRef}>
              <h2>Mobile Device Security</h2>
            </div>
            <div className="bordered-content">
              <p>
                Mobile devices are essential tools in the daily lives of the residents of Brambleton, 
                storing vast amounts of personal and sensitive information. Ensuring their security 
                is crucial to protect against data breaches, financial loss, and privacy invasions. 
                Please read the poster I have created on the importance of mobile device security.
              </p>
            </div>
          </div>
        </div>

        <button 
          className="start-quiz-button" 
          onClick={goToQuiz} 
          aria-label="View the mobile device security poster"
        >
          Poster
        </button>
      </section>
    </div>
  );
};

export default MobileSecurity;
