import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './RecognizingThreats.css'; // Ensure this file exists in Page3
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Add the logo overlay

const RecognizingThreats = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [progress, setProgress] = useState(0); // To track video progress
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping
  const basicsTitleRef = useRef(null); // Reference for the title

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page3/recognizing-threats-part-two#BasicsTitle');
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Next" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
    setProgress(100); // Set progress to 100 when the video ends
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }

    const progressPercent = (currentTime / duration) * 100;
    setProgress(progressPercent);
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page2/cybersecurity-basics#BasicsTitle'); // Navigate to the CybersecurityBasics.js page
  };

  // Scroll to the BasicsTitle section if the hash matches
  useEffect(() => {
    if (location.hash === '#BasicsTitle') {
      const element = document.getElementById('BasicsTitle');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <main className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Cybersecurity Basics"
      >
        ←
      </button>

      <section>
        <h2 id="BasicsTitle" ref={basicsTitleRef} className="BasicsTitle">
          Recognising Cyber Threats
        </h2>
      </section>

      <div className="module-content">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="/assets/videos/PageThree.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            aria-label="Educational video about recognising cyber threats"
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <section className="bordered-container">
        <p>
          OK, I have examined the computer and it is indeed suffering from sluggish performance and has a high amount of unusual pop-up ads, a clear sign that it's infected with adware. Adware automatically displays or downloads unwanted advertising material. Adware is a type of malware, which stands for malicious software. There are many different types of malware.
        </p>
      </section>

      <div className="next-button-container">
        <button
          className="next-button"
          onClick={goToNext}
          aria-disabled={!isVideoWatched}
          aria-label="Proceed to Recognizing Cyber Threats Part Two"
        >
          Next
        </button>
        {showWarning && (
          <p className="warning-text" role="alert">
            You must watch the video before proceeding.
          </p>
        )}
      </div>
    </main>
  );
};

export default RecognizingThreats;
