import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const DataBackup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const dataBackupRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module7/data-backup-part-two#DataBackupPlanHeading');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module6/public-wifi-part-two#BestPracticesPublicWiFiHeading');
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  // Scroll to "Backing Up Your Data" section if hash is present
  useEffect(() => {
    if (location.hash === '#DataBackupHeading' && dataBackupRef.current) {
      dataBackupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container">
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to best practices for public Wi-Fi section"
      >
        ←
      </button>

      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header centered-header">
              <h2 ref={dataBackupRef}>Backing Up Your Data</h2>
            </div>
            <div className="video-container">
              <video
                ref={videoRef}
                src="/assets/videos/DataBackup.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                aria-label="Backing Up Your Data video"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
          <br />
            <p>
              Aya's files are locked, and the Ransomware demands £500 worth of Bitcoin to unlock them. It is never advised to pay the ransom. You follow the <a href="/beginnerscourseparttwo/module7/remove-malware-guide2" className="link-text" target="_blank" rel="noopener noreferrer">How to Get Rid of Malware Guide.</a> Luckily, automatic backups had been set to daily for the PC, so you restored the PC back to the previous day when the Ransomware hadn't compromised the PC. Aya did lose one day's worth of coursework, but she is glad that she didn't lose more.
            </p>
            <br />
          </div>

          <div className="highlighted-section2">
            <h3>What is Data Backup?</h3>
            <p>
              Data backup is the process of creating copies of your data to protect it against loss, corruption, or accidental deletion. These copies can be restored in the event of a data loss incident.
            </p>
            <br />

            <h3>Why Backup Your Data?</h3>
            <p>
              <strong>Protection Against Data Loss:</strong><br />
              Prevents loss due to hardware failure, theft, or accidental deletion.
            </p>
            <br />
            <p>
              <strong>Data Recovery:</strong><br />
              Ensures you can recover your files after a malware attack or system crash.
            </p>
            <br />
            <p>
              <strong>Business Continuity:</strong><br />
              Helps maintain operations and services in case of data-related disasters.
            </p>
            <br />
          </div>
          
        </div>

        <div className="start-quiz-button-container">
          <button 
            className="start-quiz-button1" 
            onClick={handleNextClick} 
            aria-label="Proceed to data backup part two"
          >
            Next
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default DataBackup;
