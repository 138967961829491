import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import "./WhatIsCyberSecurity.css"; // Ensure this file exists

const EmployeeRoleQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(6).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(6).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(6).fill(null));
  const [showStar, setShowStar] = useState(false); // State to control star animation
  const auth = getAuth();

  const questions = [
    {
      question: "Why is it important to use strong, unique passwords?",
      options: [
        "Because it is company policy",
        "To reduce the risk of password guessing and hacking",
        "To remember passwords easily",
        "To update software quickly"
      ],
      correctAnswer: "To reduce the risk of password guessing and hacking"
    },
    {
      question: "What should you do if you identify a phishing attempt?",
      options: [
        "Ignore it",
        "Report it to the IT department immediately",
        "Click on the link to investigate as it may be important",
        "Delete the email without reporting"
      ],
      correctAnswer: "Report it to the IT department immediately"
    },
    {
      question: "Why is it essential to keep devices and software up-to-date?",
      options: [
        "To improve battery life, making the device more efficient",
        "To protect against vulnerabilities",
        "To increase internet speed",
        "To save storage space"
      ],
      correctAnswer: "To protect against vulnerabilities"
    },
    {
      question: "What is your responsibility regarding company cybersecurity policies?",
      options: [
        "Ignore them",
        "Follow them strictly",
        "Only follow them when convenient",
        "Delegate them to others"
      ],
      correctAnswer: "Follow them strictly"
    },
    {
      question: "What should you do if you notice unusual system behaviour?",
      options: [
        "Restart the computer",
        "Report it immediately to the IT department",
        "Ask colleagues who have more experience for advice",
        "Try to fix it yourself"
      ],
      correctAnswer: "Report it immediately to the IT department"
    },
    {
      question: "Why is quick reporting of security incidents important?",
      options: [
        "To contain and mitigate the impact of a breach",
        "So that the company can hide the breach and maintain its reputation",
        "So all computers can be shut down to avoid any further incidents",
      ],
      correctAnswer: "To contain and mitigate the impact of a breach"
    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect =
        answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] =
          shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 4) {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, "users", user.uid);
          await updateDoc(userDocRef, {
            "progress.course3.module1_quiz2": true,
          });
          setShowStar(true);
        }
      } catch (error) {
        console.error("Error updating user progress:", error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(6).fill(null));
    setFeedback(Array(6).fill(null));
    setCorrectAnswers(Array(6).fill(null));
    setShowResults(false);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate(
      "/businessacademy/moduletwo/recognizing-cyber-threats#RecognizingCyberThreatsHeading"
    );
  };

  const goBack = () => {
    navigate("/businessacademy/moduleone/employee-role#EmployeeRoleHeading");
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && (
        <div className="falling-star" role="alert" aria-live="polite"></div>
      )}
      <section
        className="module-section2"
        role="region"
        aria-labelledby="quiz-title"
      >
        <h2 id="quiz-title" tabIndex="0">
          Employee's Role in Cybersecurity Quiz
        </h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={
                  feedback[currentQuestionIndex] ? "feedback" : undefined
                }
              >
                <legend
                  id={`question-${currentQuestionIndex}`}
                  tabIndex="0"
                  aria-live="polite"
                  aria-label={`Question ${
                    currentQuestionIndex + 1
                  }: ${shuffledQuestions[currentQuestionIndex]?.question}`}
                >
                  {shuffledQuestions[currentQuestionIndex]?.question}
                </legend>
                {shuffledQuestions[currentQuestionIndex]?.options.map(
                  (option, index) => (
                    <div key={index} className="quiz-option">
                      <label
                        className="quiz-option-label"
                        htmlFor={`option-${currentQuestionIndex}-${index}`}
                      >
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex}-${index}`}
                          name={`question-${currentQuestionIndex}`}
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={() => handleAnswerChange(option)}
                          disabled={feedback[currentQuestionIndex] !== null}
                          aria-checked={
                            answers[currentQuestionIndex] === option
                          }
                        />
                        <span className="option-text">{option}</span>
                      </label>
                    </div>
                  )
                )}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  role="alert"
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === "correct"
                    ? "Correct!"
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Next Question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit Quiz"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="alert" aria-live="polite">
              <p tabIndex="0">Your score: {score} / 6</p>
              {score >= 4 ? (
                <p tabIndex="0">
                  Congratulations! You’ve passed the quiz and now have a clear
                  understanding of your role in maintaining cybersecurity as an
                  employee.
                </p>
              ) : (
                <p tabIndex="0">
                  You need to score at least 4 to pass. Please retake the quiz.
                </p>
              )}
              <div className="quiz-buttons">
                {score >= 4 ? (
                  <button
                    className="next-button"
                    onClick={goToNext}
                    aria-label="Next Lesson"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="retake-button"
                    onClick={handleRetake}
                    aria-label="Retake Quiz"
                  >
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="back-button-container">
          <button
            className="back-button"
            onClick={goBack}
            aria-label="Exit Quiz"
          >
            Exit Quiz
          </button>
        </div>
      </section>
    </div>
  );
};

export default EmployeeRoleQuiz;
