import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Added useLocation import
import '../Page3/RecognizingThreatsPartTwo.css'; // Adjusted path
import maliciouslink from '../../assets/maliciouslink.png'; // Adjust the import as per your image path

const SafeBrowsingPartTwo = () => {
  const [showQuiz, setShowQuiz] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get location to handle URL hash

  const toggleQuiz = () => {
    setShowQuiz(true);
  };

  const goToGame = () => {
    navigate('/beginnerscourse/page5/safe-browsing-drop-drag-game'); // Replace with the actual game route
  };

  const goBack = () => {
    navigate('/beginnerscourse/page5/safe-browsing#BasicsTitle'); // Replace with the actual previous page route
  };

  // Scroll to the AvoidingSuspiciousLinks section if hash is present
  useEffect(() => {
    if (location.hash === '#AvoidingSuspiciousLinks') {
      const element = document.querySelector('#AvoidingSuspiciousLinks');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  return (
    <div className={`introduction-container2 ${showQuiz ? 'hide-next-button' : ''}`}>
      {!showQuiz && (
        <div className="audio-controls" aria-hidden="true"></div>
      )}

      <section className="module-section">
        <button
          className="back-arrow-button"
          onClick={goBack}
          aria-label="Go back to Safe Browsing overview"
        >
          ←
        </button>
        {!showQuiz && (
          <>
            <div>
              {/* Updated h2 for consistency */}
              <h2 id="BasicsTitle" className="BasicsTitle">
                Avoiding suspicious links   
              </h2>

              <div className="bordered-container alt-color">
                <p>
                  Beware of sneaky links sent by unknown sources like the example below! Hackers often disguise harmful links in emails, messages, and pop-ups.{' '}
                  <strong>
                    <span style={{ color: '#005f73' }}>Clicking on these could lead to trouble!</span>
                  </strong>
                </p>
                <p>
                  <strong>
                    <span style={{ color: '#005f73' }}>Tip:</span>
                  </strong>{' '}
                  If you don't recognize the sender or the source, don't click! Think of it as avoiding a trap in a maze. Stay alert and keep your cyber defenses strong!
                </p>
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={maliciouslink}
                    alt="Example of a malicious link disguised as free accounting software"
                    className="thumbnail"
                  />
                </div>
              </div>

              <div className="module-content">
                <div className="highlighted-section">
                  <h3>Safe Browsing - Key Points</h3>
                  <ul>
                    <li>
                      Ensure the website uses{' '}
                      <strong>
                        <span style={{ color: 'white' }}>HTTPS</span>
                      </strong>
                      , but remember that this alone doesn't make the website safe. It's just one part of staying secure online.
                    </li>
                    <br />
                    <li>Hackers disguise harmful links in emails, messages, and pop-ups.</li>
                    <br />
                    <li>Clicking on unknown links can lead to trouble.</li>
                    <br />
                    <li>If you don't recognize the sender or source, don't click the link.</li>
                    <br />
                    <li>It is good practice to go directly to the official website rather than through links.</li>
                  </ul>
                </div>
              </div>

              <div className="bordered-container alt-color">
                <h3>Task Incoming</h3>
                <p>I've prepared an engaging game and quiz for the students. Are you ready to join in and complete them too?</p>
              </div>
            </div>
            <button
              className="start-quiz-button"
              onClick={goToGame}
              aria-label="Proceed to Safe Browsing drag-and-drop game"
            >
              Start Game
            </button>
          </>
        )}
      </section>
    </div>
  );
};

export default SafeBrowsingPartTwo;
