import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SocialMediaDropDragGame.css';

const terms = [
  { id: 1, text: "Set your profile visibility to friends only", category: "good" },
  { id: 2, text: "Post your home address publicly", category: "bad" },
  { id: 3, text: "Enable two-factor authentication", category: "good" },
  { id: 4, text: "Use weak passwords", category: "bad" },
  { id: 5, text: "Verify sender's address before opening email links", category: "good" },
  { id: 6, text: "Share travel plans in real-time", category: "bad" },
  { id: 7, text: "Update privacy settings to hide personal details", category: "good" },
  { id: 8, text: "Accept friend requests from unknown people", category: "bad" },
  { id: 9, text: "Clicking on links in the comment section", category: "bad" },
  { id: 10, text: "Click on suspicious links", category: "bad" },
  { id: 11, text: "Think twice before you post", category: "good" },
  { id: 12, text: "Share sensitive personal information publicly", category: "bad" }
];

const SocialMediaDropDragGame = () => {
  const [draggedTerm, setDraggedTerm] = useState(null);
  const [sortedTerms, setSortedTerms] = useState({ good: [], bad: [] });
  const navigate = useNavigate();
  const location = useLocation();

  const handleDragStart = (term) => {
    setDraggedTerm(term);
  };

  const handleDrop = (category) => {
    if (draggedTerm && draggedTerm.category === category) {
      setSortedTerms((prev) => ({
        ...prev,
        [category]: [...prev[category], draggedTerm],
      }));
    }
    setDraggedTerm(null);
  };

  const resetGame = () => {
    setSortedTerms({ good: [], bad: [] });
    setDraggedTerm(null);
  };

  const goBack = () => {
    navigate('/beginnerscourse/page6/social-media-safety-part-two');
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page6/social-media-safety-quiz');
  };

  // Scroll to the Drag and Drop Game section if hash is present
  useEffect(() => {
    if (location.hash === '#DragAndDropGame') {
      const element = document.querySelector('h2');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location.hash]);

  const isAllSortedCorrectly = sortedTerms.good.length + sortedTerms.bad.length === terms.length;

  return (
    <div
      className="drop-drag-game-container"
      role="main"
      aria-label="Social Media Safety Drag and Drop Sorting Game"
    >
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Social Media Safety Part Two"
      >
        ←
      </button>
      <h2 tabIndex={0} aria-label="Social Media Safety Drag and Drop Game">
        Social Media Safety Drag and Drop Game
      </h2>
      <div className="terms-container">
        {terms
          .filter((term) => !sortedTerms.good.includes(term) && !sortedTerms.bad.includes(term))
          .map((term) => (
            <div
              key={term.id}
              className="term"
              draggable
              onDragStart={() => handleDragStart(term)}
              role="button"
              tabIndex={0}
              aria-label={`Draggable term: ${term.text}`}
            >
              {term.text}
            </div>
          ))}
      </div>
      <div className="categories-container">
        <div
          className="category good"
          onDragOver={(e) => e.preventDefault()}
          onDrop={() => handleDrop("good")}
          role="region"
          aria-label="Drop area for good terms"
          tabIndex={0}
        >
          <h3 tabIndex={0} aria-label="Good terms">
            Good
          </h3>
          {sortedTerms.good.map((term) => (
            <div
              key={term.id}
              className="term good-term"
              role="listitem"
              tabIndex={0}
              aria-label={`Good term: ${term.text}`}
            >
              {term.text}
            </div>
          ))}
        </div>
        <div
          className="category bad"
          onDragOver={(e) => e.preventDefault()}
          onDrop={() => handleDrop("bad")}
          role="region"
          aria-label="Drop area for bad terms"
          tabIndex={0}
        >
          <h3 tabIndex={0} aria-label="Bad terms">
            Bad
          </h3>
          {sortedTerms.bad.map((term) => (
            <div
              key={term.id}
              className="term bad-term"
              role="listitem"
              tabIndex={0}
              aria-label={`Bad term: ${term.text}`}
            >
              {term.text}
            </div>
          ))}
        </div>
      </div>
      {isAllSortedCorrectly && (
        <div className="game-complete" role="alert">
          <p tabIndex={0} aria-label="Congratulations! You have sorted all the terms correctly.">
            Congratulations! You've sorted all the terms correctly.
          </p>
          <button
            className="next-button"
            onClick={goToNext}
            aria-label="Go to the Social Media Safety Quiz"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default SocialMediaDropDragGame;
