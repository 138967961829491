import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const InsiderThreats = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const insiderThreatsRef = useRef(null);
  const videoRef = useRef(null);
  const warningRef = useRef(null);

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
      setTimeout(() => {
        warningRef.current?.focus();
      }, 100);
    } else {
      goToQuiz();
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/moduletwo/insider-threats-quiz');
  };

  const goBack = () => {
    navigate('/businessacademy/moduletwo/social-engineering#SocialEngineeringHeading');
  };

  useEffect(() => {
    if (location.hash === '#InsiderThreatsHeading' && insiderThreatsRef.current) {
      insiderThreatsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the Social Engineering module"
      >
        ←
      </button>
      <div className="cybersecurity-basics-header">
        <h2 ref={insiderThreatsRef}>Insider Threats</h2>
      </div>

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="/assets/videos/InsiderThreatsModuleTwo.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Insider Threats Awareness Video"
          >
            <track kind="captions" src="/assets/videos/InsiderThreatsModuleTwo.vtt" label="English captions" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Types of Insider Threats</h3>
            <ul>
              <li><strong>Malicious Insiders:</strong> Employees who intentionally harm the organization.</li>
              <li><strong>Negligent Insiders:</strong> Employees who unintentionally cause harm through careless actions.</li>
              <li><strong>Compromised Insiders:</strong> Employees whose credentials have been stolen and used by attackers.</li>
            </ul>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>How to Identify Insider Threats</h3>
            <ul>
              <li>Unusual or unauthorized access to sensitive data.</li>
              <li>Sudden changes in an employee's behavior or performance.</li>
              <li>Attempts to bypass security controls or escalate privileges.</li>
              <li>Excessive downloading or copying of data.</li>
            </ul>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>How to Prevent Insider Threats</h3>
            <ul>
              <li>Implement strict access controls and the principle of least privilege.</li>
              <li>Conduct regular security awareness training to educate employees on risks.</li>
              <li>Monitor network activity for unusual behavior and set up alerts.</li>
              <li>Establish clear policies for reporting suspicious behavior or incidents.</li>
              <li>Conduct thorough background checks during the hiring process.</li>
            </ul>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button"
            onClick={handleNextClick}
            aria-label="Start Insider Threats Quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" ref={warningRef} tabIndex="-1" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default InsiderThreats;
