import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './EmailSecurity.css';

const EmailSecurityFour = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const emailSecurityRef = useRef(null); 
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'illegitimate');
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page7/email-security-quiz');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page7/email-security-three#EmailSecurity');
  };

  useEffect(() => {
    if (location.hash === '#EmailSecurity' && emailSecurityRef.current) {
      emailSecurityRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Email Security Three page"
      >
        ←
      </button>
      <section className="module-section2">
        <section
          className="module-section"
          style={{ textAlign: 'center' }}
          ref={emailSecurityRef}
        >
          <h2 id="EmailSecurity">Email Security</h2>
        </section>
        <div className="module-content2">
          <div className="bordered-container2">
            <div className="task-instruction">
              <p>
                <strong>
                  <span style={{ color: 'white' }}>
                    Your task is to decide if each email is legitimate or illegitimate.
                  </span>
                </strong>
              </p>
            </div>
          </div>
        </div>

        <div className="email-example-box">
          <h3>Example Email Three</h3>
          <div className="email-content">
            <p>
              <strong>From:</strong> support@paypa1.com
            </p>
            <p>
              <strong>Subject:</strong> Urgent: Your Account Has Been Suspended
            </p>
            <p>Dear Valued Customer,</p>
            <p>
              We regret to inform you that your PayPal account has been temporarily suspended due to suspicious activity. To ensure the security of your account, we require you to verify your identity and update your account information.
            </p>
            <p>Please click the link below to restore your account access:</p>
            <p>
              <strong>
                <span
                  className="tooltip-link"
                  role="link"
                  tabIndex={0}
                  aria-describedby="tooltip-text"
                >
                  <span style={{ color: 'blue' }}>Click Here</span>
                </span>
              </strong>
            </p>
            <p>
              Failure to complete the verification process within 24 hours will result in permanent suspension of your account.
            </p>
            <p>Thank you for your prompt attention to this matter.</p>
            <p>Best Regards,</p>
            <p>PayPal Security Team</p>
          </div>
          <div className="answer-buttons">
            <button
              onClick={() => handleAnswer('legitimate')}
              aria-label="Select if the email is legitimate"
            >
              Legitimate
            </button>
            <button
              onClick={() => handleAnswer('illegitimate')}
              aria-label="Select if the email is illegitimate"
            >
              Illegitimate
            </button>
          </div>
          {isAnswered && (
            <div
              className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`}
              role="alert"
              aria-live="assertive"
            >
              {isCorrect ? 'Well done!' : 'Wrong answer.'}
            </div>
          )}
        </div>

        {isAnswered && (
          <div className="phishing-signs">
            <h4>Signs of a Phishing Attempt:</h4>
            <ul>
              <li>
                <strong>Sender's Email Address:</strong> The sender's email address (support@paypa1.com) contains a slight misspelling of the official domain (paypa1 instead of paypal).
              </li>
              <li>
                <strong>Urgency:</strong> The email creates a sense of urgency by threatening account suspension if action is not taken immediately.
              </li>
              <li>
                <strong>Generic Greeting:</strong> The email uses a generic greeting like "Dear Valued Customer" instead of addressing the recipient by name.
              </li>
              <li>
                <strong>Poor Grammar and Spelling:</strong> The email may contain subtle spelling errors or awkward phrasing that are uncommon in legitimate communications from trusted organizations.
              </li>
              <li>
                <strong>Unexpected Request:</strong> The email asks for personal information and verification of identity, which is unusual and should be a red flag.
              </li>
            </ul>
          </div>
        )}

        <button
          className="next-button"
          onClick={goToNext}
          aria-label="Proceed to the Email Security Quiz page"
        >
          Next
        </button>
      </section>
    </div>
  );
};

export default EmailSecurityFour;
