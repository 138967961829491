import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getAuth, applyActionCode, signOut, sendEmailVerification, } from "firebase/auth"; // Removed sendEmailVerification import
import "./EmailVerification.css";

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("Verifying your email, please wait...");
  const [statusClass, setStatusClass] = useState("info-message");
  const [showResend, setShowResend] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const oobCode = searchParams.get("oobCode");
    const mode = searchParams.get("mode");
  
    if (mode === "verifyEmail" && oobCode) {
      verifyEmail(oobCode);
    } else if (mode === "resetPassword") {
      navigate("/reset-password" + window.location.search);
    } else {
      setMessage("⚠️ Invalid or missing verification code.");
      setStatusClass("error-message");
      setShowResend(true);
    }
  }, [searchParams]);
  
  

  const verifyEmail = async (oobCode) => {
    try {
      console.log("✅ Applying action code:", oobCode);
      await applyActionCode(auth, oobCode);
      setMessage("✅ Your email has been successfully verified! You can now log in.");
      setStatusClass("success-message");
      setShowResend(false);

      // Sign the user out to refresh their session
      await signOut(auth);
    } catch (error) {
      console.error("❌ Verification Error:", error);

      let errorMessage = "⚠️ Verification failed. The link may have expired or is invalid.";

      if (error.code === "auth/expired-action-code") {
        errorMessage = "❌ This verification link has expired. Please request a new verification email.";
      } else if (error.code === "auth/invalid-action-code") {
        errorMessage = "⚠️ This verification link is invalid. It may have been used already.";
      } else if (error.code === "auth/user-disabled") {
        errorMessage = "🚫 This account has been disabled.";
      } else if (error.code === "auth/user-not-found") {
        errorMessage = "⚠️ No user found for this verification link.";
      }

      setMessage(errorMessage);
      setStatusClass("error-message");
      setShowResend(true);
    }
  };

  const handleResendVerification = async () => {
    try {
      const user = auth.currentUser;
      if (user && !user.emailVerified) {
        await sendEmailVerification(user);
        setMessage("📧 A new verification email has been sent! Please check your inbox.");
        setStatusClass("info-message");
        setShowResend(false);
      }
    } catch (error) {
      console.error("❌ Error resending verification email:", error);
      setMessage("❌ Could not resend verification email. Try again later.");
      setStatusClass("error-message");
    }
  };
  

  return (
    <main className="verification-container" role="main">
      <h1 tabIndex="0">Email Verification</h1>
      <p role="status" aria-live="assertive" className={statusClass}>
        {message}
      </p>

      {showResend && (
        <button 
          className="resend-button" 
          onClick={handleResendVerification}
          aria-label="Resend verification email"
        >
          Resend Verification Email
        </button>
      )}

      <button 
        className="login-button" 
        onClick={() => navigate("/login")}
        aria-label="Go back to login page"
      >
        Back to Login
      </button>
    </main>
  );
};

export default EmailVerification;
