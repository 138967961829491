import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay

const SpearPhishingTask = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const spearPhishingRef = useRef(null); // Reference for the Spear Phishing section
  const warningRef = useRef(null); // Reference for focus management
  const videoRef = useRef(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Mark video as fully watched
    setShowWarning(false); // Hide warning if it was shown before
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'illegitimate');
    setShowWarning(false); // Reset warning when an answer is selected
  };

  const goToQuiz = () => {
    if (isVideoWatched) {
      navigate('/businessacademy/moduletwo/spear-phishing-task-two#PhishingHeading'); // Navigate to next task
    } else {
      setShowWarning(true); // Show warning when user tries to proceed without watching the video
      warningRef.current?.focus(); // Move focus to warning message for accessibility
    }
  };

  const goBack = () => {
    navigate('/businessacademy/moduletwo/phishing-tasks-two#PhishingHeading'); // Navigate to previous page
  };

  // Scroll to "Spear Phishing" section if hash is present
  useEffect(() => {
    if (location.hash === '#PhishingHeading' && spearPhishingRef.current) {
      spearPhishingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container" role="main">
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to the previous page"
      >
        ←
      </button>

      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h2 ref={spearPhishingRef} id="PhishingHeading" tabIndex="0">
                Spear Phishing
              </h2>
            </div>
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay />
              <video
                ref={videoRef}
                src="/assets/videos/SpearPhishingModuleTwo.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                aria-label="Spear phishing awareness training video"
              >
                <track kind="captions" src="/assets/videos/SpearPhishingModuleTwo.vtt" srclang="en" label="English captions" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className="module-section2">
          <div className="module-content2">
            
              <div className="task-instruction">
                <h3><strong>Your task is to decide if the email is legitimate or illegitimate.</strong></h3>
              </div>
            
          </div>

          <div className="email-example-box">
            <h4>Example Email</h4>
            <div className="email-content">
              <p><strong>From:</strong> John@innovatetechsolutions.com</p>
              <p><strong>Subject:</strong> Urgent: Confidential Request</p>
              <p>Hi David,</p>
              <p>
                I need you to urgently do a bank transfer of £5,000 to our new supplier. Please find the details attached. 
                Ensure this is completed within the next hour as it is critical for our ongoing project. 
                Do not discuss this with anyone as it is confidential.
              </p>
              <p>Thank you for your prompt attention to this matter.</p>
              <p>Sincerely,</p>
              <p>John</p>
              <p>CEO, Innovate Tech Solutions</p>
            </div>

            <div className="answer-buttons">
              <button 
                onClick={() => handleAnswer('legitimate')} 
                aria-label="Select if you think the email is legitimate"
              >
                Legitimate
              </button>
              <button 
                onClick={() => handleAnswer('illegitimate')} 
                aria-label="Select if you think the email is illegitimate"
              >
                Illegitimate
              </button>
            </div>

            {isAnswered && (
              <div 
                className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`} 
                role="alert" 
                aria-live="polite"
              >
                {isCorrect ? 'Well done! This is a spear phishing email.' : 'Wrong answer. Try again.'}
              </div>
            )}
          </div>

          {isAnswered && (
            <div className="email-legitimate">
              <h4>Why is this email a spear phishing attempt?</h4>
              <ul>
                <li><strong>Email Address:</strong> While the email may appear to be from the CEO, it's important to carefully verify the actual email address and domain.</li>
                <li><strong>Urgency:</strong> The email creates a false sense of urgency, pressuring the recipient to act quickly.</li>
                <li><strong>Confidentiality:</strong> The sender requests secrecy, preventing verification.</li>
                <li><strong>Unusual Request:</strong> A sudden request for a bank transfer is a common spear phishing tactic.</li>
                <li><strong>Remember:</strong> If you are uncertain, always follow company procedures.</li>
              </ul>
            </div>
          )}
        </div>

        <button 
          className="start-quiz-button" 
          onClick={goToQuiz} 
         
          aria-disabled={!isVideoWatched}
        >
          Next Task
        </button>

        {showWarning && (
          <p 
            className="warning-text" 
            ref={warningRef} 
            role="alert" 
            tabIndex="0"
          >
            You must watch the entire video before proceeding.
          </p>
        )}
      </section>
    </div>
  );
};

export default SpearPhishingTask;
