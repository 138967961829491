import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../ModuleTwo/WhatIsCyberSecurity.css';

const CyberThreats = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const threatsHeadingRef = useRef(null); // Ref for the Types of Cyber Threats heading
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module3/malware#MalwareHeading');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module2/importance-cyber#ImportanceHeading');
  };

  // Scroll to the Types of Cyber Threats heading if the hash is present
  useEffect(() => {
    if (location.hash === '#CyberThreatsHeading' && threatsHeadingRef.current) {
      threatsHeadingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <main className="cybersecurity-basics-container">
      <header className="navigation-header">
        <button
          className="back-arrow-button"
          onClick={goBack}
          aria-label="Go back to the importance of cybersecurity section"
        >
          ←
        </button>
      </header>

      <section className="module-section">
        <header className="cybersecurity-basics-header" ref={threatsHeadingRef}>
          <h2 aria-level="1">Types of Cyber Threats</h2>
        </header>

        <section className="content-layout">
          <article className="module-content">
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay />
              <video
                ref={videoRef}
                src="/assets/videos/ModuleThree.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error('Video Error:', e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                aria-label="Video explaining types of cyber threats"
              >
                Your browser does not support the video tag.
              </video>
              
            </div>
          </article>
        </section>

        <footer className="navigation-footer">
          <button
            className="start-quiz-button"
            onClick={handleNextClick}
            aria-label="Proceed to the next module on malware"
          >
            Next
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </footer>
      </section>
    </main>
  );
};

export default CyberThreats;
