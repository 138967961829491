import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './WhatIsCyberSecurity.css';

const ImportanceCyber = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const importanceRef = useRef(null); // Ref for the heading

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module2/importance-cyber-quiz');
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module2/what-is-cyber-security');
  };

  // Scroll to the heading if the hash is present
  useEffect(() => {
    if (location.hash === '#ImportanceHeading' && importanceRef.current) {
      importanceRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <main className="cybersecurity-basics-container">
      <header className="navigation-header">
        <button
          className="back-arrow-button"
          onClick={goBack}
          aria-label="Go back to the previous section"
        >
          ←
        </button>
      </header>

      <section className="module-section">
        <header className="cybersecurity-basics-header" ref={importanceRef}>
          <h2 aria-level="1">Why is Cybersecurity Important for Individuals?</h2>
        </header>

        <section className="module-content">
          {[
            {
              title: 'Protection of Personal Information',
              content:
                'Personal information such as credit card details, medical records, and personal communications are valuable to cybercriminals. Protecting this information helps prevent identity theft, financial fraud, and privacy invasions.',
            },
            {
              title: 'Financial Security',
              content:
                'Cyberattacks can lead to significant financial loss for individuals. Phishing scams, online banking fraud, and unauthorized transactions can drain bank accounts and damage credit scores. Cybersecurity measures help protect financial assets and transactions.',
            },
            {
              title: 'Preventing Identity Theft',
              content:
                'Identity theft occurs when someone uses another person\'s personal information without permission to commit fraud or other crimes. This can result in long-term damage to the victim\'s credit and financial standing. Effective cybersecurity practices help protect against identity theft.',
            },
            {
              title: 'Safeguarding Digital Assets',
              content:
                'Photos, documents, and other digital assets stored on personal devices are often irreplaceable. Cybersecurity measures such as regular backups and anti-malware protection ensure these assets are protected from loss or damage.',
            },
            {
              title: 'Maintaining Privacy',
              content:
                'In an era where personal data is frequently collected and shared, maintaining privacy is increasingly challenging. Cybersecurity helps individuals control their personal information and protect their privacy from unauthorized access and misuse.',
            },
            {
              title: 'Protecting Personal Devices',
              content:
                'Malware and viruses can cause significant damage to personal devices, leading to data loss, reduced functionality, or complete device failure. Ensuring cybersecurity helps keep personal devices running smoothly and securely.',
            },
            {
              title: 'Ensuring Safe Online Experiences',
              content:
                'From social media to online shopping, individuals engage in numerous online activities that can be targets for cyberattacks. Cybersecurity measures help ensure these activities are conducted safely, protecting individuals from scams, cyberbullying, and other online threats.',
            },
            {
              title: 'Avoiding Extortion and Blackmail',
              content:
                'Cybercriminals may use ransomware to lock individuals out of their devices or threaten to release personal information unless a ransom is paid. Cybersecurity practices help prevent such scenarios by safeguarding data and systems.',
            },
            {
              title: 'Securing Home Networks',
              content:
                'With the increasing number of smart devices in homes, securing home networks is essential to protect against unauthorized access and potential breaches.',
            },
          ].map((item, index) => (
            <article
              className={`bordered-container ${index % 2 === 0 ? 'alt-color' : ''}`}
              key={item.title}
            >
              <h3>{item.title}</h3>
              <p>{item.content}</p>
            </article>
          ))}
        </section>

        <footer className="navigation-footer">
        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button1"
            onClick={goToNext}
            aria-label="Proceed to the cybersecurity importance quiz"
          >
            Start Quiz
          </button>
          </div>
        </footer>
      </section>
    </main>
  );
};

export default ImportanceCyber;
