import React, { useRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Home.css'; // Import the CSS file
import tutorialImage from '../assets/sectiontwo.webp'; // Import the image
import ThreatStatisticsImage from '../assets/ThreatStatistics.png'; // Image to pop out on hover
import ThreatReportsImage from '../assets/ThreatReports.png'; // Image to pop out on hover
import EmployeeProgressImage from '../assets/EmployeeProgress.png'; // Image to pop out on hover
import AwareImage from '../assets/Aware.png'; // Image to pop out on hover
import Footer from './Footer/Footer';

const Home = () => {
  const location = useLocation();
  const videoRef = useRef(null); // Reference for the main video container
  const introVideoRef = useRef(null); // Reference for intro video within the container
  const [hoveredFeature, setHoveredFeature] = useState(null);

    

  useEffect(() => {
    // Scroll to the "Home Video Section" if hash is present
    if (location.hash === '#HomeVideoSection' && videoRef.current) {
      videoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const handleTimeUpdate = () => {
      if (introVideoRef.current.duration - introVideoRef.current.currentTime <= 1) {
        introVideoRef.current.pause();
      }
    };

    if (introVideoRef.current) {
      introVideoRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }

    // Cleanup: remove event listener when component is unmounted
    return () => {
      if (introVideoRef.current) {
        introVideoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [location.hash]);

  const featureImages = [EmployeeProgressImage, ThreatReportsImage, ThreatStatisticsImage, AwareImage];

  return (
    <>
      <div className="main-container-content-home2">
  {/* First Section with unique SVG class */}
  <section className="section section-1-home2">
    <div className="video-container2-home2" style={{ position: 'relative' }}>
      {/* Hidden Headings for Screen Readers */}
      <h1 className="sr-only">Be Cyber Aware</h1>
      <p className="sr-only">Your Journey to a Safer Online Experience Starts Here</p>

      <div className="intro-video-wrapper-home2">
        <video
          className="intro-video-home2"
          autoPlay
          muted
          ref={videoRef}
          disablePictureInPicture
          controlsList="nodownload"
        >
          <source src="/assets/videos/MainIntro.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

    
    </div>
    <svg
      className="svg-section-1-home2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 150"
    >
      <path
        fill="#ffeadf"
        fillOpacity="1"
        d="M0,130L60,125C120,120,240,110,360,105C480,100,600,100,720,105C840,110,960,120,1080,125C1200,130,1320,125,1380,122L1440,120L1440,150L1380,150C1320,150,1200,150,1080,150C960,150,840,150,720,150C600,150,480,150,360,150C240,150,120,150,60,150L0,150Z"
      ></path>
    </svg>
  </section>


        {/* Second Section: Courses */}
        <section className="section section-2-home2">
          <div className="content-wrapper-home2">
            <div className="content-container-home2">
              <h2 className="section-title222-home2">Course Package</h2>
              <p className="section-subtitle-home2">
                Explore our courses designed by industry experts to equip you with the knowledge to be Cyber Secure. Complete all 3 and gain a certificate!
              </p>
              <div className="course-buttons-home2">
               <Link to="/courses-two#section-2-courses" aria-label="Go to Cyber Shield Basics course">
                 <button className="course-button course1">Cyber Shield: Basics</button>
               </Link>

                <Link to="/courses-two#section-3-courses"aria-label="Go to Cyber Shield Intermediate course">
                  <button className="course-button course2">Cyber Shield: Intermediate</button>
                </Link>
                <Link to="/courses-two#section-4-courses" aria-label="Go to Cyber Shield Business course">
                  <button className="course-button course3">Cyber Shield: Business Academy</button>
                </Link>
              </div>
            </div>
            <div className="image-container-home2">
            <img src={tutorialImage} alt="Cybersecurity course tutorial" className="tutorial-image-course2" />

            </div>
          </div>
        </section>

        {/* Third Section: Manager's Account */}
        <section className="section section-3-home2">
          <div className="manager-content-container-home2">
            <h2 className="section-title-manager-home2" style={{ color: 'white' }}>
              Why Not try to Our Manager's Account?
            </h2>
            <div className="ManagerSubtitles-home2">
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px', fontSize: '22px' }}>
                <span>🟢</span> 
              <h3 style={{ color: 'white', fontWeight: 'bold', margin: 0 }}>Secure Your Business</h3>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px', fontSize: '22px' }}>
               <span>🟢</span>
              <h3 style={{ color: 'white', fontWeight: 'bold', margin: 0 }}>Help Meet GDPR Compliance</h3>
            </div>
             <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px', fontSize: '22px' }}>
              <span>🟢</span>
              <h3 style={{ color: 'white', fontWeight: 'bold', margin: 0 }}>Access to All Courses</h3>
           </div>
        </div>

            <div className="manager-features-home2">
              {['Track Employee Progress', 'Receive Threat Reports', 'Analyse Graphs & Statistics', 'Add Your Logo'].map((title, index) => (
                <div className="feature-home2" onMouseEnter={() => setHoveredFeature(index)} onMouseLeave={() => setHoveredFeature(null)} tabIndex="0" aria-label={`Feature: ${title}`}>


                  <h4 style={{ color: '#000000',  fontWeight: 'bold', fontSize: '23px'  }}>
                    {title === 'Track Employee Progress' ? ' ' : title === 'Receive Threat Reports' ? ' ' : title === 'Analyse Graphs & Statistics' ? '' : ' '}
                    {title}
                  </h4>
                  <p style={{ color: 'green', fontSize: '18px', marginTop: '1rem' }}>
                    {title === 'Track Employee Progress'
                      ? 'Stay in the loop as your team sharpens their cyber skills, viewing real-time updates on their learning journey'
                      : title === 'Receive Threat Reports'
                      ? 'Get timely threat reports from employees and be prepared to tackle potential risks head-on'
                      : title === 'Analyse Graphs & Statistics'
                      ? 'Visualise reported threats with insightful graphs and stats, making data-driven decisions a breeze'
                      : 'Personalise your company’s platform by adding your business logo—because every detail matters'}
                  </p>

                  {hoveredFeature === index && (
                    <img src={featureImages[index]} alt={`Detailed feature visualization: ${title}`}

                      style={{
                        position: 'absolute',
                        top: '-150%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '600px',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                        borderRadius: '10px',
                        zIndex: 100,
                      }}
                    />
                  )}
                </div>
              ))}
            </div>

            {/* Add your video here */}
            <div className="manager-account-video-container">
               <video ref={videoRef} className="manager-account-video" controls aria-label="Manager's Account Overview Video">
                 <source src="/assets/videos/ManagersAccount.mp4" type="video/mp4" />
                  <track
                    src="/assets/videos/ManagersAccountvtt.vtt"
                    kind="subtitles"
                    srcLang="en"
                    label="English"
      
                  />
                     Your browser does not support the video tag.
                </video>
          </div>
          </div>
        </section>

        
        <section className="section4-home2">
  <div className="content-wrapper-section4-home2">
    <div className="content-container-section4-home2" style={{ textAlign: 'center' }}>
      {/* Free Account Section */}
      <h2 className="section-title22-section4-home2">Start Your Journey to Become Cyber Secure, Sign Up for Our Free Account</h2>
      <ul className="course-summary" style={{ listStyle: 'none', lineHeight: '2', paddingLeft: '0', fontSize: '25px', color: '#005f73', textAlign: 'center', marginBottom: '30px' }}>
        <li style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <span style={{ marginRight: '10px', marginTop: '5px' }}></span>
          Start with a free account. Sample the course, upgrade anytime
        </li>
        <li style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <span style={{ marginRight: '10px', marginTop: '5px' }}></span>
          Course 1 Included Free
        </li>
      </ul>

      {/* Upgrade to Standard Account Section */}
      <h3 className="section-title22-section4-home2">Upgrade to Personal Plan</h3>
      <ul className="course-summary" style={{ listStyle: 'none', lineHeight: '2', paddingLeft: '0', fontSize: '25px', color: '#005f73', textAlign: 'center' }}>
        <li style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <span style={{ marginRight: '10px', marginTop: '5px' }}></span>
          Access to All Courses
        </li>
        <li style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <span style={{ marginRight: '10px', marginTop: '5px' }}></span>
          Progress Tracking
        </li>
        <li style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <span style={{ marginRight: '10px', marginTop: '5px' }}></span>
          Certification on Completion of All Courses
        </li>
        <li style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <span style={{ marginRight: '10px', marginTop: '5px' }}></span>
          Work at Your Own Pace
        </li>
        <li style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <span style={{ marginRight: '10px', marginTop: '5px' }}></span>
         Linking Account to Manager (Optional)
        </li>
        <li style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <span style={{ marginRight: '10px', marginTop: '5px' }}></span>
          Increasing Cyber Awareness
        </li>
      </ul>
    </div>
  </div>
</section>





        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default Home;
