import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './RecognizingThreatsPartTwo.css'; // Correct path to the CSS file
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Add logo overlay

const RecognizingThreatsPartTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping

  const startQuiz = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page3/recognizing-threats-quiz#QuizTitle');
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Task" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page3/recognizing-threats#BasicsTitle'); // Navigate to the previous page
  };

  // Scroll to the BasicsTitle section if the hash matches
  useEffect(() => {
    if (location.hash === '#BasicsTitle') {
      const element = document.getElementById('BasicsTitle');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="cybersecurity-basics-container-r">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Recognizing Threats Part One"
      >
        ←
      </button>
      <br />
      <h2 id="BasicsTitle" className="BasicsTitle">Signs of Malware</h2>
      <section className="module-section">
        <div>
          <div className="video-container" style={{ position: 'relative' }}>
            <LogoOverlay /> {/* Add the logo overlay */}
            <video
              ref={videoRef}
              src="/assets/videos/PageThreePartTwo.mp4"
              width="100%"
              onEnded={handleVideoEnd}
              onError={(e) => console.error("Video Error:", e)}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
              disablePictureInPicture // Disable Picture-in-Picture
              aria-label="Educational video on recognizing malware threats"
            >
              Your browser does not support the video tag.
            </video>
          </div>

          <section className="module-section" style={{ textAlign: 'center' }}>
            <h2>Signs Your Computer Might Have Malware</h2>
          </section>

          <div className="module-content">
            <div className="highlighted-section">
              <h3>1. Slow Computer</h3>
              <p>
                <strong>What to Look For:</strong> Your computer is much slower than usual.
              </p>
            </div>

            <div className="highlighted-section">
              <h3>2. Lots of Pop-ups</h3>
              <p>
                <strong>What to Look For:</strong> You see many pop-up ads, even when not browsing.
              </p>
            </div>

            <div className="highlighted-section">
              <h3>3. New Programs You Didn't Install</h3>
              <p>
                <strong>What to Look For:</strong> New icons or programs appear that you didn't add.
              </p>
            </div>

            <div className="highlighted-section">
              <h3>4. Browser Changes</h3>
              <p>
                <strong>What to Look For:</strong> Your web browser's homepage changes without your permission.
              </p>
            </div>

            <div className="highlighted-section">
              <h3>5. Crashes and Freezes</h3>
              <p>
                <strong>What to Look For:</strong> Malware may cause your computer to crash or freeze more often.
              </p>
            </div>

            <div className="highlighted-section">
              <h3>6. Weird Network Activity</h3>
              <p>
                <strong>What to Look For:</strong> Your internet connection is slow, or you see unusual network activity.
              </p>
            </div>

            <div className="highlighted-section">
              <h3>7. Strange Emails Sent from Your Account</h3>
              <p>
                <strong>What to Look For:</strong> Friends get weird emails from you that you didn't send.
              </p>
            </div>
          </div>

          <section className="highlighted-section" style={{ textAlign: 'center' }}>
            <h3>Your task is to relay all this information onto Chloe. Good luck!</h3>
          </section>

          <div className="start-quiz-button-container">
            <button
              className="start-quiz-button"
              onClick={startQuiz}
              aria-disabled={!isVideoWatched}
              aria-label="Start quiz on recognizing malware threats"
            >
              Start Task
            </button>
            {showWarning && (
              <p
                className="warning-text22"
                role="alert"
              >
                You must watch the video before proceeding.
              </p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default RecognizingThreatsPartTwo;
