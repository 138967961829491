import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const CollaborationTools = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const collaborationRef = useRef(null);
  const videoRef = useRef(null);
  const warningRef = useRef(null);

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
      setTimeout(() => {
        warningRef.current?.focus();
      }, 100);
    } else {
      goToQuiz();
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/modulefive/collaboration-tools-quiz');
  };

  const goBack = () => {
    navigate('/businessacademy/modulefive/remote-work-security#RemoteWorkSecurityHeading');
  };

  useEffect(() => {
    if (location.hash === '#CollaborationToolsHeading' && collaborationRef.current) {
      collaborationRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the Remote Work Security module"
      >
        ←
      </button>

      <div className="cybersecurity-basics-header">
        <br />
        <h2 ref={collaborationRef}>Securing Collaboration Tools</h2>
      </div>
      

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="/assets/videos/SecuringCollaborationToolsModuleFive.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Collaboration Tools Security Awareness Video"
          >
            <track kind="captions" src="/assets/videos/SecuringCollaborationToolsModuleFive.vtt" label="English captions" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Access Control</h3>
            <ul>
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Password-Protect Meetings:</strong> Always use passwords to secure online meetings and prevent unauthorised participants from joining.</li> <br />
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Role-Based Permissions:</strong> Set appropriate permissions for participants in online meetings.</li>
            </ul>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Ensure Software is Up-to-Date</h3> 
            <ul>
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Regular Updates:</strong> Keep all collaboration tools updated with the latest security patches.</li> <br />
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Use Approved Tools:</strong> Only use company-approved collaboration tools.</li>
            </ul>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Data Security During Collaboration</h3> 
            <ul>
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Secure File Sharing:</strong> Use encrypted services to protect sensitive data.</li> <br />
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Be Mindful of Recording:</strong> Ensure recorded meetings are securely stored and access is restricted.</li>
            </ul>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Be Wary of Phishing Attempts</h3> 
            <ul>
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Verify Links:</strong> Always verify the legitimacy of links shared during meetings.</li> <br />
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Secure Invites:</strong> Send meeting invitations through secure channels.</li>
            </ul>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button"
            onClick={handleNextClick}
            aria-label="Start Securing Collaboration Tools Quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" ref={warningRef} tabIndex="-1" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default CollaborationTools;
