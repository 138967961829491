import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './CybersecurityBasics.css'; // Updated path for the CSS file
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Add the logo overlay

const CybersecurityBasics = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current URL location
  const videoRef = useRef(null);
  const basicsTitleRef = useRef(null); // Reference for the title
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [progress, setProgress] = useState(0); // For video progress
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const goToQuiz = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page2/cybersecurity-quiz'); // Navigate to the quiz page without hash
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Quiz" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
    setProgress(100); // Set progress to 100 when the video ends
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }

    const progressPercent = (currentTime / duration) * 100;
    setProgress(progressPercent);
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page1/introduction#BasicsTitle'); // Replace with the actual previous page route
  };

  // Scroll to the title if the hash matches
  useEffect(() => {
    if (location.hash === '#BasicsTitle' && basicsTitleRef.current) {
      basicsTitleRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div
      className="introduction-container2"
      role="main"
      aria-label="Cybersecurity Basics"
    >
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Introduction page"
      >
        ←
      </button>

      <h2
        id="BasicsTitle"
        ref={basicsTitleRef}
        className="BasicsTitle"
        tabIndex={0}
        aria-label="What is Cybersecurity?"
      >
        What is Cybersecurity?
      </h2>

      <section
        className="module-section"
        aria-labelledby="BasicsTitle"
        role="region"
        aria-label="Cybersecurity Basics Content"
      >
        <div className="content-layout">
          <div
            className="video-container"
            id="video-container"
            style={{ position: 'relative' }}
            role="region"
            aria-label="Cybersecurity Basics Introduction Video"
          >
            <LogoOverlay />
            <video
              ref={videoRef}
              src="/assets/videos/PageTwo.mp4"
              width="100%"
              onEnded={handleVideoEnd}
              onError={(e) => console.error("Video Error:", e)}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate"
              disablePictureInPicture
              aria-label="Introduction to Cybersecurity Basics"
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div
          className="module-content"
          role="region"
          aria-label="Cybersecurity Basics Information"
        >
          <div className="highlighted-section">
            <h3>What is Cybersecurity?</h3>
            <p>
              Cybersecurity refers to the practice of protecting systems, networks, and programs from digital attacks.
            </p>
            <br />
            <h3>Why is Cybersecurity Important?</h3>
            <p>
              Cybersecurity is crucial to protect sensitive information, prevent data breaches, and maintain privacy.
            </p>
            <br></br>
          </div>

          
        </div>

        <div
          className="start-quiz-button-container"
          role="region"
          aria-label="Start Quiz Section"
        >
          <button
            className="start-quiz-button"
            onClick={goToQuiz}
            aria-label="Start Cybersecurity Quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p
              className="warning-text22"
              role="alert"
              aria-live="assertive"
            >
              You must watch the video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default CybersecurityBasics;
