import React, { useState, useEffect, useRef} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, setIsEmailVerified } from 'firebase/auth';

import { signOut } from 'firebase/auth';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import CookieConsent from './components/Cookies/CookieConsent'; // Import the cookie component
import CookiePolicy from './components/Cookies/CookiePolicy';

import ScrollToTop from './components/ScrollToTop'; // Import the component

import UserRoute from './components/UserRoute.js'; // Update the path based on where you store it
import ResetPasswordPage from "./components/ResetPasswordPage";

import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { firestore } from './firebase'; // <-- Add this line
import { doc, getDoc } from 'firebase/firestore'; // Add these imports

import AdminDashboard from './components/AdminDashboard/AdminDashboard.js';
import AdminRoute from './components/AdminRoute'; // Import AdminRoute
import GroupedUsers from './components/AdminDashboard/GroupedUsers';
import ManagerRoute from './components/ManagerRoute';



import CoursesTwo from './components/CoursesTwo.js';
import Home from './components/Home';
import Login from './components/Login';

import TopBar from './components/TopBar';
import Sidebar from './components/Sidebar.js'; // Import Sidebar
import SidebarTwo from './components/SidebarTwo.js'; // Import SidebarTwo
import SidebarThree from './components/SideBarThree.js';
import SidebarFour from './components/SidebarFour.js';
import Profile from './components/ManagersPortal/Profile.js'; // Adjust the path if needed
import './App.css';
import Introduction from './BeginnersCourse/Page1/Introduction';
import CybersecurityBasics from './BeginnersCourse/Page2/CybersecurityBasics';
import RecognizingThreats from './BeginnersCourse/Page3/RecognizingThreats';
import RecognizingThreatsPartTwo from './BeginnersCourse/Page3/RecognizingThreatsPartTwo';
import StrongPasswords from './BeginnersCourse/Page4/StrongPasswords';
import SafeBrowsing from './BeginnersCourse/page5/SafeBrowsing';


import StrongPasswordsPartTwo from './BeginnersCourse/Page4/StrongPasswordsPartTwo';
import StrongPasswordsPartThree from './BeginnersCourse/Page4/StrongPasswordsPartThree';
import PasswordGame from './BeginnersCourse/Page4/PasswordGame';
import SafeBrowsingGame from './BeginnersCourse/page5/SafeBrowsingGame';
import SafeBrowsingPartTwo from './BeginnersCourse/page5/SafeBrowsingPartTwo';
import SocialMediaSafety from './BeginnersCourse/page6/SocialMediaSafety';
import SocialMediaSafetyPartTwo from './BeginnersCourse/page6/SocialMediaSafetyPartTwo';
import SocialMediaDropDragGame from './BeginnersCourse/page6/SocialMediaDropDragGame';
import SafeBrowsingDropDragGame from './BeginnersCourse/page5/SafeBrowsingDropDragGame';
import EmailSecurity from './BeginnersCourse/page7/EmailSecurity';
import EmailSecurityTwo from './BeginnersCourse/page7/EmailSecurityTwo';
import EmailSecurityThree from './BeginnersCourse/page7/EmailSecurityThree';
import EmailSecurityQuiz from './BeginnersCourse/page7/EmailSecurityQuiz';
import EmailSecurityFour from './BeginnersCourse/page7/EmailSecurityFour';
import TwoFactorAuth from './BeginnersCourse/Page4.5.js/TwoFactorAuth';
import TwoFactorAuthPartTwo from './BeginnersCourse/Page4.5.js/TwoFactorAuthPartTwo';
import TwoFactorAuthQuiz from './BeginnersCourse/Page4.5.js/TwoFactorAuthQuiz';
import CyberSecurityQuiz from './BeginnersCourse/Page2/CyberSecurityQuiz';
import FinisherPage from './BeginnersCourse/Page 8/FinishPage';
import IntroductionPartTwo from './BeginnersCoursePartTwo/Introduction/IntroductionPartTwo';
import WhatIsCyberSecurity from './BeginnersCoursePartTwo/ModuleTwo/WhatIsCyberSecurity';
import WhatIsCyberSecurityQuiz from './BeginnersCoursePartTwo/ModuleTwo/WhatIsCyberSecurityQuiz';
import ImportanceCyber from './BeginnersCoursePartTwo/ModuleTwo/ImportanceCyber';
import ImportanceCyberQuiz from './BeginnersCoursePartTwo/ModuleTwo/ImportanceCyberQuiz';
import CyberThreats from './BeginnersCoursePartTwo/ModuleThree/CyberThreats';
import Malware from './BeginnersCoursePartTwo/ModuleThree/Malware';
import RemoveMalwareGuide from './BeginnersCoursePartTwo/ModuleThree/RemoveMalwareGuide';
import MalwareQuiz from './BeginnersCoursePartTwo/ModuleThree/MalwareQuiz';
import Phishing from './BeginnersCoursePartTwo/ModuleThree/Phishing';
import PhishingQuiz from './BeginnersCoursePartTwo/ModuleThree/PhishingQuiz.js';
import Ransomware from './BeginnersCoursePartTwo/ModuleThree/Ransomware.js';
import RansomwareQuiz from './BeginnersCoursePartTwo/ModuleThree/RansomwareQuiz.js';
import MobileSecurity from './BeginnersCoursePartTwo/ModuleFour/MobileSecurity.js';
import Poster from './BeginnersCoursePartTwo/ModuleFour/Poster.js';
import MobileSecurityQuiz from './BeginnersCoursePartTwo/ModuleFour/MobileSecurityQuiz.js';
import HomeNetwork from './BeginnersCoursePartTwo/ModuleFive/HomeNetwork.js';
import HomeNetworkQuiz from './BeginnersCoursePartTwo/ModuleFive/HomeNetworkQuiz.js';
import PublicWifi from './BeginnersCoursePartTwo/ModuleSix/PublicWifi.js';
import PublicWifiPartTwo from './BeginnersCoursePartTwo/ModuleSix/PublicWifiPartTwo.js';
import PublicWifiQuiz from './BeginnersCoursePartTwo/ModuleSix/PublicWifiQuiz.js';
import DataBackup from './BeginnersCoursePartTwo/ModuleSeven/DataBackup.js';
import RemoveMalwareGuide2 from './BeginnersCoursePartTwo/ModuleSeven/RemoveMalwareGuide2.js';
import DataBackupPartTwo from './BeginnersCoursePartTwo/ModuleSeven/DataBackupPartTwo.js';
import DataBackupQuiz from './BeginnersCoursePartTwo/ModuleSeven/DataBackupQuiz.js';
import CreditProfile from './BeginnersCoursePartTwo/ModuleEight/CreditProfile.js';
import CreditReportQuiz from './BeginnersCoursePartTwo/ModuleEight/CreditReportQuiz.js';
import FinishingPageTwo from './BeginnersCoursePartTwo/FinishingPageTwo.js';
import RemoveMalwareGuide3 from './BeginnersCoursePartTwo/ModuleThree/RemoveMalwareGuide3.js';
import RecognizingThreatsQuiz from './BeginnersCourse/Page3/RecognizingThreatsQuiz.js';
import SocialMediaSafetyQuiz from './BeginnersCourse/page6/SocialMediaSafetyQuiz.js';
import Intro from './BusinessAcademy/ModuleOne/Intro.js';
import IntroQuiz from './BusinessAcademy/ModuleOne/IntroQuiz.js';
import Statistics from './BusinessAcademy/ModuleOne/statistics.js';
import EmployeeRole from './BusinessAcademy/ModuleOne/EmployeesRole.js';
import RecognizingCyberThreats from './BusinessAcademy/ModuleTwo/RecognizingCyberThreats.js';
import EmployeeRoleQuiz from './BusinessAcademy/ModuleOne/EmployeeRoleQuiz.js';
import RecognizingQuiz from './BusinessAcademy/ModuleTwo/RecognizingQuiz.js';
import PhishingTasks from './BusinessAcademy/ModuleTwo/PhishingTasks.js';
import PhishingTaskTwo from './BusinessAcademy/ModuleTwo/PhishingTaskTwo.js';
import SpearPhishingTask from './BusinessAcademy/ModuleTwo/SpearPhishingTask.js';
import SpearPhishingTask2 from './BusinessAcademy/ModuleTwo/SpearPhishingTask2.js';
import Smishing from './BusinessAcademy/ModuleTwo/Smishing.js';
import Vishing from './BusinessAcademy/ModuleTwo/Vishing.js';
import VishingQuiz from './BusinessAcademy/ModuleTwo/VishingQuiz.js';
import SocialEngineering from './BusinessAcademy/ModuleTwo/SocialEngineering.js';
import SocialEngineeringQuiz from './BusinessAcademy/ModuleTwo/SocialEngineeringQuiz.js';
import InsiderThreats from './BusinessAcademy/ModuleTwo/InsiderThreats.js';
import InsiderThreatsQuiz from './BusinessAcademy/ModuleTwo/InsiderThreatsQuiz.js';
import SecuringWorkDevices from './BusinessAcademy/ModuleThree/SecuringWorkDevices.js';
import SecuringWorkDevicesQuiz from './BusinessAcademy/ModuleThree/SecuringWorkDevicesQuiz.js';
import Incidents from './BusinessAcademy/ModuleFour/Incident.js';
import IncidentsQuiz from './BusinessAcademy/ModuleFour/IncidentsQuiz.js';
import ReportingProcedures from './BusinessAcademy/ModuleFour/ReportingProcedures.js';
import ReportingProceduresQuiz from './BusinessAcademy/ModuleFour/ReportingProceduresQuiz.js';
import RemoteWorkSecurity from './BusinessAcademy/ModuleFive/RemoteWorkSecurity.js';
import RemoteWorkQuiz from './BusinessAcademy/ModuleFive/RemoteWorkQuiz.js';
import CollaborationTools from './BusinessAcademy/ModuleFive/CollaborationTools.js';
import DataProtection from './BusinessAcademy/ModuleSix/DataProtection.js';
import CollaborationToolsQuiz from './BusinessAcademy/ModuleFive/CollaborationToolsQuiz.js';
import DataProtectionQuiz from './BusinessAcademy/ModuleSix/DataProtectionQuiz.js';
import Privacy from './BusinessAcademy/ModuleSix/Privacy.js';
import PrivacyQuiz from './BusinessAcademy/ModuleSix/PrivacyQuiz.js';
import Compliance from './BusinessAcademy/ModuleSix/Compliance.js';
import ComplianceQuiz from './BusinessAcademy/ModuleSix/ComplianceQuiz.js';
import FinishingPageThree from './BusinessAcademy/FinishingPage/FinishingPageThree.js';
import WhatIsCyberSecurityTwo from './BusinessAcademy/ModuleOne/WhatISCyberSecurity.js';
import ManagersPortal from './components/ManagersPortal/ManagersPortal.js';

import Progress from './components/ManagersPortal/Progress.js';
import AddReport from './components/ManagersPortal/AddReport.js';
import ThreatReports from './components/ManagersPortal/ThreatReports.js';

import ThreatStats from './components/ThreatStats.js';
import CheckingURLs from './BeginnersCourse/Page5.5/CheckingURLs.js';
import CheckingURLsQuiz from './BeginnersCourse/Page5.5/CheckingURLsQuiz.js';
import ThreatLandscape from './components/ThreatLandcape.js';

import Settings from './components/ManagersPortal/Settings.js';
import ManageUsers from './components/AdminDashboard/ManageUsers.js';

import AccountType from './components/AccountType.js';

import PrivacyPolicy from './components/LegalDocuments/PrivacyPolicy.js';
import UserAgreement from './components/LegalDocuments/UserAgreement.js';
import SettingsStandard from './components/SettingsStandard.js';
import StandardReport from './components/StandardReport.js';
import Footer2 from './components/Footer/Footer2.js';
import VerificationNotice from './components/VerificationNotice.js';
import AboutPage from './components/AboutPage.js';
import ResetPassword from './components/ResetPassword.js';
import Forbidden from './components/Forbidden.js';
import FreeUserSignUp from './components/FreeUserSignUp.js';
import ManagerPaymentSuccess from './components/ManagerPaymentSuccess.js';

import EmailVerification from './components/EmailVerification.js';





// Define ProtectedRoute for logged-in users with verified emails
const ProtectedRoute = ({ children }) => {
  const auth = getAuth();
  const user = auth.currentUser;

  // Check if user exists and if the user's email is verified
  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!user.emailVerified) {
    return <Navigate to="/verification-notice" />;
  }

  return children;
};




function App() {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate hook

 

  // Idle timer settings
  const IDLE_TIMEOUT = 15 * 60 * 1000; // 15 minutes of inactivity
  const logoutTimeoutRef = useRef(null); // Use useRef to persist across renders

 

  // Move useLocation here at the top level
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Reload the user if needed to get updated information
          await user.reload();
  
          // Fetch the user's profile data from Firestore
          const userProfileDoc = await getDoc(doc(firestore, "users", user.uid));
  
          if (userProfileDoc.exists()) {
            const userProfileData = userProfileDoc.data();
  
            setUser({
              uid: user.uid,
              name: user.displayName || 'Anonymous',
              emailVerified: user.emailVerified,
              photoURL: user.photoURL || 'default-profile-pic-url.jpg',
              profile: userProfileData.profile || { accountType: 'standard' },
            });
          } else {
            setUser(null);
          }
        } catch (error) {
          console.error("Error fetching user profile data:", error);
        }
      } else {
        setUser(null);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);
  
  

  // Handle idle timeout to log out the user
  useEffect(() => {
    const resetTimeout = () => {
      clearTimeout(logoutTimeoutRef.current);
      logoutTimeoutRef.current = setTimeout(() => {
        handleLogout();
      }, IDLE_TIMEOUT);
    };
  
    const handleActivity = () => {
      resetTimeout(); // Reset the timer on activity
    };
  
    const handleLogout = () => {
      console.log("Logging out due to inactivity");
      signOut(getAuth()).then(() => {
        navigate('/login');
      }).catch((error) => {
        console.error('Error logging out:', error);
      });
    };
  
    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);
  
    // Initialize the timeout
    resetTimeout();
  
    // Cleanup on unmount
    return () => {
      clearTimeout(logoutTimeoutRef.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
    };
  }, [IDLE_TIMEOUT, navigate]); // Add IDLE_TIMEOUT here as a dependency
  

  const handleEmailLogin = async (email, password) => {
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await user.reload(); // Make sure to reload user information
  
      setUser({
        uid: user.uid,
        name: user.displayName || 'Anonymous',
        emailVerified: user.emailVerified,
        photoURL: user.photoURL || 'default-profile-pic-url.jpg',
      });
  
      if (user.emailVerified) {
        navigate('/');
      } else {
        navigate('/verification-notice');
      }
    } catch (error) {
      console.error('Error logging in with email:', error);
    }
  };
  
  

  const hideTopBar = location.pathname === '/login';


  const showSidebar = [
    "/beginnerscourse/page1/introduction",
    "/beginnerscourse/page2/cybersecurity-basics",
    "/beginnerscourse/page3/recognizing-threats",
    "/beginnerscourse/page3/recognizing-threats-part-two",
    "/beginnerscourse/page4/strong-passwords",
    "/beginnerscourse/page4/strong-passwords-part-two",
    "/beginnerscourse/page4/strong-passwords-part-three",
    "/beginnerscourse/page4.5/two-factor-auth",
    "/beginnerscourse/page4.5/two-factor-auth-part-two",
    "/beginnerscourse/page5/safe-browsing",
    "/beginnerscourse/page5/safe-browsing-part-two",
    "/beginnerscourse/page5.5/checking-urls",
    "/beginnerscourse/page6/social-media-safety",
    "/beginnerscourse/page6/social-media-safety-part-two",
    "/beginnerscourse/page7/email-security",
    "/beginnerscourse/page7/email-security-two",
    "/beginnerscourse/page7/email-security-three",
    "/beginnerscourse/page7/email-security-four",
  ].includes(location.pathname);

  const showSidebarTwo = [
    "/beginnerscourseparttwo/introduction/introduction-part-two",
    "/beginnerscourseparttwo/module2/what-is-cyber-security",
    "/beginnerscourseparttwo/module2/importance-cyber",
    "/beginnerscourseparttwo/module3/cyber-threats",
    "/beginnerscourseparttwo/module3/malware",
    "/beginnerscourseparttwo/module3/phishing",
    "/beginnerscourseparttwo/module3/ransomware",
    "/beginnerscourseparttwo/module4/mobile-security",
    "/beginnerscourseparttwo/module4/poster",
    "/beginnerscourseparttwo/module5/home-network",
    "/beginnerscourseparttwo/module6/public-wifi",
    "/beginnerscourseparttwo/module6/public-wifi-part-two",
    "/beginnerscourseparttwo/module7/data-backup",
    "/beginnerscourseparttwo/module7/data-backup-part-two",
    "/beginnerscourseparttwo/module8/credit-profile",
  ].includes(location.pathname);

  const showSidebarThree = [
    "/businessacademy/moduleone/intro",
    "/businessacademy/moduleone/what-is-cybersecurity-two",
    
    "/businessacademy/moduleone/statistics",
    "/businessacademy/moduleone/employee-role",
    
    "/businessacademy/moduletwo/recognizing-cyber-threats",
    
    "/businessacademy/moduletwo/phishing-tasks",
    "/businessacademy/moduletwo/phishing-tasks-two",
    "/businessacademy/moduletwo/spear-phishing-task",
    "/businessacademy/moduletwo/spear-phishing-task-two",
    "/businessacademy/moduletwo/smishing",
    "/businessacademy/moduletwo/vishing",
    "/businessacademy/moduletwo/social-engineering",
    "/businessacademy/moduletwo/insider-threats",
    "/businessacademy/modulethree/securing-work-devices",
    "/businessacademy/modulefour/incidents",
    "/businessacademy/modulefour/reporting-procedures",
    "/businessacademy/modulefive/remote-work-security",
    "/businessacademy/modulefive/collaboration-tools",
    "/businessacademy/modulesix/data-protection",
    "/businessacademy/modulesix/privacy",
    "/businessacademy/modulesix/compliance",
  ].includes(location.pathname);

  const showSidebarFour = [
    "/progress",
    "/add-report",
    "/threat-reports",
    "/threat-stats",
    "/settings",
  ].includes(location.pathname);

  const footer2Routes = [
    "/beginnerscourse/page1/introduction",
    "/beginnerscourse/page2/cybersecurity-basics",
    "/beginnerscourse/page3/recognizing-threats",
    "/beginnerscourse/page3/recognizing-threats-part-two",
    "/beginnerscourse/page4/strong-passwords",
    "/beginnerscourse/page4/strong-passwords-part-two",
    "/beginnerscourse/page4/strong-passwords-part-three",
    "/beginnerscourse/page4.5/two-factor-auth",
    "/beginnerscourse/page4.5/two-factor-auth-part-two",
    "/beginnerscourse/page5/safe-browsing",
    "/beginnerscourse/page5/safe-browsing-part-two",
    "/beginnerscourse/page5.5/checking-urls",
    "/beginnerscourse/page6/social-media-safety",
    "/beginnerscourse/page6/social-media-safety-part-two",
    "/beginnerscourse/page7/email-security",
    "/beginnerscourse/page7/email-security-two",
    "/beginnerscourse/page7/email-security-three",
    "/beginnerscourse/page7/email-security-four",
    "/beginnerscourseparttwo/introduction/introduction-part-two",
    "/beginnerscourseparttwo/module2/what-is-cyber-security",
    "/beginnerscourseparttwo/module2/importance-cyber",
    "/beginnerscourseparttwo/module3/cyber-threats",
    "/beginnerscourseparttwo/module3/malware",
    "/beginnerscourseparttwo/module3/phishing",
    "/beginnerscourseparttwo/module3.ransomware",
    "/beginnerscourseparttwo/module4.mobile-security",
    "/beginnerscourseparttwo.module4.poster",
    "/beginnerscourseparttwo.module5.home-network",
    "/beginnerscourseparttwo/module6/public-wifi",
    "/beginnerscourseparttwo/module6.public-wifi-part-two",
    "/beginnerscourseparttwo.module7.data-backup",
    "/beginnerscourseparttwo/module7.data-backup-part-two",
    "/beginnerscourseparttwo/module8/credit-profile",
  ];

  const showFooter2 = footer2Routes.some(route => 
    location.pathname.startsWith(route) &&
    location.pathname !== '/beginnerscourse/page3/recognizing-threats-quiz' && location.pathname !== '/beginnerscourse/page4.5/two-factor-auth-quiz' && location.pathname !== '/beginnerscourse/page5/safe-browsing-drop-drag-game' && location.pathname !== '/beginnerscourse/page5/safe-browsing-game' && location.pathname !== '/beginnerscourse/page6/social-media-safety-quiz' && location.pathname !== '/beginnerscourse/page7/email-security-quiz' && location.pathname !== '/beginnerscourseparttwo/module2/what-is-cyber-security-quiz'  && location.pathname !== '/beginnerscourseparttwo/module2/importance-cyber-quiz' && location.pathname !== '/beginnerscourseparttwo/module3/malware-quiz' && location.pathname !== '/beginnerscourseparttwo/module3/phishing-quiz' && location.pathname !== '/beginnerscourseparttwo/module6/public-wifi-quiz'
  );

   

  return (
    <>
    <ScrollToTop /> {/* Ensure ScrollToTop is included here */}
    <div className="App">
      {/* Cookie Consent Banner */}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="userCookieConsent"
        style={{ background: "#2B373B", color: "#fff" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        enableDeclineButton
      >
        We use cookies to improve your experience.{" "}
        <a href="/cookie-policy" style={{ color: "#4e9c81" }}>
          Learn more
        </a>
      </CookieConsent>


      {/* Top bar */}
      {!hideTopBar && <TopBar user={user} profile={profile} />}

      {/* Main content with sidebars */}
      <div className="main-content">
        {showSidebar && <Sidebar />}
        {showSidebarTwo && <SidebarTwo />}
        {showSidebarThree && <SidebarThree />}
        {showSidebarFour && <SidebarFour />}

        <div className={showSidebar || showSidebarTwo || showSidebarThree || showSidebarFour ? "content-with-sidebar" : "content"}>
          
          <Routes>

          <Route path="/admindashboard" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
          <Route path="/admindashboard/manage-users" element={<AdminRoute><ManageUsers /></AdminRoute>} />
          <Route path="/admindashboard/view-groups" element={<AdminRoute><GroupedUsers /></AdminRoute>} />
          
          <Route path="/managers-portal" element={<ManagerRoute><ManagersPortal /></ManagerRoute>} />

            <Route exact path="/" element={<Home />} />
            <Route path="/beginnerscourse/page1/introduction" element={<Introduction />} />
            {/* Only keep the cookie policy route */}
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            
            <Route path="/login" element={<Login handleEmailLogin={handleEmailLogin} />} />
          

            <Route path="/verify-email" element={<EmailVerification />} />

            <Route path="/reset-password-page" element={<ResetPasswordPage />} />

            <Route path="/free-user-signup" element={<FreeUserSignUp setUser={setUser} setProfile={setProfile} />} />

            
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />

           
            
            <Route path="/verification-notice" element={<VerificationNotice />} />

            <Route path="/403" element={<Forbidden />} />
             
             <Route path="/about-page" element={<AboutPage />} />

             <Route path="/threat-landscape" element={<ThreatLandscape />} />
          

             <Route path="/account-type" element={<AccountType />} />
             
             <Route path="/managersportal" element={<ManagerRoute><ManagersPortal /></ManagerRoute>} />
             <Route path="/progress" element={<ManagerRoute><Progress /></ManagerRoute>} />
             <Route path="/add-report" element={<ManagerRoute><AddReport /></ManagerRoute>} />
             <Route path="/Threat-Reports" element={<ManagerRoute><ThreatReports /></ManagerRoute>} />
             <Route path="/threat-stats" element={<ManagerRoute><ThreatStats /></ManagerRoute>} />
             <Route path="/settings" element={<ManagerRoute><Settings /></ManagerRoute>} />
             <Route path="/settings-standard" element={<SettingsStandard/>} />
             <Route path="/standard-report" element={<StandardReport/>} />
             <Route path="/privacy-policy" element={<PrivacyPolicy />} />
             <Route path="/user-agreement" element={<UserAgreement />} />
             <Route path="/reset-password" element={<ResetPassword />} />
             <Route path="/Courses-two" element={<CoursesTwo />} />

             <Route path="/manager-payment-success" element={<ManagerRoute><ManagerPaymentSuccess /></ManagerRoute>} />


            {/* Include all the other routes as well */}
            <Route path="/beginnerscourse/page2/cybersecurity-basics" element={<CybersecurityBasics />} />
              <Route path="/beginnerscourse/page2/cybersecurity-quiz" element={<CyberSecurityQuiz />} />
              <Route path="/beginnerscourse/page3/recognizing-threats" element={<RecognizingThreats />} />
              <Route path="/beginnerscourse/page3/recognizing-threats-quiz" element={<RecognizingThreatsQuiz />} />
              <Route path="/beginnerscourse/page3/recognizing-threats-part-two" element={<RecognizingThreatsPartTwo />} />
              <Route path="/beginnerscourse/page4/strong-passwords" element={<StrongPasswords />} />
              <Route path="/beginnerscourse/page4/strong-passwords-part-two" element={<StrongPasswordsPartTwo />} />
              <Route path="/beginnerscourse/page4/strong-passwords-part-three" element={<StrongPasswordsPartThree />} />
              <Route path="/beginnerscourse/page4/password-game" element={<PasswordGame />} />
              <Route path="/beginnerscourse/page4.5/two-factor-auth" element={<TwoFactorAuth />} />
              <Route path="/beginnerscourse/page4.5/two-factor-auth-part-two" element={<TwoFactorAuthPartTwo />} />
              <Route path="/beginnerscourse/page4.5/two-factor-auth-quiz" element={<TwoFactorAuthQuiz />} />
              <Route path="/beginnerscourse/page5/safe-browsing" element={<SafeBrowsing />} />
              <Route path="/beginnerscourse/page5/safe-browsing-part-two" element={<SafeBrowsingPartTwo />} />
              <Route path="/beginnerscourse/page5/safe-browsing-drop-drag-game" element={<SafeBrowsingDropDragGame />} />
              <Route path="/beginnerscourse/page5/safe-browsing-game" element={<SafeBrowsingGame />} />
              <Route path="/beginnerscourse/page5.5/Checking-URLs" element={<CheckingURLs />} />
              <Route path="/beginnerscourse/page5.5/Checking-URLs-quiz" element={<CheckingURLsQuiz />} />
              <Route path="/beginnerscourse/page6/social-media-safety" element={<SocialMediaSafety />} />
              <Route path="/beginnerscourse/page6/social-media-safety-part-two" element={<SocialMediaSafetyPartTwo />} />
              <Route path="/beginnerscourse/page6/social-media-drop-and-drag-game" element={<SocialMediaDropDragGame />} />
              <Route path="/beginnerscourse/page6/social-media-safety-quiz" element={<SocialMediaSafetyQuiz />} />
              <Route path="/beginnerscourse/page7/email-security" element={<EmailSecurity />} />
              <Route path="/beginnerscourse/page7/email-security-two" element={<EmailSecurityTwo />} />
              <Route path="/beginnerscourse/page7/email-security-three" element={<EmailSecurityThree />} />
              <Route path="/beginnerscourse/page7/email-security-four" element={<EmailSecurityFour />} />
              <Route path="/beginnerscourse/page7/email-security-quiz" element={<EmailSecurityQuiz />} />
              <Route path="/beginnerscourse/page8/finishers-page" element={<FinisherPage />} />

              <Route path="/beginnerscourseparttwo/introduction/introduction-part-two" element={<UserRoute><IntroductionPartTwo /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module2/what-is-cyber-security" element={<UserRoute><WhatIsCyberSecurity /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module2/what-is-cyber-security-quiz" element={<UserRoute><WhatIsCyberSecurityQuiz /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module2/importance-cyber" element={<UserRoute><ImportanceCyber /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module2/importance-cyber-quiz" element={<UserRoute><ImportanceCyberQuiz /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module3/cyber-threats" element={<UserRoute><CyberThreats /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module3/malware" element={<UserRoute><Malware /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module3/remove-malware-guide" element={<UserRoute><RemoveMalwareGuide /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module3/malware-quiz" element={<UserRoute><MalwareQuiz /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module3/phishing" element={<UserRoute><Phishing /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module3/phishing-quiz" element={<UserRoute><PhishingQuiz /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module3/ransomware" element={<UserRoute><Ransomware /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module3/remove-malware-guide3" element={<UserRoute><RemoveMalwareGuide3 /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module3/ransomware-quiz" element={<UserRoute><RansomwareQuiz /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module4/mobile-security" element={<UserRoute><MobileSecurity /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module4/poster" element={<UserRoute><Poster /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module4/mobile-security-quiz" element={<UserRoute><MobileSecurityQuiz /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module5/home-network" element={<UserRoute><HomeNetwork /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module5/home-network-quiz" element={<UserRoute><HomeNetworkQuiz /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module6/public-wifi" element={<UserRoute><PublicWifi /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module6/public-wifi-part-two" element={<UserRoute><PublicWifiPartTwo /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module6/public-wifi-quiz" element={<UserRoute><PublicWifiQuiz /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module7/data-backup" element={<UserRoute><DataBackup /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module7/remove-malware-guide2" element={<UserRoute><RemoveMalwareGuide2 /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module7/data-backup-part-two" element={<UserRoute><DataBackupPartTwo /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module7/data-backup-quiz" element={<UserRoute><DataBackupQuiz /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module8/credit-profile" element={<UserRoute><CreditProfile /></UserRoute>} />
<Route path="/beginnerscourseparttwo/module8/credit-report-quiz" element={<UserRoute><CreditReportQuiz /></UserRoute>} />
<Route path="/beginnerscourseparttwo/finishing-page-two" element={<UserRoute><FinishingPageTwo /></UserRoute>} />

<Route path="/businessacademy/moduleone/intro" element={<UserRoute><Intro /></UserRoute>} />
<Route path="/businessacademy/moduleone/what-is-cybersecurity-two" element={<UserRoute><WhatIsCyberSecurityTwo /></UserRoute>} />
<Route path="/businessacademy/moduleone/intro-quiz" element={<UserRoute><IntroQuiz /></UserRoute>} />
<Route path="/businessacademy/moduleone/statistics" element={<UserRoute><Statistics /></UserRoute>} />
<Route path="/businessacademy/moduleone/employee-role" element={<UserRoute><EmployeeRole /></UserRoute>} />
<Route path="/businessacademy/moduleone/employee-role-quiz" element={<UserRoute><EmployeeRoleQuiz /></UserRoute>} />
<Route path="/businessacademy/moduletwo/recognizing-cyber-threats" element={<UserRoute><RecognizingCyberThreats /></UserRoute>} />
<Route path="/businessacademy/moduletwo/phishing-tasks" element={<UserRoute><PhishingTasks /></UserRoute>} />
<Route path="/businessacademy/moduletwo/phishing-tasks-two" element={<UserRoute><PhishingTaskTwo /></UserRoute>} />
<Route path="/businessacademy/moduletwo/recognizing-quiz" element={<UserRoute><RecognizingQuiz /></UserRoute>} />
<Route path="/businessacademy/moduletwo/spear-phishing-task" element={<UserRoute><SpearPhishingTask /></UserRoute>} />
<Route path="/businessacademy/moduletwo/spear-phishing-task-two" element={<UserRoute><SpearPhishingTask2 /></UserRoute>} />
<Route path="/businessacademy/moduletwo/smishing" element={<UserRoute><Smishing /></UserRoute>} />
<Route path="/businessacademy/moduletwo/vishing" element={<UserRoute><Vishing /></UserRoute>} />
<Route path="/businessacademy/moduletwo/vishing-quiz" element={<UserRoute><VishingQuiz /></UserRoute>} />
<Route path="/businessacademy/moduletwo/social-engineering" element={<UserRoute><SocialEngineering /></UserRoute>} />
<Route path="/businessacademy/moduletwo/social-engineering-quiz" element={<UserRoute><SocialEngineeringQuiz /></UserRoute>} />
<Route path="/businessacademy/moduletwo/insider-threats" element={<UserRoute><InsiderThreats /></UserRoute>} />
<Route path="/businessacademy/moduletwo/insider-threats-quiz" element={<UserRoute><InsiderThreatsQuiz /></UserRoute>} />
<Route path="/businessacademy/modulethree/securing-work-devices" element={<UserRoute><SecuringWorkDevices /></UserRoute>} />
<Route path="/businessacademy/modulethree/securing-work-devices-quiz" element={<UserRoute><SecuringWorkDevicesQuiz /></UserRoute>} />
<Route path="/businessacademy/modulefour/incidents" element={<UserRoute><Incidents /></UserRoute>} />
<Route path="/businessacademy/modulefour/incidents-quiz" element={<UserRoute><IncidentsQuiz /></UserRoute>} />
<Route path="/businessacademy/modulefour/reporting-procedures" element={<UserRoute><ReportingProcedures /></UserRoute>} />
<Route path="/businessacademy/modulefour/reporting-procedures-quiz" element={<UserRoute><ReportingProceduresQuiz /></UserRoute>} />
<Route path="/businessacademy/modulefive/remote-work-security" element={<UserRoute><RemoteWorkSecurity /></UserRoute>} />
<Route path="/businessacademy/modulefive/remote-work-security-quiz" element={<UserRoute><RemoteWorkQuiz /></UserRoute>} />
<Route path="/businessacademy/modulefive/collaboration-tools" element={<UserRoute><CollaborationTools /></UserRoute>} />
<Route path="/businessacademy/modulefive/collaboration-tools-quiz" element={<UserRoute><CollaborationToolsQuiz /></UserRoute>} />
<Route path="/businessacademy/modulesix/data-protection" element={<UserRoute><DataProtection /></UserRoute>} />
<Route path="/businessacademy/modulesix/data-protection-quiz" element={<UserRoute><DataProtectionQuiz /></UserRoute>} />
<Route path="/businessacademy/modulesix/privacy" element={<UserRoute><Privacy /></UserRoute>} />
<Route path="/businessacademy/modulesix/privacy-quiz" element={<UserRoute><PrivacyQuiz /></UserRoute>} />
<Route path="/businessacademy/modulesix/compliance" element={<UserRoute><Compliance /></UserRoute>} />
<Route path="/businessacademy/modulesix/compliance-quiz" element={<UserRoute><ComplianceQuiz /></UserRoute>} />
<Route path="/businessacademy/modulesix/finishing-page" element={<UserRoute><FinishingPageThree /></UserRoute>} />

            
             
             
          </Routes>
        </div>
      </div>
      {showFooter2 && <Footer2 />}
    </div>
    </>
  );
}

export default App;
