import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence, browserSessionPersistence, reload, signOut } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Import updateDoc
import { firestore } from '../firebase';
import Cookies from 'js-cookie';
import CookieConsent from '../components/Cookies/CookieConsent';
import DOMPurify from 'dompurify';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    const initializeAuth = async () => {
      try {
        await setPersistence(auth, browserSessionPersistence);
        console.log('🔄 Persistence set to browserLocalPersistence.');

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          setLoading(true);
          try {
            if (user) {
              console.log("✅ User detected:", user);
        
              // 🔄 Force a Firebase refresh to make sure we get the latest email verification status
              await reload(user);
              console.log("🔄 Reloaded user:", user.emailVerified);
        
              // 📌 Step 1: Fetch Firestore data
              const userDocRef = doc(firestore, "users", user.uid);
              let userData = (await getDoc(userDocRef)).data();
        
              if (!userData) {
                console.warn(`⚠️ No Firestore user data found for UID: ${user.uid}`);
                setCurrentUser(user);
                setProfile(null);
                return;
              }
        
              console.log("📄 Fetched Firestore data:", userData);
        
              // ✅ Debugging accountType
              console.log("🔥 Account Type from Firestore:", userData.accountType);
        
              // 📌 Step 2: If Firestore `isEmailVerified` is still false, update it
              if (user.emailVerified && !userData.isEmailVerified) {
                console.log("🚀 Updating Firestore: Setting isEmailVerified to true...");
                await updateDoc(userDocRef, { isEmailVerified: true });
        
                // 🔄 Fetch updated Firestore data
                userData = (await getDoc(userDocRef)).data();
                console.log("✅ Firestore successfully updated:", userData);
              }
        
              // 📌 Step 3: Set state with the latest Firestore data
              setIsEmailVerified(user.emailVerified);
              setCurrentUser(user);
              setProfile({
                name: DOMPurify.sanitize(user.displayName || "Anonymous"),
                accountType: userData.profile?.accountType || "FreeUser", // ✅ Fix: Access inside profile
                email: userData.email,
                ...userData.profile, // ✅ Spread profile data to avoid nesting issues
              });
              console.log("🔥 Fixed Account Type from Firestore:", userData.profile?.accountType);

        
            } else {
              console.log("❌ No user is currently logged in.");
              setCurrentUser(null);
              setProfile(null);
              setIsEmailVerified(false);
            }
          } catch (error) {
            console.error("❌ Error during user verification process:", error);
          } finally {
            setLoading(false);
          }
        });
        

        return unsubscribe;
      } catch (error) {
        console.error('❌ Error setting persistence mode:', error);
        setLoading(false);
      }
    };
    console.log("🛠 Final Profile State:", profile);

    const unsubscribe = initializeAuth();

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, profile, isEmailVerified }}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {children}
          {isCookieBannerVisible && (
            <CookieConsent setIsCookieBannerVisible={setIsCookieBannerVisible} />
          )}
        </>
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
