import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import '../ModuleOne/WhatIsCyberSecurity.css';

const InsiderThreatsQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(10).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(10).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(10).fill(null));
  const [showStar, setShowStar] = useState(false);
  const auth = getAuth();

  const questions = [
    {
      question: "What is an insider threat?",
      options: [
        "A threat from outside the organisation",
        "A threat from someone with inside knowledge",
        "A threat from a competing organisation",
        "A threat from outdated software systems",
      ],
      correctAnswer: "A threat from someone with inside knowledge",
    },
    {
      question: "Which of the following is a type of insider threat?",
      options: [
        "Malicious Insiders",
        "Phishing Attacks",
        "Man-in-the-middle Attacks",
        "Denial of Service",
      ],
      correctAnswer: "Malicious Insiders",
    },
    {
      question: "What are negligent insiders?",
      options: [
        "Employees who intentionally harm the organisation",
        "Employees who unintentionally cause harm through careless actions",
        "Employees who are unaware of security policies",
        "Employees who report suspicious activities",
      ],
      correctAnswer:
        "Employees who unintentionally cause harm through careless actions",
    },
    {
      question: "What is a sign of a potential insider threat?",
      options: [
        "Excessive downloading or copying of data",
        "Following company protocols strictly",
        "Attending all company meetings",
        "Requesting more training",
      ],
      correctAnswer: "Excessive downloading or copying of data",
    },
    {
      question: "How can compromised insiders be described?",
      options: [
        "Employees whose credentials were stolen and used by attackers",
        "Employees who intentionally cause harm to the organisation",
        "Employees who have recently quit the company",
        "Employees who strictly follow all security protocols",
      ],
      correctAnswer:
        "Employees whose credentials were stolen and used by attackers",
    },
    {
      question: "Which of the following is NOT a way to prevent insider threats?",
      options: [
        "Implementing strict access controls",
        "Conducting regular security awareness training",
        "Ignoring unusual behaviour",
        "Monitoring network activity for unusual behaviour",
      ],
      correctAnswer: "Ignoring unusual behaviour",
    },
    {
      question: "What should you do if you notice unusual or unauthorised access to sensitive data?",
      options: [
        "Report it immediately to the security team",
        "Ignore it",
        "Discuss it with a colleague",
        "Wait to see if it happens again",
      ],
      correctAnswer: "Report it immediately to the security team",
    },
    {
      question: "Why is the principle of least privilege important in preventing insider threats?",
      options: [
        "It limits access to only what's necessary for job duties",
        "It grants everyone unrestricted access to all data",
        "It simplifies the process of sharing information freely",
        "It eliminates the need for extensive security training",
      ],
      correctAnswer: "It limits access to only what's necessary for job duties",
    },
    {
      question: "What role does regular security awareness training play in preventing insider threats?",
      options: [
        "It educates employees about risks and signs of insider threats",
        "It makes employees less likely to follow security rules",
        "It encourages employees to share passwords safely with others",
        "It reduces the overall need for doing background checks",
      ],
      correctAnswer:
        "It educates employees about risks and signs of insider threats",
    },
    {
      question: "What is a compromised insider?",
      options: [
        "An employee whose credentials were stolen and used by attackers",
        "An employee who frequently violates company policies",
        "An employee who decides to quit without giving notice",
        "An employee who consistently follows all security protocols",
      ],
      correctAnswer:
        "An employee whose credentials were stolen and used by attackers",
    },
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect =
        answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] =
          shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 8) {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, "users", user.uid);
          await updateDoc(userDocRef, {
            "progress.course3.module2_quiz4": true,
          });
          setShowStar(true);
        }
      } catch (error) {
        console.error("Error updating user progress:", error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(10).fill(null));
    setFeedback(Array(10).fill(null));
    setCorrectAnswers(Array(10).fill(null));
    setShowResults(false);
    setScore(0);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate(
      "/businessacademy/modulethree/securing-work-devices#SecuringWorkDevicesHeading"
    );
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star" role="alert" aria-live="polite"></div>}
      <section
        className="module-section2"
        role="region"
        aria-labelledby="quiz-title"
      >
        <h2 id="quiz-title" tabIndex="0">
          Recognising Insider Threats Quiz
        </h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={
                  feedback[currentQuestionIndex] ? "feedback" : undefined
                }
              >
                <legend
                  id={`question-${currentQuestionIndex}`}
                  tabIndex="0"
                  aria-live="polite"
                  aria-label={`Question ${
                    currentQuestionIndex + 1
                  }: ${shuffledQuestions[currentQuestionIndex]?.question}`}
                >
                  {shuffledQuestions[currentQuestionIndex]?.question}
                </legend>
                {shuffledQuestions[currentQuestionIndex]?.options.map(
                  (option, index) => (
                    <div key={index} className="quiz-option">
                      <label
                        className="quiz-option-label"
                        htmlFor={`option-${currentQuestionIndex}-${index}`}
                      >
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex}-${index}`}
                          name={`question-${currentQuestionIndex}`}
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={() => handleAnswerChange(option)}
                          disabled={feedback[currentQuestionIndex] !== null}
                          aria-checked={
                            answers[currentQuestionIndex] === option
                          }
                        />
                        <span className="option-text">{option}</span>
                      </label>
                    </div>
                  )
                )}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  role="alert"
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === "correct"
                    ? "Correct!"
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Next Question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit Quiz"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="alert" aria-live="polite">
              <p tabIndex="0">Your score: {score} / 10</p>
              {score >= 8 ? (
                <p tabIndex="0">
                  Congratulations! You passed the quiz and have a solid understanding of recognising insider threats.
                </p>
              ) : (
                <p tabIndex="0">
                  You need to score at least 8 to pass. Please retake the quiz.
                </p>
              )}
              <div className="quiz-buttons">
                {score >= 8 ? (
                  <button
                    className="next-button"
                    onClick={goToNext}
                    aria-label="Next Lesson"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="retake-button"
                    onClick={handleRetake}
                    aria-label="Retake Quiz"
                  >
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default InsiderThreatsQuiz;
