import React, { useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './CoursesTwo.css'; 
import coursetwo from '../assets/coursetwopic.webp'; 
import courseone from '../assets/courseoneimage.webp'; 
import businesspicture from '../assets/businesspicture.webp'; 
import Footer from './Footer/Footer';

const CoursesTwo = () => {
  const videoRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleTimeUpdate = () => {
      if (videoElement.duration - videoElement.currentTime <= 1) {
        videoElement.pause();
      }
    };

    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, []);

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100); // Delay to ensure elements have rendered
      }
    }
  }, [location]); // Triggered when the route (or hash) changes
  

  return (
    <>
      {/* First Section */}
      <section className="section-courses">
        <div className="content-wrapper-courses">
          <h1 className="section-title-courses">Explore Our Cyber Shield Courses</h1>
          <div className="section-subtitle-container">
            <p className="section-subtitle-courses">
              Explore our expertly designed courses, crafted by industry professionals to equip you 
              with essential cybersecurity knowledge. Cyber Shield provides a foundational understanding 
              of cybersecurity principles, while subsequent courses expand and deepen your expertise.
            </p>
          </div>
        </div>
      </section>

      {/* Course 1 */}
      <section id="section-2-courses" className="section-2-courses">
        <div className="content-wrapper-course-one">
          <div className="image-container-course-one">
            <img src={courseone} alt="CyberShield Basics Course Preview" className="tutorial-image-course-one" />
          </div>
          <div className="content-container-course-one">
            <h2 className="section-title-course-one">CyberShield Basics</h2>
            <p className="section-subtitle-course-one">
              This course offers <strong>foundational</strong> training for anyone looking to enhance 
              their cybersecurity skills. Step into the role of a cyber detective and safeguard 
              the town of Brambleton!
            </p>

            <div className="course-summary-container">
  {[
    'Essential cybersecurity concepts',
    'Recognise threats and identify malware',
    'Create strong passwords for better security',
    'Ensure social media and email safety',
    'Learn about two-factor authentication (2FA)',
    'Practice safe browsing techniques',
    'Understand how to verify links for safety'
  ].map((item, index) => (
    <p key={index} className="course-summary-item" tabIndex="0" aria-label={item}>
      <span className="checkmark">✔</span>
      <strong>{item}</strong>
    </p>
  ))}
</div>
            <Link to="/beginnerscourse/page1/introduction#BasicsTitle" aria-label="Begin CyberShield Basics Course">
              <button className="begin-button-course-one"><strong>Begin Course</strong></button>
            </Link>
          </div>
        </div>
      </section>

      {/* Course 2 */}
      <section id="section-3-courses" className="section-3-courses">
        <div className="content-wrapper-course-two">
          <div className="content-container-course-two">
            <h2 className="section-title-course-two">CyberShield Intermediate</h2>
            <p className="section-subtitle-course-two">
              This course builds upon the previous material, focusing on defending Brambleton from 
              the hacking group 'Mayhem'.
            </p>

            <div className="course-summary-container2">
  {[
    'Understand the role of cybersecurity',
    'Learn about Malware, Phishing, and Ransomware',
    'Secure mobile devices and home networks',
    'Practice safe use of Public Wi-Fi and data backup',
    'Protect your credit profile from cyber threats',
    'Learn how to secure your home network',
    'Understand the importance of backing up your data'
  ].map((item, index) => (
    <p key={index} className="course-summary-item" tabIndex="0" aria-label={item}>
      <span className="checkmark">✅</span>
      <strong>{item}</strong>
    </p>
  ))}
</div>

            <Link to="/beginnerscourseparttwo/introduction/introduction-part-two#CybersecurityDetective" aria-label="Begin CyberShield Intermediate Course">
              <button className="begin-button-course-two"><strong>Begin Course</strong></button>
            </Link>
          </div>
          <div className="image-container-course-two">
            <img src={coursetwo} alt="CyberShield Intermediate Course Preview" className="tutorial-image-course-two" />
          </div>
        </div>
      </section>

      {/* Course 3 */}
      <section id="section-4-courses" className="section-4-courses">
        <div className="content-wrapper-course-three">
          <div className="image-container-course-three">
            <img src={businesspicture} alt="CyberShield Business Academy Course Preview" className="tutorial-image-course-three" />
          </div>
          <div className="content-container-course-three">
            <h2 className="section-title-course-three">CyberShield: Business Academy</h2>
            <p className="section-subtitle-course-three">
              CyberShield Business Academy provides <strong>cybersecurity training tailored for employees</strong>. 
              This course enhances workplace security awareness and teaches best practices to protect digital environments.
            </p>

            <div className="course-summary-container">
  {[
    'Understand the importance of cybersecurity in the workplace',
    'Learn to identify and defend against cyber threats such as phishing, smishing, and vishing',
    'Develop skills to secure work devices and data',
    'Apply best practices for remote work and collaboration tools',
    'Foster a security-first mindset to protect sensitive information'
  ].map((item, index) => (
    <p key={index} className="course-summary-item" tabIndex="0" aria-label={item}>
      <span className="checkmark">✔</span>
      <strong>{item}</strong>
    </p>
  ))}
</div>

            <Link to="/businessacademy/moduleone/intro#BusinessAcademyHeading" aria-label="Begin CyberShield Business Academy Course">
              <button className="begin-button-course-three"><strong>Begin Course</strong></button>
            </Link>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default CoursesTwo;
