import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const DataProtection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dataProtectionRef = useRef(null);
  const videoRef = useRef(null);
  const warningRef = useRef(null);

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
      setTimeout(() => {
        warningRef.current?.focus();
      }, 100);
    } else {
      goToQuiz();
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/modulesix/data-protection-quiz');
  };

  const goBack = () => {
    navigate('/businessacademy/modulefive/collaboration-tools#CollaborationToolsHeading');
  };

  useEffect(() => {
    if (location.hash === '#DataProtectionHeading' && dataProtectionRef.current) {
      dataProtectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the Collaboration Tools module"
      >
        ←
      </button>

      <div className="cybersecurity-basics-header">
        <h2 ref={dataProtectionRef}>Data Protection</h2>
      </div>

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="/assets/videos/DataProtectionModuleSix.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Data Protection Awareness Video"
          >
            <track kind="captions" src="/assets/videos/DataProtectionModuleSix.vtt" label="English captions" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="module-content">
        <div className="highlighted-section">
          <h3>Understanding Data Protection Regulations</h3>
          
            <p>Overview of GDPR:
            <br />
            <br />GDPR (General Data Protection Regulation) is a key regulation that governs how organisations must handle personal data. It applies to all organisations operating within the EU and the UK GDPR post-Brexit.</p>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Key Principles</h3> 
          
            <ul>
              
                <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Lawfulness, Fairness, and Transparency:</strong> Data must be processed legally and transparently.</li>
              
              <br />
              
                <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Purpose Limitation:</strong> Data should only be collected for specific, legitimate purposes.</li>
              
              <br />
              
                <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Data Minimisation:</strong> Only the necessary amount of data should be collected.</li>
              
              <br />
              
                <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Accuracy:</strong> Data must be accurate and kept up to date.</li>
              
              <br />
              
                <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Storage Limitation:</strong> Data should only be retained as long as necessary.</li>
              
              <br />
              
                <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Integrity and Confidentiality:</strong> Data must be protected from unauthorised access and breaches.</li>
              
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Business Implications</h3> 
          
            <ul>
              
                <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Consent:</strong> Obtain explicit consent before processing personal data.</li>
              
              <br />
            
                <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Rights of Data Subjects:</strong> Ensure that individuals can access, correct, and delete their data.</li>
              
              <br />
              
                <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Breach Notification:</strong> Report data breaches to the Information Commissioner's Office (ICO) within 72 hours if required.</li>
              
              <br />
              
                <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Penalties for Non-Compliance:</strong> Non-compliance can result in significant fines.</li>
              
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Handling and Protecting Business Data</h3> 
          <br></br>
          
            <ul>
              
                <li><strong style={{ color: 'white' }}>Secure Storage:</strong></li>
              
              <br />
              <ul>
                
                  <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Encryption:</strong> Encrypt sensitive data both at rest and in transit.</li>
                
                <br />
                
                  <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Access Control:</strong> Implement strict access controls to limit data access to authorised personnel.</li>
                
                <br />
                
                  <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Physical Security:</strong> Secure physical storage of sensitive documents and backup drives.</li>
                
              </ul>
              <br />
              
                <li><strong style={{ color: 'white' }}>Secure Sharing:</strong></li>
              
              <br />
              <ul>
                
                  <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Email Encryption:</strong> Use encrypted email services for sending sensitive information.</li>
                
                <br />
                
                  <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Secure File Transfer:</strong> Utilise secure file transfer protocols (SFTP) and encrypted cloud services.</li>
                
              </ul>
              <br />
              
                <li><strong style={{ color: 'white' }}>Data Disposal:</strong></li>
              
              <br />
              <ul>
                
                  <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Data Destruction:</strong> Securely destroy data that is no longer needed.</li>
                
                <br />
                
                  <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Retention Policies:</strong> Follow data retention policies for timely and secure disposal of data.</li>
                
              </ul>
            </ul>
          
        </div>
      </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button"
            onClick={handleNextClick}
            aria-label="Start Data Protection Quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" ref={warningRef} tabIndex="-1" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default DataProtection;
