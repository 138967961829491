import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../Page3/RecognizingThreatsPartTwo.css';

const SocialMediaSafety = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const socialMediaSafetyRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourse/page6/social-media-safety-part-two#Task2');
    }
  };

  useEffect(() => {
    if (location.hash === '#SocialMediaSafety' && socialMediaSafetyRef.current) {
      socialMediaSafetyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page5.5/checking-urls#VerifyLinks');
  };

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Checking URLs"
      >
        ←
      </button>

      <section className="module-section">
        <section className="module-section" style={{ textAlign: 'center' }}>
          <h2 id="SocialMediaSafety" ref={socialMediaSafetyRef} className="BasicsTitle">
            Social Media Safety
          </h2>
        </section>

        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="/assets/videos/PageSix.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            aria-label="Educational video on social media safety"
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <section className="module-section" style={{ textAlign: 'center' }}>
          <h3>Mission: Adjusting Privacy Settings</h3>
        </section>

        <div className="bordered-container">
          <div className="grid-item text">
            <div className="task">
             
              <br />
              <p>
                <strong >Objective:</strong> Check your social media privacy settings to see if your accounts are private or public. It's important to make your accounts private to protect your personal information from strangers and potential cyber threats. By restricting who can see your posts and details, you reduce the risk of identity theft, online harassment, and unauthorized access to your data. This ensures that only trusted individuals can view your content, helping to maintain your safety and privacy online. If you choose to keep it public, think twice before you post.
              </p>
              <p>
                <strong >Action:</strong> Check out and become familiar with the privacy settings on your social media accounts. There are useful features such as setting your profile to be visible only to friends and restrict public access. You also can adjust the settings to hide your phone number and email address and other personal data from public view.
              </p>
              <p>
                <strong >Outcome:</strong> Your social media accounts are more secure, with limited public access to your personal information.
              </p>
            </div>
          </div>
        </div>

        <div className="button-container">
          <button
            className="next-button"
            onClick={goToNext}
            aria-label="Proceed to Social Media Safety Part Two"
          >
            Next
          </button>
          {showWarning && (
            <p className="warning-text22" role="alert">
              You must watch the video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default SocialMediaSafety;
