import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import './WhatIsCyberSecurity.css';

const WhatIsCyberSecurity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const backArrowRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleQuizClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module2/what-is-cyber-security-quiz');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/introduction/introduction-part-two#CybersecurityDetective');
  };

  // Scroll to the back arrow button if the hash is present
  useEffect(() => {
    if (location.hash === '#BackArrow' && backArrowRef.current) {
      backArrowRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <main className="cybersecurity-basics-container">
      <header className="cybersecurity-basics-header">
        <button
          className="back-arrow-button"
          onClick={goBack}
          ref={backArrowRef}
          aria-label="Go back to the previous section"
        >
          ←
        </button>
        <h2>What is Cybersecurity</h2>
      </header>

      <section className="module-section">
        <section className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="/assets/videos/ModuleTwo.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Introduction video explaining what cybersecurity is"
          >
            Your browser does not support the video tag.
          </video>
          
        </section>

        <section className="module-content">
          <div className="highlighted-section">
            <h3>What is Cybersecurity?</h3>
            <p>
              Cybersecurity is the practice of protecting systems, networks, and programs from digital attacks.
              It encompasses a range of technologies, processes, and practices designed to safeguard computers,
              servers, mobile devices, electronic systems, networks, and data from malicious attacks, damage,
              or unauthorized access.
            </p>
            <br />
            <p>
              Cybersecurity involves various layers of protection spread across computers, networks, programs, or data
              that one intends to keep safe. For an organisation, the people, processes, and technology must all
              complement one another to create an effective defense from cyber attacks.
            </p>
            <br />
          </div>
        </section>

        <section className="start-quiz-button-container">
          <button
            className="start-quiz-button"
            onClick={handleQuizClick}
            aria-label="Start the quiz"
            disabled={!isVideoWatched}
            tabIndex={!isVideoWatched ? -1 : 0}
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </section>
      </section>
    </main>
  );
};

export default WhatIsCyberSecurity;
