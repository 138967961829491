import React, { useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './AboutPage.css'; // Updated CSS file
import AboutPageImage from '../assets/AboutPageImage.webp'; // Import the image
import Footer from './Footer/Footer';

const AboutPage = () => {
  const videoRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const videoElement = videoRef.current;

    // Event listener to stop the video 2 seconds before it ends
    const handleTimeUpdate = () => {
      if (videoElement.duration - videoElement.currentTime <= 1) {
        videoElement.pause();
      }
    };

    if (videoElement) {
      // Add event listener for time update
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
    }

    // Cleanup: remove event listener when component is unmounted
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, []);

  // Effect to handle scroll to section based on URL hash
  useEffect(() => {
    if (location.hash === '#AboutHeading') {
      const element = document.querySelector('.section-title222-about');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  return (
    <>
     

      <div className="main-container-content-about">
        {/* First Section */}
        <section className="section-1-about">
          <div className="content-container-about1">
            <h1 className="section-title222-about">Be Cyber Aware</h1>
            <p className="section-subtitle-about">
              Welcome to 'Be Cyber Aware', your go-to resource for learning cybersecurity. Whether you're a beginner or have some experience, our courses are designed to help you understand and protect yourself against cyber threats.
            </p>
          </div>

          <svg className="svg-section-1-about" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 150">
            <path fill="#ffeadf" fillOpacity="1" d="M0,130L60,125C120,120,240,110,360,105C480,100,600,100,720,105C840,110,960,120,1080,125C1200,130,1320,125,1380,122L1440,120L1440,150L1380,150C1320,150,1200,150,1080,150C960,150,840,150,720,150C600,150,480,150,360,150C240,150,120,150,60,150L0,150Z"></path>
          </svg>
        </section>

        {/* Second Section: Courses */}
        <section className="section section-2-about">
          <div className="content-wrapper-about">
          <div className="content-container-about">
              <h2 className="section-title222-about2">Our Mission</h2>
              <p className="section-subtitle-about2">
                Our mission is to empower users with the knowledge and skills needed to navigate the digital world safely. We believe that everyone should have access to quality cybersecurity education, regardless of their background or experience level.
              </p>
             <div className="course-boxes-about">
                  <div style={{ display: 'flex', alignItems: 'left', gap: '10px', marginBottom: '15px', fontSize: '30px' }}>
                       <span>🟢</span>
                       <p style={{ color: '#005f73', fontWeight: 'bold' }}>No Experience Required</p>
                  </div>
               <div style={{ display: 'flex', alignItems: 'left', gap: '10px', marginBottom: '15px', fontSize: '30px' }}>
                       <span>🟢</span>
                       <p style={{ color: '#005f73', fontWeight: 'bold' }}>Learn to Protect Yourself</p>
               </div>
               <div style={{ display: 'flex', alignItems: 'left', gap: '10px', marginBottom: '15px', fontSize: '30px' }}>
                       <span>🟢</span>
                       <p style={{ color: '#005f73', fontWeight: 'bold' }}>Learn to Protect Your Organisation</p>
               </div>
             </div>
           </div>

            {/* Add the image next to the content */}
            <div className="image-container-about">
              <img src={AboutPageImage} alt="Tutorial" className="tutorial-image-course-about" />
            </div>
          </div>
        </section>

        {/* Third Section: Manager's Account */}
        <section className="section section-3-about">
          <div className="manager-content-container-about">
            <h2 className="section-title-manager-about" style={{ color: 'white' }}>SafeBytes Learning Path: Building Strong Cybersecurity Foundations</h2>
            



            <div className="manager-features-about">
              {/* First Feature */}
              <div className="feature-about">
              <h3 id="cybersecurity-foundations-heading" style={{ color: 'black' }}>
                   Building Strong Cybersecurity Foundations
              </h3>
              <ul aria-labelledby="cybersecurity-foundations-heading" style={{ color: '#333' }}> {/* Improved contrast */}
                  <li>
                    <strong style={{ color: 'black' }}>Course Learning Path</strong>
                    <br /><br />
                    <span style={{ color: 'black' }}>
                      Carefully structured with regular quizzes to enhance your cybersecurity skills step by step.
                    </span>
                  </li>
                  <br />
                  <li>
                    <strong style={{ color: 'black' }}>Goal</strong>
                    <br /><br />
                    <span style={{ color: 'black' }}>
                      Provides a solid foundation for understanding online protection.
                    </span>
                  </li>
                  <br />
                  <li>
                    <strong style={{ color: 'black' }}>Importance</strong>
                    <br /><br />
                    <span style={{ color: 'black' }}>
                      Teaches you first to protect yourself, then the organisation you work for.
                    </span>
                  </li>
                </ul>
              </div>

              {/* Second Feature */}
              <div className="feature-about">
              <h3 id="personal-cybersecurity-heading" style={{ color: 'black' }}>
                  Personal Cybersecurity Essentials
              </h3>
                <br></br>
                <ul aria-labelledby="personal-cybersecurity-heading" style={{ color: '#333' }}> {/* Improved contrast */}
                  <li>
                    <strong style={{ color: 'black' }}>Cyber Shield Course Focus</strong>
                    <br /><br />
                    <span style={{ color: 'black' }}>
                      The course begins by focusing on personal cybersecurity.
                    </span>
                  </li>
                  <br />
                  <li>
                    <strong style={{ color: 'black' }}>Common Threats</strong>
                    <br /><br />
                    <span style={{ color: 'black' }}>
                      You'll learn about common threats you may face and effective strategies to defend yourself.
                    </span>
                  </li>
                  <br />
                  <li>
                    <strong style={{ color: 'black' }}>Goal</strong>
                    <br /><br />
                    <span style={{ color: 'black' }}>
                      Understanding these potential threats is the first step toward becoming confident in maintaining your digital safety and minimising risks.
                    </span>
                  </li>
                </ul>
              </div>

              {/* Third Feature */}
              <div className="feature-about">
              <h3 id="professional-security-heading" style={{ color: 'black' }}>
                  Expanding to Professional Security
              </h3>
              <ul aria-labelledby="professional-security-heading" style={{ color: '#333' }}> {/* Improved contrast */}
                  <li>
                    <strong style={{ color: 'black' }}>Course Progression</strong>
                    <br /><br />
                    <span style={{ color: 'black' }}>
                      As you progress through the course, the modules expand to include professional cybersecurity practices.
                    </span>
                  </li>
                  <br />
                  <li>
                    <strong style={{ color: 'black' }}>Application of Skills</strong>
                    <br /><br />
                    <span style={{ color: 'black'  }}>
                      You'll learn how to apply the skills you developed to safeguard not just your personal information, but also the data of the organisation you work for.
                    </span>
                  </li>
                  <br />
                  <li>
                    <strong style={{ color: 'black' }}>Goal</strong>
                    <br /><br />
                    <span style={{ color: 'black'  }}>
                      This focus ensures you are well-equipped to maintain security in a business environment.
                    </span>
                  </li>
                </ul>
              </div>

              {/* Fourth Feature */}
              <div className="feature-about">
              <h3 id="digital-landscape-heading" style={{ color: 'black' }}>
                 Prepared for the Digital Landscape
              </h3>
                <br></br>
                <ul aria-labelledby="digital-landscape-heading" style={{ color: '#333' }}> {/* Improved color for readability */}
                  <li>
                    <strong style={{ color: 'black' }}>Course Outcome</strong>
                    <br /><br />
                    <span style={{ color: 'black'  }}>
                      By the end of the course, you will be equipped to confidently navigate the digital landscape.
                    </span>
                  </li>
                  <br />
                  <li>
                    <strong style={{ color: 'black' }}>Knowledge and Tools</strong>
                    <br /><br />
                    <span style={{ color: 'black'  }}>
                      With the knowledge and tools gained, you will be capable of protecting both yourself and your workplace from evolving cyber threats.
                    </span>
                  </li>
                  <br />
                  <li>
                    <strong style={{ color: 'black' }}>Goal</strong>
                    <br /><br />
                    <span style={{ color: 'black'  }}>
                      Ensuring a higher level of security for your online activities.
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="ManagerSubtitles-about">
               <p style={{ color: 'white', marginBottom: '10px', fontSize: '30px' }}><strong>Secure Your Business</strong></p>
              <p style={{ color: 'white', marginBottom: '10px', fontSize: '30px' }}><strong>Meet GDPR Compliance</strong></p>
            </div>

            {/* Sign Up Button */}
            <div className="button-container-about">
              <Link to="/free-user-signup">
                <button className="ManagerAccountButtonHomePage-about"><strong>Create a Free Account Now!</strong></button>
              </Link>
            </div>
          </div>
        </section>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default AboutPage;
