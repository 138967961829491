import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../ModuleOne/WhatIsCyberSecurity.css'; // Updated path for the CSS file

const PhishingTaskTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const phishingRef = useRef(null); // Reference for the Phishing section heading
  const warningRef = useRef(null); // Reference for focus management
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const [showWarning, setShowWarning] = useState(false); // New state to track warning visibility

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'legitimate');
    setShowWarning(false); // Reset warning when an answer is selected
  };

  const goToQuiz = () => {
    if (isAnswered) {
      navigate('/businessacademy/moduletwo/spear-phishing-task#PhishingHeading'); // Navigate to the next section
    } else {
      setShowWarning(true); // Show warning when user tries to proceed without answering
      warningRef.current?.focus(); // Move focus to warning message for screen readers
    }
  };

  const goBack = () => {
    navigate('/businessacademy/moduletwo/phishing-tasks#PhishingHeading'); // Navigate to the previous section
  };

  // Scroll to the "Phishing" section if hash is present
  useEffect(() => {
    if (location.hash === '#PhishingHeading' && phishingRef.current) {
      phishingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container" role="main">
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to the previous page"
      >
        ←
      </button>

      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h2 ref={phishingRef} id="PhishingHeading" tabIndex="0">
                Phishing
              </h2>
            </div>
          </div>
        </div>

        <div className="module-section2">
          <section className="module-section" style={{ textAlign: 'center' }}>
          </section>

          <div className="module-content2">
            <div className="bordered-container2">
              <div className="task-instruction">
                <p><strong><span style={{ color: 'white' }}>Your task is to decide if the email is legitimate or illegitimate.</span></strong></p>
              </div>
            </div>
          </div>

          <div className="email-example-box">
            <h3>Example Email</h3>
            <div className="email-content">
              <p><strong>From:</strong> support@innovatetechsolutions.com</p>
              <p><strong>Subject:</strong> Scheduled Maintenance Notification</p>
              <p>Hi David,</p>
              <p>
                We wanted to inform you of a scheduled maintenance window for our systems. The maintenance will take place on Saturday, August 12th, from 2:00 AM to 4:00 AM. During this time, access to your Innovate Tech Solutions account may be temporarily unavailable.
              </p>
              <p>
                If you have any questions or require assistance, please contact our IT support team at support@innovatetechsolutions.com.
              </p>
              <p>Thank you for your understanding.</p>
              <p>Sincerely,</p>
              <p>Innovate Tech Solutions IT Support</p>
            </div>

            <div className="answer-buttons">
              <button 
                onClick={() => handleAnswer('legitimate')} 
                aria-label="Select if you think the email is legitimate"
              >
                Legitimate
              </button>
              <button 
                onClick={() => handleAnswer('illegitimate')} 
                aria-label="Select if you think the email is illegitimate"
              >
                Illegitimate
              </button>
            </div>

            {isAnswered && (
              <div 
                className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`} 
                role="alert" 
                aria-live="polite"
              >
                {isCorrect ? 'Well done! This is a legitimate email.' : 'Wrong answer. Try again.'}
              </div>
            )}
          </div>

          {isAnswered && (
            <div className="email-legitimate">
              <h4>Why is this email legitimate?</h4>
              <ul>
                <li><strong>Email Address:</strong> The sender's email address (support@innovatetechsolutions.com) is from a recognised domain. However, this alone should not be relied on as the email address may be spoofed. 
                  A spoofed email is a fraudulent email that appears to come from a legitimate source but is actually sent by an attacker.
                </li>
                <li><strong>Content:</strong> The email provides clear information about scheduled maintenance, which is a common and legitimate communication from IT departments.</li>
                <li><strong>Contact Information:</strong> The email provides a legitimate contact email for further questions or assistance.</li>
                <li><strong>No Urgent Threats:</strong> Unlike phishing emails, this email does not create a sense of urgency or threat.</li>
              </ul>
            </div>
          )}
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <h3>Different Types of Phishing</h3>
            <p>Phishing can take various forms, such as phishing emails, smishing (SMS phishing), and vishing (voice phishing). Signs of phishing include unsolicited messages, urgent requests for personal information, and suspicious links or attachments. You should not respond to the email and mark the sender as junk or spam and delete it.</p>
          </div>
        </div>

        <button 
          className="start-quiz-button" 
          onClick={goToQuiz} 
          
          aria-disabled={!isAnswered}
        >
          Next
        </button>

        {showWarning && (
          <p 
            className="warning-text" 
            ref={warningRef} 
            role="alert" 
            tabIndex="0"
          >
            You must answer the question before proceeding.
          </p>
        )}
      </section>
    </div>
  );
};

export default PhishingTaskTwo;
