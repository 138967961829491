import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './EmailSecurity.css';

const EmailSecurityThree = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const emailSecurityRef = useRef(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'legitimate');
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page7/email-security-four#EmailSecurity');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page7/email-security-two#EmailSecurity');
  };

  useEffect(() => {
    if (location.hash === '#EmailSecurity' && emailSecurityRef.current) {
      emailSecurityRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the previous Email Security page"
      >
        ←
      </button>

      <section className="module-section2">
        <section
          className="module-section"
          style={{ textAlign: 'center' }}
          ref={emailSecurityRef}
        >
          <h2 id="EmailSecurity">Email Security</h2>
        </section>

        <div className="module-content2">
          <div className="bordered-container2">
            <div className="task-instruction">
              <p>
                <strong>
                  <span style={{ color: 'white' }}>
                    Your task is to decide if each email is legitimate or illegitimate.
                  </span>
                </strong>
              </p>
            </div>
          </div>
        </div>

        <div className="email-example-box">
          <h3>Example Email Two</h3>
          <div className="email-content">
            <p>
              <strong>From:</strong> support@amazon.com
            </p>
            <p>
              <strong>Subject:</strong> Order Confirmation #123-4567890-1234567
            </p>
            <p>Dear John Davies,</p>
            <p>
              Thank you for your order. Your order #123-4567890-1234567 has been confirmed. You can{' '}
              <span
                className="tooltip-link"
                role="button"
                tabIndex={0}
                aria-describedby="tooltip-text"
              >
                <strong>
                  <span style={{ color: 'blue' }}>Click Here</span>
                </strong>
              </span>{' '}
              to view your order details.
            </p>
            <p>Thank you for shopping with us.</p>
            <p>Sincerely,</p>
            <p>Amazon Support Team</p>
          </div>

          <div className="answer-buttons">
            <button
              onClick={() => handleAnswer('legitimate')}
              aria-label="Select if the email is legitimate"
            >
              Legitimate
            </button>
            <button
              onClick={() => handleAnswer('illegitimate')}
              aria-label="Select if the email is illegitimate"
            >
              Illegitimate
            </button>
          </div>

          {isAnswered && (
            <div
              className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`}
              role="alert"
              aria-live="assertive"
            >
              {isCorrect ? 'Well done!' : 'Wrong answer.'}
            </div>
          )}
        </div>

        {isAnswered && (
          <div className="phishing-signs">
            <h4>Signs of a Legitimate Email:</h4>
            <ul>
              <li>
                <strong>Email Address:</strong> The sender's email address (support@amazon.com) is
                correctly spelled and matches the official domain.
              </li>
              <li>
                <strong>Personal Greeting:</strong> The email uses a personal greeting like "Dear
                John Davies" instead of a generic one.
              </li>
              <li>
                <strong>Link Destination:</strong> The link provided leads to the official Amazon
                website or order details page.
              </li>
              <li>
                <strong>Order Specific Information:</strong> The email contains specific details
                about your order, such as the order number.
              </li>
            </ul>
          </div>
        )}

        <button
          className="next-button"
          onClick={goToNext}
          aria-label="Proceed to the next Email Security page"
        >
          Next
        </button>
      </section>
    </div>
  );
};

export default EmailSecurityThree;
