import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../ModuleTwo/WhatIsCyberSecurity.css';

const Ransomware = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const ransomwareHeadingRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module3/ransomware-quiz');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/phishing#PhishingHeading');
  };

  useEffect(() => {
    if (location.hash === '#RansomwareHeading' && ransomwareHeadingRef.current) {
      ransomwareHeadingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the phishing section"
      >
        ←
      </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header" ref={ransomwareHeadingRef}>
              <h2>Ransomware</h2>
            </div>
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay />
              <video
                ref={videoRef}
                src="/assets/videos/Ransomware.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                aria-label="Ransomware awareness video"
              >
                Your browser does not support the video tag.
              </video>
            
            </div>
          </div>
        </div>

        <div className="module-content">
          <div className="bordered-content">
            <p>
              Follow the same steps as outlined in the{' '}
              <a
                href="/beginnerscourseparttwo/module3/remove-malware-guide3"
                className="link-text"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Read the guide on how to remove ransomware"
              >
                How to Get Rid of Malware Guide
              </a>
              , since ransomware is a type of malware. However, unlike typical malware, ransomware encrypts your data, making it inaccessible without a decryption key. But don't worry, not all is lost! Fortunately, the library has a robust backup strategy. They regularly back up their data to external hard drives that are kept offline and separate from the library network, as well as to the cloud. After successfully removing the ransomware, the data is restored to the library network.
            </p>
          </div>

          <div className="bordered-container alt-color">
  <h3 style={{ marginBottom: '15px' }}>How Ransomware Gets on Your Computer</h3>
  
  <p style={{ marginBottom: '15px' }}>
    Ransomware is a type of malicious software that locks your computer or encrypts your files, 
    demanding a ransom to get them back. Here are some common ways it can get onto your computer:
  </p>

  <ul style={{ paddingLeft: '20px', listStylePosition: 'inside' }}>
    <li style={{ marginBottom: '10px' }}>
      <strong>Phishing Emails:</strong> Cybercriminals send emails that look legitimate, 
      tricking you into clicking on a link or opening an attachment. This can install ransomware on your system.
    </li>
    <li style={{ marginBottom: '10px' }}>
      <strong>Malicious Websites:</strong> Visiting a compromised or fake website can 
      automatically download ransomware onto your computer.
    </li>
    <li style={{ marginBottom: '10px' }}>
      <strong>Infected Downloads:</strong> Downloading software, games, or other files 
      from untrusted sources can also bring ransomware onto your computer.
    </li>
    <li style={{ marginBottom: '10px' }}>
      <strong>Exploiting Security Holes:</strong> Outdated software and operating systems with 
      security vulnerabilities can be exploited by hackers to install ransomware.
    </li>
  </ul>

  <p style={{ marginTop: '15px' }}>
    To protect yourself, be cautious of unexpected emails and links, only download from 
    trusted sources, and keep your software up to date.
  </p>
</div>



          <div className="bordered-container">
            <h3>The Importance of Backups</h3>
            <p>
              The practice of maintaining multiple backups is crucial for data availability and recovery. Without these backups, the library would have faced significant data loss and operational disruption. This example highlights the importance of having reliable backup solutions to safeguard against potential data loss from ransomware attacks.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button1"
            onClick={handleNextClick}
            aria-label="Proceed to the ransomware quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Ransomware;
