import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const CreditProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const creditProfileRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module8/credit-report-quiz');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module7/data-backup-part-two#DataBackupPlanHeading');
  };

  // Scroll to the "Credit Profile" section if hash is present
  useEffect(() => {
    if (location.hash === '#CreditProfileHeading' && creditProfileRef.current) {
      creditProfileRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to data backup section"
      >
        ←
        
      </button>

      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header centered-header">
              <h2 ref={creditProfileRef}>Credit Profile</h2>
            </div>
            <div className="video-container">
              <video
                ref={videoRef}
                src="/assets/videos/CreditProfile.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                aria-label="Credit Profile video"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <p>
            <br />
              If Tanya had set up a credit monitoring service, she would have received real-time alerts for new credit applications and changes to her credit report. This would have enabled her to take immediate action and prevent the fraud from occurring. Some companies that offer these services include Experian CreditExpert, Equifax Credit Watch, and TransUnion Credit Monitoring.
            </p>
            <br />
          </div>

          <div className="highlighted-section2">
            <h3>Bank and Financial Institution Alerts:</h3>
            <p>
              Many banks and credit card companies offer free alerts for new credit activity, including loan applications, as part of their online banking services. Check with your financial institution to see if they offer this feature.
            </p>
            <br />
          </div>

          <div className="highlighted-section2">
            <h3>Benefits of Credit Monitoring Alerts</h3>
            <p>
              <strong>Early Detection:</strong><br />
              Immediate alerts can help you detect fraudulent activity as soon as it occurs, allowing you to take quick action.
            </p>
            <br />
            <p>
              <strong>Peace of Mind:</strong><br />
              Knowing that you will be notified of any significant changes to your credit report can provide peace of mind.
            </p>
            <br />
            <p>
              <strong>Preventative Measures:</strong><br />
              By being promptly informed, you can prevent further unauthorised activities and limit potential damage to your credit profiles.
            </p>
            <br />
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button 
            className="start-quiz-button1" 
            onClick={handleNextClick} 
            aria-label="Proceed to the credit report quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default CreditProfile;
