import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../Page3/RecognizingThreatsPartTwo.css'; // Adjusted path
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const StrongPasswordsPartTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping

  useEffect(() => {
    // Scroll to the "bordered-container" section if hash matches
    if (location.hash === '#bordered-container') {
      const element = document.querySelector('.bordered-container');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page4/strong-passwords-part-three#BasicsTitle'); // Navigate to the new page
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Next" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page4/strong-passwords'); // Navigate to the previous page
  };

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Strong Passwords Part One"
      >
        ←
      </button>
      <br />
      <h2 id="BasicsTitle" className="BasicsTitle">Strong Passwords (2)</h2>
      <section className="module-section">
        <div className="bordered-container">
          <p>
            As you gather more information, you discover that Ravi's password was simply his pet dog's name. Just a few days ago, Ravi had posted a picture of his dog on social media, including the pet's name in the caption. Additionally, Ravi hadn't set up two-factor authentication (2FA), which provides an extra layer of security. This made it remarkably easy for an attacker to gain access to his account. The hacker then found Ravi's other social media profiles and effortlessly accessed them as well, since Ravi reused the same password across multiple accounts and none of them had 2FA enabled.
          </p>
        </div>

        <section className="module-section">
          <div className="module-content">
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay /> {/* Add the logo overlay */}
              <video
                ref={videoRef}
                src="/assets/videos/PageFourPartTwo.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
                disablePictureInPicture // Disable Picture-in-Picture
                aria-label="Educational video on strong passwords (Part 2)"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </section>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Key Points</h3>
            <ul>
              <li>12-16 characters is the recommended length for a strong password.</li>
              <li>Avoid using common words, patterns, and personal information in passwords as this can be easily guessed.</li>
              <li>Use unique passwords for each account.</li>
              <li>Two-factor authentication provides an additional layer of security.</li>
              <li>If you suspect a password has been compromised, change it immediately.</li>
              <li>Avoid storing sensitive data in your chat history. If your account is compromised, an attacker could access this information. It's strongly recommended to delete all sensitive chat history regularly.</li>
            </ul>
          </div>
        </div>

        <div className="button-container">
          <button
            className="next-button"
            onClick={goToNext}
            aria-disabled={!isVideoWatched}
            aria-label="Proceed to Strong Passwords Part Three"
          >
            Next
          </button>
          {showWarning && (
            <p
              className="warning-text22"
              role="alert"
            >
              You must watch the video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default StrongPasswordsPartTwo;
