import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import "./WhatIsCyberSecurity.css";

const IntroQuiz = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const quizHeadingRef = useRef(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(8).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(8).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(8).fill(null));
  const [showStar, setShowStar] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "Why is cybersecurity critical for businesses?",
      options: [
        "To protect sensitive data",
        "To install games",
        "To update social media",
        "To free up memory"
      ],
      correctAnswer: "To protect sensitive data"
    },
    {
      question: "What is one of the consequences of poor cybersecurity?",
      options: [
        "Increased speed",
        "Data breaches and financial loss",
        "Free internet access",
        "Longer battery life"
      ],
      correctAnswer: "Data breaches and financial loss"
    },
    {
      question: "Why is it important to maintain business continuity?",
      options: [
        "To avoid operational disruptions",
        "To save data",
        "To increase battery life",
        "To make your device look better"
      ],
      correctAnswer: "To avoid operational disruptions"
    },
    {
      question: "How does safeguarding the company's reputation help?",
      options: [
        "By providing free internet",
        "By maintaining trustworthiness",
        "By speeding up your device",
        "By disabling firewalls"
      ],
      correctAnswer: "By maintaining trustworthiness"
    },
    {
      question: "What is GDPR?",
      options: [
        "A data protection regulation",
        "A type of malware",
        "An antivirus software",
        "A social media platform"
      ],
      correctAnswer: "A data protection regulation"
    },
    {
      question: "What can result from legal non-compliance?",
      options: [
        "Increased speed",
        "Legal ramifications and fines",
        "Free internet access",
        "Longer battery life"
      ],
      correctAnswer: "Legal ramifications and fines"
    },
    {
      question: "What can damage the brand reputation?",
      options: [
        "Good customer service",
        "Cybersecurity breaches",
        "High-quality products",
        "Efficient operations"
      ],
      correctAnswer: "Cybersecurity breaches"
    },
    {
      question: "Why is customer trust important?",
      options: [
        "To prevent unauthorised access",
        "To connect faster",
        "To free up memory",
        "To increase battery life"
      ],
      correctAnswer: "To prevent unauthorised access"
    }
  ];
  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  useEffect(() => {
    if (location.hash === "#CybersecurityQuizHeading" && quizHeadingRef.current) {
      quizHeadingRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location.hash]);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect =
        answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] =
          shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 6 && user) {
      try {
        const userDocRef = doc(firestore, "users", user.uid);
        await updateDoc(userDocRef, {
          "progress.course3.module1_quiz1": true,
        });
        setShowStar(true);
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(8).fill(null));
    setFeedback(Array(8).fill(null));
    setCorrectAnswers(Array(8).fill(null));
    setShowResults(false);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate("/businessacademy/moduleone/statistics#CurrentThreatLandscape");
  };

  const goBack = () => {
    navigate("/businessacademy/moduleone/what-is-cybersecurity-two#BusinessAcademyHeading");
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && (
        <div className="falling-star" role="alert" aria-live="polite"></div>
      )}
      <section
        className="module-section2"
        role="region"
        aria-labelledby="quiz-title"
      >
        <h2 id="quiz-title" ref={quizHeadingRef} tabIndex="0">
          Understanding Organisational Cybersecurity Quiz
        </h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={
                  feedback[currentQuestionIndex] ? "feedback" : undefined
                }
              >
                <legend
                  id={`question-${currentQuestionIndex}`}
                  tabIndex="0"
                  aria-live="polite"
                  aria-label={`Question ${
                    currentQuestionIndex + 1
                  }: ${shuffledQuestions[currentQuestionIndex]?.question}`}
                >
                  {shuffledQuestions[currentQuestionIndex]?.question}
                </legend>
                {shuffledQuestions[currentQuestionIndex]?.options.map(
                  (option, index) => (
                    <div key={index} className="quiz-option">
                      <label
                        className="quiz-option-label"
                        htmlFor={`option-${currentQuestionIndex}-${index}`}
                      >
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex}-${index}`}
                          name={`question-${currentQuestionIndex}`}
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={() => handleAnswerChange(option)}
                          disabled={feedback[currentQuestionIndex] !== null}
                          aria-checked={
                            answers[currentQuestionIndex] === option
                          }
                        />
                        <span className="option-text">{option}</span>
                      </label>
                    </div>
                  )
                )}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  role="alert"
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === "correct"
                    ? "Correct!"
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Next Question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit Quiz"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="alert" aria-live="polite">
              <p tabIndex="0">Your score: {score} / 8</p>
              {score >= 6 ? (
                <p tabIndex="0">
                  Congratulations! You passed the quiz and have a solid
                  understanding of organisational cybersecurity.
                </p>
              ) : (
                <p tabIndex="0">
                  You need to score at least 6 to pass. Please retake the quiz.
                </p>
              )}
              <div className="quiz-buttons">
                {score >= 6 ? (
                  <button
                    className="next-button"
                    onClick={goToNext}
                    aria-label="Next Lesson"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="retake-button"
                    onClick={handleRetake}
                    aria-label="Retake Quiz"
                  >
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="back-button-container">
          <button
            className="back-button"
            onClick={goBack}
            aria-label="Exit Quiz"
          >
            Exit Quiz
          </button>
        </div>
      </section>
    </div>
  );
};

export default IntroQuiz;
