import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './CybersecurityBasics.css';

const CyberSecurityQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(2).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [feedback, setFeedback] = useState(Array(2).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(2).fill(null));
  const [showStar, setShowStar] = useState(false);
  const [showCompletionMessage, setShowCompletionMessage] = useState(false);


  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "What is Cybersecurity?",
      options: [
        "Developing software for various purposes",
        "Designing and creating websites",
        "Protecting systems and networks from attacks",
        
      ],
      correctAnswer: "Protecting systems and networks from attacks",
    },
    {
      question: "Why is Cybersecurity Important?",
      options: [
        "To protect sensitive information",
        "To design better websites",
        "To develop new software",
      ],
      correctAnswer: "To protect sensitive information",
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null && answer !== null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect = answer === shuffledQuestions[currentQuestionIndex].correctAnswer;

      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    setShowResults(true);

    if (
      answers[0] === questions[0].correctAnswer &&
      answers[1] === questions[1].correctAnswer &&
      user
    ) {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          'progress.course1.module1': true,
        });
        setShowStar(true);
        setShowCompletionMessage(true);
      } catch (error) {
        console.error('Error updating user profile: ', error);
      }
    }
  };

  useEffect(() => {
    if (showStar) {
      const timer = setTimeout(() => {
        setShowStar(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showStar]);

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(2).fill(null));
    setFeedback(Array(2).fill(null));
    setCorrectAnswers(Array(2).fill(null));
    setShowResults(false);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page3/recognizing-threats#BasicsTitle');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page2/cybersecurity-basics#BasicsTitle');
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
  <button
    className="back-arrow-button"
    onClick={goBack}
    aria-label="Go back to the previous page"
  >
    ←
  </button>
  <br />
  {showStar && <div className="falling-star" aria-hidden="true"></div>}
  <section className="module-section2" role="region" aria-labelledby="quiz-title">
    <h2 id="quiz-title">Cybersecurity Quiz</h2>
    <div className="module-content2">
      {!showResults ? (
        <>
          <fieldset
            className="quiz-fieldset"
            aria-describedby={feedback[currentQuestionIndex] ? "feedback" : undefined}
          >
            <legend className="quiz-legend">
              Question {currentQuestionIndex + 1}: {shuffledQuestions[currentQuestionIndex].question}
            </legend>
            {shuffledQuestions[currentQuestionIndex].options.map((option, index) => (
              <div key={index} className="quiz-option">
                <label className="quiz-option-label">
                  <input
                    type="radio"
                    name={`question-${currentQuestionIndex}`}
                    value={option}
                    checked={answers[currentQuestionIndex] === option}
                    onChange={() => handleAnswerChange(option)}
                    disabled={feedback[currentQuestionIndex] !== null}
                    aria-checked={answers[currentQuestionIndex] === option}
                  />
                  <span className="option-text">{option}</span>
                </label>
              </div>
            ))}
          </fieldset>
          <div
            id="feedback"
            className={`feedback ${feedback[currentQuestionIndex] || ""}`}
            aria-live="polite"
          >
            {feedback[currentQuestionIndex] === "correct"
              ? "Correct!"
              : feedback[currentQuestionIndex] === "incorrect"
              ? `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`
              : ""}
          </div>
          <div className="quiz-buttons">
            {currentQuestionIndex < shuffledQuestions.length - 1 ? (
              <button
                className="next-button"
                onClick={handleNextQuestion}
                aria-label="Go to the next question"
              >
                Next
              </button>
            ) : (
              <button
                className="submit-button"
                onClick={handleSubmit}
                aria-label="Submit your answers"
              >
                Submit
              </button>
            )}
          </div>
        </>
      ) : (
        <div className="quiz-results" role="region" aria-labelledby="results-title">
          <h2 id="results-title">Results</h2>
          {answers[0] === questions[0].correctAnswer &&
answers[1] === questions[1].correctAnswer ? (
  <div>
    
    {showCompletionMessage && (
      <div className="completion-message">
        <p>
          🎉 Well done! You have successfully completed your first quiz and earned your first star. 
          Visit your <strong>Profile Page</strong> to track your progress. Keep going—once you complete 
          all three courses, you will receive your official certificate! 🏆
        </p>
      </div>
    )}
    <button
      className="next-button"
      onClick={goToNext}
      aria-label="Go to the next section"
    >
      Next
    </button>
  </div>

          ) : (
            <div>
              <p>You need to answer both questions correctly to proceed. Please retake the quiz.</p>
              <button
                className="retake-button"
                onClick={handleRetake}
                aria-label="Retake the quiz"
              >
                Retake Quiz
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  </section>
</div>

  );
};

export default CyberSecurityQuiz;
