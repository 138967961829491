import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Adjust the import path as needed
import './SafeBrowsingGame.css';

const scenarios = [
  {
    text: "You are about to enter your online bank account. What should you check first?",
    options: [
      { text: "Check if the website uses HTTPS", isCorrect: true },
      { text: "Just enter your credentials", isCorrect: false }
    ],
    feedback: [
      "Correct! Always ensure websites use HTTPS to keep your connection secure.",
      "Incorrect. You should always check that the website uses HTTPS."
    ]
  },
  {
    text: "You receive an email with a link from an unknown sender. What should you do?",
    options: [
      { text: "Click the link to see what it is", isCorrect: false },
      { text: "Avoid clicking the link", isCorrect: true }
    ],
    feedback: [
      "Correct! Avoid clicking on links from unknown sources to stay safe.",
      "Incorrect. You should never click on links from unknown sources."
    ]
  },
  {
    text: "You receive an email with a fantastic deal from a well-known retailer.",
    options: [
      { text: "Verify the sender's email address", isCorrect: true },
      { text: "Proceed without verifying", isCorrect: false }
    ],
    feedback: [
      "Correct! Always verify the sender's email address and visit the official website if unsure.",
      "Incorrect. You should always verify the sender's email address before proceeding."
    ]
  },
  {
    text: "A pop-up appears offering you a free gift card. What is your action?",
    options: [
      { text: "Click the pop-up to claim it", isCorrect: false },
      { text: "Close the pop-up and ignore it", isCorrect: true }
    ],
    feedback: [
      "Correct! Always ignore and close suspicious pop-ups.",
      "Incorrect. You should never click on suspicious pop-ups."
    ]
  }
];

const SafeBrowsingGame = () => {
  const [currentScenario, setCurrentScenario] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showStar, setShowStar] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate('/login'); // Redirect to login if the user is not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleOptionClick = (isCorrect, feedbackMessage, index) => {
    if (selectedOption === null) {
      setSelectedOption(index);
      setFeedback({ message: feedbackMessage, isCorrect });
      if (isCorrect) setScore(score + 1);

      setTimeout(() => {
        setFeedback(null);
        setSelectedOption(null);
        setCurrentScenario(currentScenario + 1);
      }, 2000);
    }
  };

  useEffect(() => {
    if (currentScenario >= scenarios.length && score >= 3) {
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        updateDoc(userDocRef, {
          'progress.course1.module5': true,
        })
          .then(() => setShowStar(true))
          .catch((error) => console.error('Error updating user profile:', error));
      }
    }
  }, [currentScenario, score, user]);

  const resetGame = () => {
    setCurrentScenario(0);
    setScore(0);
    setFeedback(null);
    setShowStar(false);
    setSelectedOption(null);
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page5.5/checking-urls#VerifyLinks');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page5/safe-browsing-part-two#AvoidingSuspiciousLinks');
  };

  if (currentScenario >= scenarios.length) {
    return (
      <div className="safe-browsing-game-container-unique11">
        <button
          className="back-arrow-button-unique11"
          onClick={goBack}
          aria-label="Go back to the previous page"
        >
          ←
        </button>
        {showStar && <div className="falling-star" aria-hidden="true"></div>}
        <h2>Mission Complete!</h2>
        <p>You scored {score} out of {scenarios.length}</p>
        <button onClick={resetGame} aria-label="Restart the quiz">Play Again</button>
        <button onClick={goToNext} aria-label="Go to the next section">Next</button>
      </div>
    );
  }

  const { text, options, feedback: scenarioFeedback } = scenarios[currentScenario];

  return (
    <div className="safe-browsing-game-container-unique11">
      <button
        className="back-arrow-button-unique11"
        onClick={goBack}
        aria-label="Go back to the previous page"
      >
        ←
      </button>
      <fieldset
        className="quiz-fieldset"
        aria-describedby={feedback ? 'feedback' : undefined}
      >
        <legend className="quiz-legend">{text}</legend>
        {options.map((option, index) => (
          <button
            key={index}
            className={
              selectedOption === index
                ? feedback?.isCorrect
                  ? 'correct-unique11'
                  : 'incorrect-unique11'
                : ''
            }
            onClick={() => handleOptionClick(option.isCorrect, scenarioFeedback[index], index)}
            disabled={selectedOption !== null}
            aria-describedby={selectedOption === index && feedback ? 'feedback' : undefined}
            aria-pressed={selectedOption === index}
          >
            {option.text}
          </button>
        ))}
      </fieldset>
      <div
        id="feedback"
        className={`feedback-unique11 ${feedback?.isCorrect ? 'correct-feedback-unique11' : 'incorrect-feedback-unique11'}`}
        aria-live="polite"
      >
        {feedback?.message}
      </div>
    </div>
  );
};

export default SafeBrowsingGame;
