import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const Privacy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const privacyRef = useRef(null);
  const videoRef = useRef(null);
  const warningRef = useRef(null);

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
      setTimeout(() => {
        warningRef.current?.focus();
      }, 100);
    } else {
      goToQuiz();
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/modulesix/privacy-quiz');
  };

  const goBack = () => {
    navigate('/businessacademy/modulesix/data-protection#DataProtectionHeading');
  };

  useEffect(() => {
    if (location.hash === '#PrivacyHeading' && privacyRef.current) {
      privacyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the Data Protection module"
      >
        ←
      </button>

      <div className="cybersecurity-basics-header">
       
        <h2 ref={privacyRef}>Privacy</h2>
    
      </div>

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="/assets/videos/PrivacyModuleSix.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Privacy Awareness Video"
          >
            <track kind="captions" src="/assets/videos/PrivacyModuleSix.vtt" label="English captions" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Privacy in Business Context</h3> 
            <ul>
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Understanding Privacy:</strong> Privacy involves protecting personal information from unauthorised access and misuse. It's not only a legal requirement but also a cornerstone of trust between the organisation and its clients, customers, and employees.</li>
              <br />
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>GDPR and Privacy:</strong> GDPR emphasises the importance of privacy by giving individuals greater control over their personal data. This includes the right to access, correct, delete, and restrict the processing of their data.</li>
              <br />
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Impact on Businesses:</strong> Protecting privacy helps maintain customer trust, enhances the organisation's reputation, and reduces the risk of legal penalties.</li>
              <br />
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Employee Responsibility:</strong> All employees must handle personal data with care, ensuring that they follow best practices for data privacy at all times.</li>
            </ul>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Data Encryption for Privacy Protection</h3> 
            <ul>
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>Importance of Encryption:</strong> Encryption is essential for protecting sensitive data from unauthorised access.</li>
              <br />
              <li><strong style={{ color: 'rgb(255, 184, 176)' }}>When to Use Encryption:</strong> Encrypt data when storing it on devices, during transmission (e.g., via email or file transfers), and when archiving sensitive information.</li>
            </ul>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button"
            onClick={handleNextClick}
            aria-label="Start Privacy Quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" ref={warningRef} tabIndex="-1" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Privacy;
