import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import safeurl from '../../assets/URLchecker.png';
import '../Page3/RecognizingThreats.css';

const CheckingURLs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const verifyLinksRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const goToNext = () => {
    navigate("/beginnerscourse/page5.5/Checking-URLs-quiz");
  };

  useEffect(() => {
    if (location.hash === '#VerifyLinks' && verifyLinksRef.current) {
      verifyLinksRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page5/safe-browsing-part-two#AvoidingSuspiciousLinks');
  };

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Avoiding Suspicious Links"
      >
        ←
      </button>
      <section className="module-section">
        <section className="module-section" style={{ textAlign: 'center' }}>
          <h2 id="VerifyLinks" ref={verifyLinksRef} className="BasicsTitle">
            Verify Links
          </h2>
        </section>

        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="/assets/videos/PageFiveFive.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            aria-label="Educational video on verifying links"
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <section className="module-section" style={{ textAlign: 'center' }}>
          <h3>How to Verify a Link</h3>
        </section>

        <div className="module-content">
          <div className="highlighted-section">
            <p>
              Determining the authenticity of a link by sight alone can be challenging, especially when URL shorteners are used. To verify a link, you can hover your cursor over it to see the URL, or right-click and select "Copy Link" to inspect it further. To ensure a link is safe, you can paste it into a URL checker. This tool reveals the full web address, checks for risks, and provides information about the destination site, helping you avoid potentially harmful websites. Below is an example of a URL checked on a URL checker.
            </p>
          </div>
        </div>
        <br />

        <div style={{ textAlign: 'center' }}>
          <img
            src={safeurl}
            alt="Screenshot showing the use of a URL checker to verify a link's safety"
            style={{ width: '100%', height: 'auto' }}
          />
        </div>

        <br />

        <div className="bordered-container">
          <p>
            The URL checker used above is available at{' '}
            <a
              href="https://radar.cloudflare.com/scan"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'lightblue', textDecoration: 'underline' }}
              aria-label="Open Cloudflare Radar URL checker in a new tab"
            >
              Cloudflare Radar
            </a>. Other URL checkers are available. The link tested was `http://bit.ly/3TeDQM2`, a shortened URL that doesn't reveal its destination at first glance. However, by using the URL checker, you can see a screenshot of the landing page and the actual domain, which in this case is YouTube, confirming that the link is legitimate. If you were to check a link and saw a Facebook login page in the screenshot, but the domain wasn't `facebook.com`, you'd know the link is fraudulent and designed to steal your credentials. Shortened URLs are often used for legitimate purposes, but they can also be exploited for malicious intent. That's why it's crucial to verify them using a URL checker. Your task is to add a URL checker to your bookmarks or favorites, allowing you to quickly and easily verify links whenever needed.
          </p>
        </div>

        <div className="button-container">
          <button
            className="next-button"
            onClick={goToNext}
            aria-label="Proceed to Checking URLs quiz"
          >
            Go to Verify Links
          </button>
          {showWarning && (
            <p className="warning-text22" role="alert">
              You must watch the video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default CheckingURLs;
